interface Props {
    content: string
    itResponder?: boolean
}

const EmailTemplate = ({ content, itResponder = true }: Props) => {

    const styles = {
        container: {
            width: "600px"
        },
        headerBg: {
            background: "#130922",
            width: "600px",
            textAlign: "center" as const,
            padding: "20px"
        },
        image: {
            display: "block",
            height: "auto",
            width: "100%",
        },
        bg: {
            background: "#130922",
            color: "#ffffff",
            padding: "20px",
            fontSize: "20px"
        },
        bold: {
            fontWeight: "bold"
        }
    }

    return (
        <table style={styles.container}>
            <tbody style={styles.container}>
                <tr style={styles.headerBg}>
                    <img style={styles.image} width="600" src="https://i.ibb.co/10qfGyw/logo.png" />
                </tr>
                <tr style={styles.bg}>
                    <span dangerouslySetInnerHTML={{ __html: content }}></span>
                    <br/>
                    {itResponder &&
                        <>
                            <p>Thank you,</p>
                            <p style={styles.bold}>Automated IT Support Responder</p>
                        </>
                    }
                    <p style={styles.bold}>**This email is automated. Do not reply.**</p>
                </tr>
            </tbody>
        </table>
    )
}

export default EmailTemplate