import React from "react"

interface Props {
    text: any
    className?: string
}

const Status = ({text, className}: Props) => {

    return (
        <p className={`status ${text === "Queued" && "status--blue"}  ${text === "Denied" && "status--red"} ${text === "Scheduled" && "status--orange"} ${text === "MCI notified" && "status--blue"} ${text === "No Response" && "status--red"} ${text === "Not Applicable" && "status--red"} ${text === "Cancelled" && "status--red"} ${text === "High priority" && "status--red"} ${text === "Checked in" && "status--green"} ${text === "Delivered" && "status--green"} ${text === "Completed" ? "status--green" : "status--orange"} ${className}`}>{text}</p>
    )
}

export default Status