import React from "react";
import { Draggable } from "react-beautiful-dnd";
import { ProjectTicket } from "../Pages/SubPages/Developer";
import moment from "moment";

interface Props {
  item: ProjectTicket
  index: number
  prefix: string
  onClick: () => void
}
const ListItem = ({ item, index, prefix, onClick }: Props) => {
  return (
    <Draggable draggableId={item.id} index={index}>
      {(provided, snapshot) => {
        return (
          <div
            onClick={onClick}
            className={`projectCard 
            ${prefix === "In Progress" && "projectCard--orange"} 
            ${prefix === "Testing" && "projectCard--orange"} 
            ${prefix === "Completed" && "projectCard--green"}
            `}
            ref={provided.innerRef}
            //@ts-ignore
            snapshot={snapshot}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <div className="d-flex flex-row justify-content-between align-items-center mb-2">
              <div className="d-flex flex-row justify-content-between">
                <p className={`m-0 mr1 projectCard__id 
             ${prefix === "In Progress" && "projectCard__id--orange"} 
             ${prefix === "Testing" && "projectCard__id--orange"} 
             ${prefix === "Completed" && "projectCard__id--green"}
            `}>{item.id}</p>
                {item.priority &&
                  <p className={`m-0 projectCard__id
             ${item.priority === "Medium Priority" && "projectCard__id--orange"} 
             ${item.priority === "High Priority" && "projectCard__id--red"}
            `}>{item.priority}</p>
                }
              </div>
              <p className="projectCard__name">
                {item.user?.name.split(" ")[0].substring(0, 1)}
                {item.user?.name.split(" ")[1].substring(0, 1)}
              </p>
            </div>
            {item.dateRaised &&
            <p className="m-0 projectCard__date">{moment(item.dateRaised).format("DD/MM/YYYY")}</p>
            }
            <p className="m-0 projectCard__title">{item.title}</p>
            <p className="m-0 projectCard__desc">{item.description}</p>
          </div>
        );
      }}
    </Draggable>
  );
};

export default ListItem;
