import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react"
import AuthenticatedHome from "./AuthenticatedHome";
import UnauthenticatedHome from "./UnauthenticatedHome";
const Home = () => {

    return (
        <>
            <UnauthenticatedTemplate>
                <UnauthenticatedHome />
            </UnauthenticatedTemplate>
            <AuthenticatedTemplate>
                <AuthenticatedHome />
            </AuthenticatedTemplate>
        </>
    )
}

export default Home