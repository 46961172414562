import Form from 'react-bootstrap/Form';
import { User, ticket } from '../Pages/AuthenticatedHome';

interface Props {
    initial?: string
    onChange: (value: User, ticket: ticket) => void
    ticket: ticket
}
const AssignedDropdown = ({ initial = "", onChange, ticket }: Props) => {

    const changeUser = (user: string) => {
        let tempUser: User = {email: "", id: "", name: ""};
        if (user === "Lewis Toseland") {
            tempUser.name = "Lewis Toseland"
            tempUser.id = "761b869d-2f90-4be7-91a6-3aaee6df11cd"
            tempUser.email = "ltoseland@eurosafesolutions.co.uk"
            onChange(tempUser, ticket)
        }
        if (user === "Ben Mallett") {
            tempUser.name = "Ben Mallett"
            tempUser.id = "9a5a2200-baab-4afd-9ccc-3507cdae153d"
            tempUser.email = "bmallett@eurosafesolutions.co.uk"
            onChange(tempUser, ticket)
        }
        if (user === "Callie McCabe") {
            tempUser.name = "Callie McCabe"
            tempUser.id = "5b6600c9-e466-4f3f-8538-76da45fe82a8"
            tempUser.email = "cmccabe@eurosafesolutions.co.uk"
            onChange(tempUser, ticket)
        }
        if (user === "Elliot Bonser") {
            tempUser.name = "Elliot Bonser"
            tempUser.id = "f28ab930-a6da-4de2-a9f1-6c74377445b7"
            tempUser.email = "ebonser@eurosafesolutions.co.uk"
            onChange(tempUser, ticket)
        }
        if (user === "Joshua Knight") {
            tempUser.name = "Joshua Knight"
            tempUser.id = "6aa92760-f287-4bf0-8406-673cbbb8a76c"
            tempUser.email = "Jknight@eurosafesolutions.co.uk"
            onChange(tempUser, ticket)
        }
        if (user === "1101") {
            tempUser.name = "1101"
            tempUser.id = "1101"
            tempUser.email = "1101"
            onChange(tempUser, ticket)
        }
    }
    return (
        <Form.Group controlId="assignedDropdown.change">
            <Form.Select value={initial} onChange={(e) => changeUser(e.currentTarget.value)}>
                <option value={""}>Assign a user</option>
                <option value={"Lewis Toseland"}>Lewis Toseland</option>
                <option value={"Elliot Bonser"}>Elliot Bonser</option>
                <option value={"Ben Mallett"}>Ben Mallett</option>
                <option value={"Callie McCabe"}>Callie McCabe</option>
                <option value={"Joshua Knight"}>Joshua Knight</option>
                <option value={"1101"}>1101</option>
            </Form.Select>
        </Form.Group>
    )
}

export default AssignedDropdown