import Form from 'react-bootstrap/Form';
import { ticket } from '../Pages/AuthenticatedHome';

interface Props {
    type: string
    initial: string
    onChange: (type: string, value: string, ticket: ticket) => void
    ticket: ticket
}

const AdminStatusDropdown = ({ type, initial, onChange, ticket }: Props) => {
    return (
        <Form.Group controlId="adminStatusDropdown.change">
            <Form.Select value={initial} onChange={(e) => onChange(type, e.currentTarget.value, ticket)}>
                {type === "status" &&
                    <>
                        <option value={"Queued"}>Queued</option>
                        <option value={"In progress"}>In progress</option>
                        <option value={"Awaiting action"}>Awaiting action</option>
                        <option value={"Scheduled"}>Scheduled</option>
                        <option value={"Ordered"}>Ordered</option>
                        <option value={"Not Applicable"}>Not Applicable</option>
                        <option value={"No Response"}>No Response</option>
                        <option value={"Completed"}>Completed</option>
                        <option value={"1101 notified"}>Escalated to 1101</option>
                    </>
                }
                {type === "priority" &&
                    <>
                        <option value={"Low priority"}>Low priority</option>
                        <option value={"Medium priority"}>Medium priority</option>
                        <option value={"High priority"}>High priority</option>
                    </>
                }
            </Form.Select>
        </Form.Group>
    )
}

export default AdminStatusDropdown