import { FaX } from "react-icons/fa6";
import { KnowledgebaseItem, NewKnowledge, User, graphAPIData } from "../Pages/AuthenticatedHome";
import wave from "../Images/wave.png";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, convertFromRaw, ContentState } from "draft-js";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Lightbox from "yet-another-react-lightbox";
import { getDownloadURL, getStorage, ref } from "firebase/storage";

interface Props {
    children: React.ReactNode;
    user: graphAPIData;
    admins: User[];
    viewTicket: boolean;
    selected?: NewKnowledge;
    closeTicket: () => void;
    saveChanges: (change: NewKnowledge) => void;

}

const KnowledgeContainer = ({ admins, children, closeTicket, user, viewTicket, selected, saveChanges }: Props) => {
    const [editorState, setEditorState] = useState<EditorState>(EditorState.createEmpty());
    const [open, setOpen] = useState<boolean>(false)
    const [imageURL, setImageURL] = useState<string>("")

    useEffect(() => {
        setImageURL("")
        if (selected && selected.description) {
            const blocksFromHtml = htmlToDraft(selected.description);
            const { contentBlocks, entityMap } = blocksFromHtml;
            const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
            setEditorState(EditorState.createWithContent(contentState));
            if(selected.screenshot) {
                getFile()
            }
        }
    }, [selected]);

    const onEditorStateChange = (editorState: EditorState) => {
        setEditorState(editorState);
    };

    const saveDescription = () => {
        const rawContentState = convertToRaw(editorState.getCurrentContent());
        const htmlContent = draftToHtml(rawContentState);
        let temp = selected
        if (temp) {
            temp.description = htmlContent
            saveChanges(temp)
        }
        // Here you would normally update the `selected.description` or send the HTML content to an API
    };

    const storage = getStorage()
    const imageRef = ref(storage, selected?.screenshot)
    const getFile = () => {
        getDownloadURL(imageRef).then((url) => setImageURL(url))
    }

    console.log(imageURL)
    return (
        <div className="containerES">
            <div className={`containerES-children ${!viewTicket && "w-100"}`}>
                {children}
            </div>
            {viewTicket && selected && (
                <div className="containerES-ticket">
                    <div className="containerES-ticket-content">
                        <div className="d-flex flex-row justify-content-between align-items-center">
                            <p className="containerES-ticket-content__id"></p>
                            <FaX onClick={closeTicket} className="containerES-ticket-content__close" color="black" size={24} />
                        </div>
                        <h2 className="containerES-ticket-content__title">{selected.issue}</h2>
                        <Lightbox
                            open={open}
                            close={() => setOpen(false)}
                            slides={[
                                {
                                    src: imageURL
                                },
                            ]}
                        />
                        {imageURL !== "" &&
                            <>
                                <p className="containerES-ticket-content__label">Attached image:</p>
                                <img onClick={() => setOpen(true)} className="containerES-ticket-content-thumb" src={imageURL} />
                            </>
                        }
                        <p className="containerES-ticket-content__label">Description:</p>
                        <div className="containerES-ticket-content-description">
                            <Editor
                                editorState={editorState}
                                wrapperClassName="demo-wrapper"
                                toolbarClassName="rdw-editor-toolbar toolbarClassName"
                                editorClassName="form-control editor-height rdw-editor-main"
                                onEditorStateChange={onEditorStateChange}
                            />
                        </div>
                        <div className="d-flex justify-content-end">
                            <Button onClick={saveDescription} className="mt-3 buttonES">
                                Save Changes
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default KnowledgeContainer;
