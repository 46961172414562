import { assert } from "console";
import Container from "../../Components/Container"
import Status from "../../Components/Status";
import { Asset, User, graphAPIData, ticket } from "../AuthenticatedHome"
import { DataGrid, GridRowsProp, GridColDef, GridRowSelectionModel, GridSortModel } from '@mui/x-data-grid';
import { useState, useEffect } from "react";
import AssetContainer from "../../Components/AssetContainer";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { FaCopy, FaFileCirclePlus } from "react-icons/fa6";
import { Button } from "react-bootstrap";

interface Props {
    allData: Asset[]
    user: graphAPIData
    admins: User[]
    saveAsset: (asset: Asset) => void
    duplicateAsset: (asset: Asset) => void
}
const AssetRegister = ({ allData, user, admins, saveAsset, duplicateAsset }: Props) => {
    const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
    const [selectedAsset, setSelectedAsset] = useState<Asset>()
    const [viewing, setViewing] = useState<boolean>(false)
    const [create, setCreate] = useState<boolean>(false)
    const [contextMenu, setContextMenu] = useState<{
        mouseX: number;
        mouseY: number;
    } | null>(null);

    const handleContextMenu = (event: React.MouseEvent) => {
        event.preventDefault();
        setContextMenu(
            contextMenu === null
                ? {
                    mouseX: event.clientX + 2,
                    mouseY: event.clientY - 6,
                }
                : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
                // Other native context menus might behave different.
                // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
                null,
        );
    };

    const handleClose = () => {
        setContextMenu(null);
    };

    const rows: GridRowsProp = allData.map((asset, index) => {
        return {
            id: "#" + asset.id,
            name: asset.productTitle,
            category: asset.category,
            brand: asset.brand,
            assigned: asset.assigned.user.name,
            status: <Status className="w-75" text={asset.status} />,
        }
    })

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'ASSET ID', minWidth: 100, flex: 1 },
        { field: 'name', headerName: 'ASSET NAME', minWidth: 400, flex: 1 },
        { field: 'category', headerName: 'CATEGORY', minWidth: 200, flex: 1 },
        { field: 'brand', headerName: 'BRAND', minWidth: 200, flex: 1 },
        { field: 'assigned', headerName: 'ASSIGNED', minWidth: 100, flex: 1 },

        {
            field: 'status', headerName: 'STATUS', minWidth: 170, flex: 1,
            sortComparator: (v1, v2) => v1.localeCompare(v2),
            renderCell: (params: any) => (
                <Status className="w-75" text={params.value} />
            ),
            valueGetter: (params) => params.value.props.text
        },
    ];

    useEffect(() => {
        allData.map((item) => {
            if (selectionModel.length > 0 && item.id.toString() === selectionModel[0].toString().slice(1)) {
                setSelectedAsset(item)
            }
        })
    }, [selectionModel])

    return (
        <AssetContainer
            duplicateAsset={(asset) => duplicateAsset(asset)}
            saveAsset={(asset) => saveAsset(asset)}
            admins={admins}
            closeAsset={() => {
                setViewing(false)
                setCreate(false)
            }}
            user={user}
            create={create}
            viewAsset={viewing}
            asset={selectedAsset}
            allData={allData}
        >
            <div className="assetReg" onContextMenu={handleContextMenu}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    onRowSelectionModelChange={(newSelectionModel) => {
                        setSelectionModel(newSelectionModel);
                    }}
                    rowSelectionModel={selectionModel}
                    onRowClick={() => {
                        setCreate(false)
                        setViewing(true)
                    }}
                />
                <Menu
                    open={contextMenu !== null}
                    onClose={handleClose}
                    anchorReference="anchorPosition"
                    anchorPosition={
                        contextMenu !== null
                            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                            : undefined
                    }
                >
                    <MenuItem onClick={() => {
                        setViewing(false)
                        setCreate(true)
                        handleClose()
                    }}><span className="d-flex flex-row align-items-center">
                            <FaFileCirclePlus color="white" className="mr1" />
                            Create new asset
                        </span>
                    </MenuItem>
                </Menu>
            </div>
        </AssetContainer>
    )
}

export default AssetRegister