import moment from "moment";
import Container from "../../Components/Container"
import Status from "../../Components/Status";
import { User, graphAPIData, ticket } from "../AuthenticatedHome"
import { DataGrid, GridRowsProp, GridColDef, GridRowSelectionModel, GridSortModel } from '@mui/x-data-grid';
import { useState, useEffect } from "react";

interface Props {
    allData: ticket[]
    user: graphAPIData
    onAssignedChange: (value: User, ticket: ticket) => void
    admins: User[]
    onStatusPriorityChange: (type: string, value: string, ticket: ticket) => void
    addNewComment: (ticket: ticket) => void
    onSubChange: (ticket: ticket) => void
}
const MyTickets = ({ allData, user, admins, onStatusPriorityChange, onAssignedChange, addNewComment, onSubChange }: Props) => {
    const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
    const [selectedTicket, setSelectedTicket] = useState<ticket>()
    const [viewing, setViewing] = useState<boolean>(false)
    const rows: GridRowsProp = allData.filter((item) => item.raisedBy.id === user.id && item.status !== "Completed").map((ticket, index) => {
        return {
            id: "#" + ticket.ticketId,
            summary: ticket.subject,
            device: ticket.device || "Not assigned",
            type: ticket.type || "Not assigned",
            assigned: ticket.assigned?.name,
            status: <Status className="w-75" text={ticket.status} />,
            raised: formatDate(ticket.dateRaised)
        }
    })

    
    function formatDate(dateString: string) {
        // Check if the date is in "Tue Sep 10 2024 13:03:45 GMT+0100" format
        if (moment(dateString, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ', true).isValid()) {
            return moment(dateString).format('DD/MM/YYYY');
        }

        // Check if the date is in "10/09/24" format
        if (moment(dateString, 'DD/MM/YYYY', true).isValid()) {
            return moment(dateString, 'DD/MM/YYYY').format('DD/MM/YYYY');
        }

        // Fallback for unrecognized formats
        return 'Invalid Date';
    }

    const columns: GridColDef[] = [
        { field: 'id', headerName: '#NUMBER', minWidth: 100, flex: 1 },
        { field: 'summary', headerName: 'SUMMARY', minWidth: 400, flex: 1 },
        { field: 'device', headerName: 'DEVICE', minWidth: 200, flex: 1 },
        { field: 'type', headerName: 'TYPE', minWidth: 200, flex: 1 },
        { field: 'assigned', headerName: 'ASSIGNED', minWidth: 200, flex: 1 },
        {
            field: 'status', headerName: 'STATUS', minWidth: 170, flex: 1,
            sortComparator: (v1, v2) => v1.localeCompare(v2),
            renderCell: (params: any) => (
                <Status className="w-75" text={params.value} />
            ),
            valueGetter: (params) => params.value.props.text
        },
        { field: 'raised', headerName: 'DATE RAISED', minWidth: 100, flex: 1 },
    ];

    useEffect(() => {
        allData.map((item) => {
            if (selectionModel.length > 0 && item.ticketId.toString() === selectionModel[0].toString().slice(1)) {
                setSelectedTicket(item)
            }
        })
    }, [selectionModel])

    useEffect(() => {
        setSelectedTicket(allData[0])
    }, [allData])

    return (
        <Container onSubChange={(ticket) => onSubChange(ticket)} addNewComment={(ticket) => addNewComment(ticket)} onAssignedChange={(value, ticket) => onAssignedChange(value, ticket)} onStatusPriorityChange={(type, value, ticket) => onStatusPriorityChange(type, value, ticket)} admins={admins} closeTicket={() => setViewing(false)} viewTicket={viewing} user={user} ticket={selectedTicket}>
            <DataGrid
                rows={rows}
                columns={columns}
                onRowSelectionModelChange={(newSelectionModel) => {
                    setSelectionModel(newSelectionModel);
                }}
                onRowClick={() => setViewing(true)}
                rowSelectionModel={selectionModel}
            />
        </Container>
    )
}

export default MyTickets