import { DataGrid, GridColDef, GridRowSelectionModel, GridRowsProp } from "@mui/x-data-grid";
import { KnowledgebaseItem, NewKnowledge, User, graphAPIData, ticket } from "../AuthenticatedHome";
import Status from "../../Components/Status";
import Container from "../../Components/Container";
import { ChangeEvent, useEffect, useState } from "react";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { FaFileCirclePlus } from "react-icons/fa6";
import KnowledgeContainer from "../../Components/KnowledgeContainer";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import { Timestamp } from "firebase/firestore";
import moment from "moment";
import React, { PureComponent } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Project } from "./Developer";
import { Button, Form, Modal } from "react-bootstrap";
import { v4 as uuidv4 } from 'uuid';
import Lightbox from "yet-another-react-lightbox";

interface Props {
    page: string
    allData: ticket[]
    user: graphAPIData
    onAssignedChange: (value: User, ticket: ticket) => void
    admins: User[]
    onStatusPriorityChange: (type: string, value: string, ticket: ticket) => void
    addNewComment: (ticket: ticket) => void
    onSubChange: (ticket: ticket) => void
    knowledgebase: NewKnowledge[]
    newKnowledge: (item: NewKnowledge, file: File | null) => void
    projects: Project[];
    saveKnowledgeUpdates: (know: NewKnowledge) => void
}

const Dashboard = ({ page, saveKnowledgeUpdates, knowledgebase, allData, projects, newKnowledge, user, admins, onStatusPriorityChange, onAssignedChange, addNewComment, onSubChange }: Props) => {
    const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
    const [selectedTicket, setSelectedTicket] = useState<ticket>()
    const [viewing, setViewing] = useState<boolean>(false)
    const [contextMenu, setContextMenu] = useState<{
        mouseX: number;
        mouseY: number;
    } | null>(null);
    const [contextMenuKnow, setContextMenuKnow] = useState<{
        mouseX: number;
        mouseY: number;
    } | null>(null);
    const [create, setCreate] = useState<boolean>(false)
    const handleContextMenu = (event: React.MouseEvent) => {
        event.preventDefault();
        setContextMenu(
            contextMenu === null
                ? {
                    mouseX: event.clientX + 2,
                    mouseY: event.clientY - 6,
                }
                : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
                // Other native context menus might behave different.
                // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
                null,
        );
    };
    const [show, setShow] = useState(false);
    const [title, setTitle] = useState<string>('');
    const [type, setType] = useState<string>('');
    const [device, setDevice] = useState<string>('');
    const [resolution, setResolution] = useState<string>('');
    const [file, setFile] = useState<File | null>(null);
    const [selectedKnow, setSelectedKnow] = useState<NewKnowledge>();
    const handleTitleChange = (e: ChangeEvent<HTMLInputElement>) => setTitle(e.target.value);
    const handleTypeChange = (e: ChangeEvent<HTMLSelectElement>) => setType(e.target.value);
    const handleDeviceChange = (e: ChangeEvent<HTMLSelectElement>) => setDevice(e.target.value);
    const handleResolutionChange = (e: ChangeEvent<HTMLTextAreaElement>) => setResolution(e.target.value);
    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => setFile(e.target.files ? e.target.files[0] : null);

    const handleCloseModal = () => setShow(false);
    const handleShowModal = () => setShow(true);


    const handleClose = () => {
        setContextMenu(null);
        setContextMenuKnow(null)
    };

    const handleContextMenuKnow = (event: React.MouseEvent) => {
        event.preventDefault();
        setContextMenuKnow(
            contextMenuKnow === null
                ? {
                    mouseX: event.clientX + 2,
                    mouseY: event.clientY - 6,
                }
                : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
                // Other native context menus might behave different.
                // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
                null,
        );
    };

    const getScheduled = (sched: Timestamp) => {
        console.log(sched)
        if (sched && sched instanceof Timestamp) {
            return moment(sched.toDate()).format("DD/MM/YYYY")
        }
        return ""
    }


    function formatDate(dateString: string) {
        // Check if the date is in "Tue Sep 10 2024 13:03:45 GMT+0100" format
        if (moment(dateString, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ', true).isValid()) {
            return moment(dateString).format('DD/MM/YYYY');
        }

        // Check if the date is in "10/09/24" format
        if (moment(dateString, 'DD/MM/YYYY', true).isValid()) {
            return moment(dateString, 'DD/MM/YYYY').format('DD/MM/YYYY');
        }

        // Fallback for unrecognized formats
        return 'Invalid Date';
    }

    const getRows = () => {
        let rows: any = []
        if (page === "Assign Admin") {
            admins.map((admin) => {
                rows.push({
                    id: admin.id,
                    name: admin.name,
                    email: admin.email
                })
            })
        } else if (page === "Knowledgebase") {
            knowledgebase.map((item) => {
                rows.push({
                    id: item.id,
                    summary: item.issue,
                    type: item.type,
                    device: item.device,
                    creator: item.loggedBy,
                    screenshot: item.screenshot ? "Available" : "Not Available"
                })
            })
        } else {
            allData.map((ticket: ticket) => {
                let t = ""
                if (ticket.scheduledDate) {
                    //@ts-ignore
                    const temp: Timestamp = ticket.scheduledDate
                    t = getScheduled(temp)
                }
                if (page === "Assigned Tickets" && (ticket.assigned?.id === user.id && ticket.status !== "Completed" && ticket.status !== "No Response" && ticket.status !== "Not Applicable")) {
                    rows.push({
                        id: "#" + ticket.ticketId,
                        summary: ticket.subject,
                        device: ticket.device || "Not assigned",
                        type: ticket.type || "Not assigned",
                        assigned: ticket.assigned?.name,
                        raisedBy: ticket.raisedBy.name,
                        status: <Status className="w-75" text={ticket.status} />,
                        priority: <Status className="w-75" text={ticket.priority} />,
                        scheduled: t,
                        raised: formatDate(ticket.dateRaised)
                    })
                }
                if (page === "High Priority" && ticket.priority === "High priority") {
                    rows.push({
                        id: "#" + ticket.ticketId,
                        summary: ticket.subject,
                        device: ticket.device || "Not assigned",
                        type: ticket.type || "Not assigned",
                        assigned: ticket.assigned?.name,
                        raisedBy: ticket.raisedBy.name,
                        status: <Status className="w-75" text={ticket.status} />,
                        priority: <Status className="w-75" text={ticket.priority} />,
                        scheduled: t,
                        raised: formatDate(ticket.dateRaised)

                    })
                }
                if (page === "Completed" && (ticket.status === "Completed" || ticket.status === "No Response" || ticket.status === "Not Applicable")) {
                    rows.push({
                        id: "#" + ticket.ticketId,
                        summary: ticket.subject,
                        device: ticket.device || "Not assigned",
                        type: ticket.type || "Not assigned",
                        assigned: ticket.assigned?.name,
                        raisedBy: ticket.raisedBy.name,
                        status: <Status className="w-75" text={ticket.status} />,
                        priority: <Status className="w-75" text={ticket.priority} />,
                        scheduled: t,
                        raised: formatDate(ticket.dateRaised)

                    })
                }
                if (page === "1101 Notified" && ticket.status === "MCI notified") {
                    rows.push({
                        id: "#" + ticket.ticketId,
                        summary: ticket.subject,
                        device: ticket.device || "Not assigned",
                        type: ticket.type || "Not assigned",
                        assigned: ticket.assigned?.name,
                        raisedBy: ticket.raisedBy.name,
                        status: <Status className="w-75" text={ticket.status} />,
                        priority: <Status className="w-75" text={ticket.priority} />,
                        scheduled: t,
                        raised: formatDate(ticket.dateRaised)

                    })
                }
            })
        }
        let rowsCorrect: GridRowsProp = rows
        return rowsCorrect
    }

    const getColumns = () => {
        let columns: GridColDef[] = []
        if (page === "High Priority" || page === "Completed" || page === "1101 Notified" || page === "Assigned Tickets") {
            columns = [
                { field: 'id', headerName: '#NUMBER', minWidth: 100, flex: 1 },
                { field: 'summary', headerName: 'SUMMARY', minWidth: 300, flex: 1 },
                { field: 'device', headerName: 'DEVICE', minWidth: 200, flex: 1 },
                { field: 'type', headerName: 'TYPE', minWidth: 200, flex: 1 },
                { field: 'assigned', headerName: 'ASSIGNED', minWidth: 200, flex: 1 },
                { field: 'raisedBy', headerName: 'RAISED BY', minWidth: 100, flex: 1 },
                {
                    field: 'status', headerName: 'STATUS', minWidth: 170, flex: 1,
                    sortComparator: (v1, v2) => v1.localeCompare(v2),
                    renderCell: (params: any) => (
                        <Status className="w-75" text={params.value} />
                    ),
                    valueGetter: (params) => params.value.props.text
                },
                {
                    field: 'priority', headerName: 'PRIORITY', minWidth: 170, flex: 1,
                    sortComparator: (v1, v2) => v1.localeCompare(v2),
                    renderCell: (params: any) => (
                        <Status className="w-75" text={params.value} />
                    ),
                    valueGetter: (params) => params.value.props.text
                },
                { field: 'scheduled', headerName: 'SCHEDULED', minWidth: 100, flex: 1 },
                { field: 'raised', headerName: 'DATE RAISED', minWidth: 100, flex: 1 },
            ]
        }
        if (page === "Assign Admin") {
            columns = [
                { field: 'id', headerName: 'USER ID', minWidth: 300, flex: 1 },
                { field: 'name', headerName: 'FULL NAME', minWidth: 300, flex: 1 },
                { field: 'email', headerName: 'EMAIL', minWidth: 300, flex: 1 },
            ]
        }
        if (page === "Knowledgebase") {
            columns = [
                { field: 'summary', headerName: 'ISSUE', minWidth: 300, flex: 1 },
                { field: 'type', headerName: 'TYPE', minWidth: 200, flex: 1 },
                { field: 'device', headerName: 'DEVICE', minWidth: 200, flex: 1 },
                { field: 'creator', headerName: 'CREATOR', minWidth: 200, flex: 1 },
                { field: 'screenshot', headerName: 'SCREENSHOT', minWidth: 200, flex: 1 },
            ]
        }
        return columns
    }

    useEffect(() => {
        if (page === "Knowledgebase" && selectionModel.length > 0) {
            const sel = knowledgebase.filter((a) => a.id === selectionModel[0])
            if (sel) {
                setSelectedKnow(sel[0])
            }
        } else {
            allData.map((item) => {
                if (selectionModel.length > 0 && item.ticketId.toString() === selectionModel[0].toString().slice(1)) {
                    setSelectedTicket(item)
                }
            })
        }
    }, [selectionModel])

    const getDepartmentKey = (dep: string) => {
        if (dep === "Contracts - Office") {
            return "cOffice"
        }
        if (dep === "Contracts - Site") {
            return "cSite"
        }
        if (dep === "Design") {
            return "cOffice"
        }
        if (dep === "Directors") {
            return "directors"
        }
        if (dep === "Sales") {
            return "sales"
        }
        if (dep === "Process") {
            return "process"
        }
        if (dep === "Finance & Warehouse") {
            return "fAndW"
        }
        if (dep === "Remedials - FPS") {
            return "rem"
        }
        if (dep === "Remedials - LPS") {
            return "rem"
        }
        if (dep === "Rope Access") {
            return "ropeAccess"
        }
        if (dep === "Test & Inspection Operations") {
            return "tIOps"
        }
        if (dep === "Test & Inspection Sales") {
            return "tISales"
        }
        if (dep === "Test & Inspection Site") {
            return "tISite"
        }
        if (dep === "Training & PPE") {
            return "trainAndPPE"
        }
        return "misc"
    }

    function convertMinutesToHours(minutes: number) {
        var hours = minutes / 60;
        return hours
    }

    const getGraphData = () => {
        const data = [
            {
                name: "Jan",
                cOffice: 0,
                cSite: 0,
                directors: 0,
                fAndW: 0,
                rem: 0,
                ropeAccess: 0,
                tIOps: 0,
                tISales: 0,
                tISite: 0,
                trainAndPPE: 0,
                misc: 0,
                process: 0,
                sales: 0
            },
            {
                name: "Feb",
                cOffice: 0,
                cSite: 0,
                directors: 0,
                fAndW: 0,
                rem: 0,
                ropeAccess: 0,
                tIOps: 0,
                tISales: 0,
                tISite: 0,
                trainAndPPE: 0,
                misc: 0,
                process: 0,
                sales: 0
            },
            {
                name: "Mar",
                cOffice: 0,
                cSite: 0,
                directors: 0,
                fAndW: 0,
                rem: 0,
                ropeAccess: 0,
                tIOps: 0,
                tISales: 0,
                tISite: 0,
                trainAndPPE: 0,
                misc: 0,
                process: 0,
                sales: 0
            },
            {
                name: "Apr",
                cOffice: 0,
                cSite: 0,
                directors: 0,
                fAndW: 0,
                rem: 0,
                ropeAccess: 0,
                tIOps: 0,
                tISales: 0,
                tISite: 0,
                trainAndPPE: 0,
                misc: 0,
                process: 0,
                sales: 0
            },
            {
                name: "May",
                cOffice: 0,
                cSite: 0,
                directors: 0,
                fAndW: 0,
                rem: 0,
                ropeAccess: 0,
                tIOps: 0,
                tISales: 0,
                tISite: 0,
                trainAndPPE: 0,
                misc: 0,
                process: 0,
                sales: 0
            },
            {
                name: "Jun",
                cOffice: 0,
                cSite: 0,
                directors: 0,
                fAndW: 0,
                rem: 0,
                ropeAccess: 0,
                tIOps: 0,
                tISales: 0,
                tISite: 0,
                trainAndPPE: 0,
                misc: 0,
                process: 0,
                sales: 0
            },
            {
                name: "Jul",
                cOffice: 0,
                cSite: 0,
                directors: 0,
                fAndW: 0,
                rem: 0,
                ropeAccess: 0,
                tIOps: 0,
                tISales: 0,
                tISite: 0,
                trainAndPPE: 0,
                misc: 0,
                process: 0,
                sales: 0
            },
            {
                name: "Aug",
                cOffice: 0,
                cSite: 0,
                directors: 0,
                fAndW: 0,
                rem: 0,
                ropeAccess: 0,
                tIOps: 0,
                tISales: 0,
                tISite: 0,
                trainAndPPE: 0,
                misc: 0,
                process: 0,
                sales: 0
            },
            {
                name: "Sep",
                cOffice: 0,
                cSite: 0,
                directors: 0,
                fAndW: 0,
                rem: 0,
                ropeAccess: 0,
                tIOps: 0,
                tISales: 0,
                tISite: 0,
                trainAndPPE: 0,
                misc: 0,
                process: 0,
                sales: 0
            },
            {
                name: "Oct",
                cOffice: 0,
                cSite: 0,
                directors: 0,
                fAndW: 0,
                rem: 0,
                ropeAccess: 0,
                tIOps: 0,
                tISales: 0,
                tISite: 0,
                trainAndPPE: 0,
                misc: 0,
                process: 0,
                sales: 0
            },
            {
                name: "Nov",
                cOffice: 0,
                cSite: 0,
                directors: 0,
                fAndW: 0,
                rem: 0,
                ropeAccess: 0,
                tIOps: 0,
                tISales: 0,
                tISite: 0,
                trainAndPPE: 0,
                misc: 0,
                process: 0,
                sales: 0
            },
            {
                name: "Dec",
                cOffice: 0,
                cSite: 0,
                directors: 0,
                fAndW: 0,
                rem: 0,
                ropeAccess: 0,
                tIOps: 0,
                tISales: 0,
                tISite: 0,
                trainAndPPE: 0,
                misc: 0,
                process: 0,
                sales: 0
            },
        ];

        allData.map((ticket) => {
            if (ticket.status === "Completed") {
                const month = moment(ticket.dateRaised, "DD/MM/YYYY").format("MMM")
                const year = moment(ticket.dateRaised, "DD/MM/YYYY").format("YYYY")
                data.map((line) => {
                    if (line.name === month && ticket.department !== undefined && ticket.timeTaken && year === "2024") {
                        const split = ticket.timeTaken.split("-")
                        let time = parseInt(split[0])
                        if (split[1] === "minutes") {
                            time = convertMinutesToHours(parseInt(split[0]))
                        }
                        line[getDepartmentKey(ticket.department)] = line[getDepartmentKey(ticket.department)] + parseFloat(time.toFixed(1))
                    }
                })
            }
        })
        projects.map((project) => {
            project.tickets.Completed.map((projectTicket) => {
                if (projectTicket.timeSpent) {
                    const split = projectTicket.timeSpent.split("-")
                    let time = parseInt(split[0])
                    if (split[1] === "minutes") {
                        time = convertMinutesToHours(parseInt(split[0]))
                    }
                    data.map((line) => {
                        if (line.name === moment(projectTicket.dateRaised).format("MMM") && moment(projectTicket.dateRaised).format("YYYY") === "2024" && projectTicket.department) {
                            line[getDepartmentKey(projectTicket.department)] = line[getDepartmentKey(projectTicket.department)] + parseFloat(time.toFixed(1))
                        }
                    })
                }
            })
        })
        return data
    }

    const createKnowItem = () => {
        const uu = uuidv4();
        let temp: NewKnowledge = {
            id: uu,
            description: resolution,
            device: device,
            issue: title,
            loggedBy: user.displayName,
            type: type
        }
        if (file) {
            temp.screenshot = `/KnowledgebaseScreenshots/${temp.id}-${file.name}`
        }
        newKnowledge(temp, file)
    }

    return (
        <>
            {page === "Reports" &&
                <div className="d-flex flex-row p-1">
                    <div className="w-100 graph-container">
                        <ResponsiveContainer width="100%" height="100%">
                            <LineChart
                                width={500}
                                height={300}
                                data={getGraphData()}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis label="Hours" />
                                <Tooltip />
                                <Legend />
                                <Line name="Contracts - Office" type="monotone" dataKey="cOffice" stroke="#003f5c" />
                                <Line name="Contracts - Site" type="monotone" dataKey="cSite" stroke="#2f4b7c" />
                                <Line name="Directors" type="monotone" dataKey="directors" stroke="#a05195" />
                                <Line name="Finance & Warehouse" type="monotone" dataKey="fAndW" stroke="#d45087" />
                                <Line name="Remedials" type="monotone" dataKey="rem" stroke="#ff7c43" />
                                <Line name="Rope Access" type="monotone" dataKey="ropeAccess" stroke="#ffa600" />
                                <Line name="T&I Operations" type="monotone" dataKey="tIOps" stroke="#ff47b1" />
                                <Line name="T&I Sales" type="monotone" dataKey="tISales" stroke="#de56d6" />
                                <Line name="T&I Site" type="monotone" dataKey="tISite" stroke="#9d69f2" />
                                <Line name="Training & PPE" type="monotone" dataKey="trainAndPPE" stroke="#0078ff" />
                                <Line name="Process" type="monotone" dataKey="process" stroke="#00e9c0" />
                                <Line name="Sales" type="monotone" dataKey="sales" stroke="#00ff32" />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                </div>
            }
            {page === "Assigned Tickets" &&
                <Container onSubChange={(ticket) => onSubChange(ticket)} addNewComment={(ticket) => addNewComment(ticket)} onAssignedChange={(value, ticket) => onAssignedChange(value, ticket)} onStatusPriorityChange={(type, value, ticket) => onStatusPriorityChange(type, value, ticket)} admins={admins} closeTicket={() => setViewing(false)} viewTicket={viewing} user={user} ticket={selectedTicket}>
                    <DataGrid
                        rows={getRows()}
                        columns={getColumns()}
                        onRowSelectionModelChange={(newSelectionModel) => {
                            setSelectionModel(newSelectionModel);
                        }}
                        onRowClick={() => setViewing(true)}
                        rowSelectionModel={selectionModel}
                    />
                </Container>
            }
            {page === "High Priority" &&
                <Container onSubChange={(ticket) => onSubChange(ticket)} addNewComment={(ticket) => addNewComment(ticket)} onAssignedChange={(value, ticket) => onAssignedChange(value, ticket)} onStatusPriorityChange={(type, value, ticket) => onStatusPriorityChange(type, value, ticket)} admins={admins} closeTicket={() => setViewing(false)} viewTicket={viewing} user={user} ticket={selectedTicket}>
                    <DataGrid
                        rows={getRows()}
                        columns={getColumns()}
                        onRowSelectionModelChange={(newSelectionModel) => {
                            setSelectionModel(newSelectionModel);
                        }}
                        onRowClick={() => setViewing(true)}
                        rowSelectionModel={selectionModel}
                    />
                </Container>
            }
            {page === "Completed" &&
                <Container onSubChange={(ticket) => onSubChange(ticket)} addNewComment={(ticket) => addNewComment(ticket)} onAssignedChange={(value, ticket) => onAssignedChange(value, ticket)} onStatusPriorityChange={(type, value, ticket) => onStatusPriorityChange(type, value, ticket)} admins={admins} closeTicket={() => setViewing(false)} viewTicket={viewing} user={user} ticket={selectedTicket}>
                    <DataGrid
                        rows={getRows()}
                        columns={getColumns()}
                        onRowSelectionModelChange={(newSelectionModel) => {
                            setSelectionModel(newSelectionModel);
                        }}
                        onRowClick={() => setViewing(true)}
                        rowSelectionModel={selectionModel}
                    />
                </Container>
            }
            {page === "1101 Notified" &&
                <Container onSubChange={(ticket) => onSubChange(ticket)} addNewComment={(ticket) => addNewComment(ticket)} onAssignedChange={(value, ticket) => onAssignedChange(value, ticket)} onStatusPriorityChange={(type, value, ticket) => onStatusPriorityChange(type, value, ticket)} admins={admins} closeTicket={() => setViewing(false)} viewTicket={viewing} user={user} ticket={selectedTicket}>
                    <DataGrid
                        rows={getRows()}
                        columns={getColumns()}
                        onRowSelectionModelChange={(newSelectionModel) => {
                            setSelectionModel(newSelectionModel);
                        }}
                        onRowClick={() => setViewing(true)}
                        rowSelectionModel={selectionModel}
                    />
                </Container>
            }
            {page === "Assign Admin" &&
                <div className="assetReg" onContextMenu={handleContextMenu}>
                    <DataGrid
                        rows={getRows()}
                        columns={getColumns()}
                        onRowSelectionModelChange={(newSelectionModel) => {
                            setSelectionModel(newSelectionModel);
                        }}
                        rowSelectionModel={selectionModel}
                    />
                    <Menu
                        open={contextMenu !== null}
                        onClose={handleClose}
                        anchorReference="anchorPosition"
                        anchorPosition={
                            contextMenu !== null
                                ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                                : undefined
                        }
                    >
                        <MenuItem onClick={() => {
                            setCreate(true)
                            handleClose()
                        }}><span className="d-flex flex-row align-items-center">
                                <FaFileCirclePlus color="white" className="mr1" />
                                Add new admin
                            </span>
                        </MenuItem>
                    </Menu>
                </div>
            }
            {page === "Knowledgebase" &&
                <KnowledgeContainer saveChanges={(temp) => saveKnowledgeUpdates(temp)} selected={selectedKnow} admins={admins} closeTicket={() => setViewing(false)} viewTicket={viewing} user={user}>
                    <Modal size="lg" show={show} onHide={handleCloseModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>Create Knowledgebase Item</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label className="m-0">Title:</Form.Label>
                                <Form.Control
                                    placeholder="Enter issue title..."
                                    value={title}
                                    onChange={handleTitleChange}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                                <Form.Label className="m-0">Type:</Form.Label>
                                <Form.Select value={type} onChange={handleTypeChange}>
                                    <option value={""}>Select issue type...</option>
                                    <option value={"Bluebeam"}>Bluebeam</option>
                                    <option value={"Email"}>Email</option>
                                    <option value={"ERP"}>ERP</option>
                                    <option value={"App"}>App</option>
                                    <option value={"Hardware"}>Hardware</option>
                                    <option value={"RDS"}>RDS</option>
                                    <option value={"Sharepoint"}>Sharepoint</option>
                                    <option value={"VPN/Sophos"}>VPN/Sophos</option>
                                    <option value={"3CX"}>3CX</option>
                                    <option value={"Other"}>Other</option>
                                </Form.Select>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
                                <Form.Label className="m-0">Device:</Form.Label>
                                <Form.Select value={device} onChange={handleDeviceChange}>
                                    <option value={""}>Select a device...</option>
                                    <option value={"Phone"}>Phone</option>
                                    <option value={"Tablet"}>Tablet</option>
                                    <option value={"Laptop"}>Laptop</option>
                                    <option value={"ThinClient"}>ThinClient</option>
                                    <option value={"Printer"}>Printer</option>
                                    <option value={"Other"}>Other</option>
                                </Form.Select>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput4">
                                <Form.Label className="m-0">Resolution:</Form.Label>
                                <Form.Control
                                    placeholder="Enter resolution..."
                                    as="textarea"
                                    rows={5}
                                    value={resolution}
                                    onChange={handleResolutionChange}
                                />
                            </Form.Group>

                            <Form.Group controlId="formFile" className="mb-3">
                                <Form.Label>Upload Screenshot:</Form.Label>
                                <Form.Control type="file" onChange={handleFileChange} />
                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button className="buttonES" onClick={() => {
                                createKnowItem()
                                handleCloseModal()
                            }}>
                                Save Item
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <div className="assetReg" onContextMenu={handleContextMenuKnow}>

                        <Menu
                            open={contextMenuKnow !== null}
                            onClose={handleClose}
                            anchorReference="anchorPosition"
                            anchorPosition={
                                contextMenuKnow !== null
                                    ? { top: contextMenuKnow.mouseY, left: contextMenuKnow.mouseX }
                                    : undefined
                            }
                        >
                            <MenuItem onClick={() => {
                                handleShowModal()
                            }}><span className="d-flex flex-row align-items-center">
                                    <FaFileCirclePlus color="white" className="mr1" />
                                    New knowledgebase item
                                </span>
                            </MenuItem>
                        </Menu>
                        <DataGrid
                            rows={getRows()}
                            columns={getColumns()}
                            onRowSelectionModelChange={(newSelectionModel) => {
                                setSelectionModel(newSelectionModel);
                            }}
                            onRowClick={() => setViewing(true)}
                            rowSelectionModel={selectionModel}
                        />
                    </div>

                </KnowledgeContainer>
            }
        </>
    )
}

export default Dashboard