// "761b869d-2f90-4be7-91a6-3aaee6df11cd"
export const levelOne = ["761b869d-2f90-4be7-91a6-3aaee6df11cd", "9a5a2200-baab-4afd-9ccc-3507cdae153d", "5b6600c9-e466-4f3f-8538-76da45fe82a8"]



export const allUsers = [
  {
    "userPrincipalName": "KHaywood@eurosafeuk.com",
    "displayName": "Keiran Haywood",
    "surname": "Haywood",
    "mail": "KHaywood@eurosafeuk.com",
    "givenName": "Keiran",
    "id": "022a5f5f-c6cb-4adb-9340-5a447ae6bde5",
    "userType": "Member",
    "jobTitle": "Site Operative",
    "department": "Contracts",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Contracting",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Contracting",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "9/23/2024 2:53:53 PM"
  },
  {
    "userPrincipalName": "JEllis@eurosafeuk.com",
    "displayName": "Joel Ellis",
    "surname": "Ellis",
    "mail": "JEllis@eurosafeuk.com",
    "givenName": "Joel",
    "id": "044b6c8d-f851-40cb-a18e-ba19bf5c7700",
    "userType": "Member",
    "jobTitle": "Junior Sales Estimator",
    "department": "Sales",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Contracting",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "07947596811",
    "mobilePhone": "07947596811",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Contracting",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "9/9/2020 7:44:17 AM"
  },
  {
    "userPrincipalName": "Interviews@eurosafesolutions.co.uk",
    "displayName": "Eurosafe Solutions Interviews",
    "surname": "",
    "mail": "Interviews@eurosafesolutions.co.uk",
    "givenName": "",
    "id": "05984314-efc3-4c47-8aa4-103fc0596fa5",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "True",
    "usageLocation": "",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "1/31/2023 3:07:19 PM"
  },
  {
    "userPrincipalName": "MPollard@eurosafeuk.com",
    "displayName": "Mark Pollard",
    "surname": "Pollard",
    "mail": "MPollard@eurosafeuk.com",
    "givenName": "Mark",
    "id": "073abf70-903d-42cb-bf83-52a41c04edbe",
    "userType": "Member",
    "jobTitle": "Site Supervisor",
    "department": "Compliance",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Compliance",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Compliance",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "11/10/2021 8:46:57 AM"
  },
  {
    "userPrincipalName": "Compliance@eurosafeuk.com",
    "displayName": "T&I Compliance Email",
    "surname": "Compliance",
    "mail": "Compliance@eurosafeuk.com",
    "givenName": "T&I",
    "id": "07eb67c8-e28a-4468-9ac7-96866442873d",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "2/5/2024 1:58:19 PM"
  },
  {
    "userPrincipalName": "ALucas@eurosafesolutions.co.uk",
    "displayName": "Adam Lucas",
    "surname": "Lucas",
    "mail": "alucas@eurosafesolutions.co.uk",
    "givenName": "Adam",
    "id": "0937cb77-0f97-4fbb-a8e5-94446db6b6f9",
    "userType": "Member",
    "jobTitle": "Retentions Account Manager",
    "department": "Test & Inspection Sales",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (Ext 222)",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "False",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "10/13/2022 12:12:53 PM"
  },
  {
    "userPrincipalName": "Solidrail@eurosafeuk.com",
    "displayName": "Solidrail",
    "surname": "Ellis",
    "mail": "solidrail@eurosafeuk.com",
    "givenName": "Gavin",
    "id": "0a13037f-eccc-4caf-af30-27b4222b6507",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "True",
    "usageLocation": "",
    "streetAddress": "Unit 51B Orgreave Drive, Handsworth",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "Sheffield",
    "postalCode": "S13 9NR",
    "telephoneNumber": "0114 2507411",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "12/16/2021 11:35:01 AM"
  },
  {
    "userPrincipalName": "GCooke@eurosafeuk.com",
    "displayName": "Graham Cooke",
    "surname": "Cooke",
    "mail": "GCooke@eurosafeuk.com",
    "givenName": "Graham",
    "id": "0a7001a9-dec6-45f0-b051-a496dd548298",
    "userType": "Member",
    "jobTitle": "PPE & Plant Inspection Co-Ordinator",
    "department": "Warehouse & Purchasing",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "11/15/2023 10:27:22 AM"
  },
  {
    "userPrincipalName": "ATIteam8@eurosafeuk.com",
    "displayName": "T&I Team 8",
    "surname": "Team 8",
    "mail": "ATIteam8@eurosafeuk.com",
    "givenName": "T&I",
    "id": "0aa42b6c-b528-45ce-9141-c72640b49197",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "4/18/2019 3:11:43 PM"
  },
  {
    "userPrincipalName": "Swood@eurosafeuk.com",
    "displayName": "Steve Wood",
    "surname": "Wood",
    "mail": "SWood@eurosafeuk.com",
    "givenName": "Steve",
    "id": "0c67aedc-05e9-4ef5-ae79-859db9f0be7b",
    "userType": "Member",
    "jobTitle": "Senior Trainer",
    "department": "Training",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Training & PPE",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (Ext 325)",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Training & PPE",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "3/9/2022 12:07:03 PM"
  },
  {
    "userPrincipalName": "ileau@eurosafeuk.com",
    "displayName": "Ion Leau",
    "surname": "Leau",
    "mail": "ILeau@eurosafeuk.com",
    "givenName": "Ion",
    "id": "0d7a6bf6-4b8e-4485-bf6f-d01ab15e04d4",
    "userType": "Member",
    "jobTitle": "Site Operative",
    "department": "Installations",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Contracting",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Contracting",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "9/20/2021 1:26:06 PM"
  },
  {
    "userPrincipalName": "Timesheets-Installs@eurosafeuk.com",
    "displayName": "Timesheets-Installs",
    "surname": "",
    "mail": "Timesheets-Installs@eurosafeuk.com",
    "givenName": "",
    "id": "0e6b2554-67d6-4bfd-b1fc-3e06833fda4d",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "False",
    "usageLocation": "",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "8/18/2020 10:50:36 AM"
  },
  {
    "userPrincipalName": "Solar@eurosafeuk.com",
    "displayName": "Solar",
    "surname": "",
    "mail": "Solar@eurosafeuk.com",
    "givenName": "",
    "id": "0ea54452-ffb0-40e0-be4e-708211fe24b5",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "True",
    "usageLocation": "",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "9/30/2024 2:29:16 PM"
  },
  {
    "userPrincipalName": "PPowell@eurosafeuk.com",
    "displayName": "Paul Powell",
    "surname": "Powell",
    "mail": "PPowell@eurosafeuk.com",
    "givenName": "Paul",
    "id": "0ebc8765-4cff-4513-b94f-a71c9aa4fbc1",
    "userType": "Member",
    "jobTitle": "Operations Director",
    "department": "Compliance",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Compliance",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (Ext 241)",
    "mobilePhone": "0781 702 3513",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Compliance",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "1/17/2017 12:45:23 PM"
  },
  {
    "userPrincipalName": "ACampbell@eurosafeuk.com",
    "displayName": "Aaron Campbell",
    "surname": "Campbell",
    "mail": "ACampbell@eurosafeuk.com",
    "givenName": "Aaron",
    "id": "0ee11898-96cb-4b91-ba9f-96610ad0de26",
    "userType": "Member",
    "jobTitle": "Site Supervisor",
    "department": "Contracting",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Contracting",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "07903320165",
    "mobilePhone": "07903320165",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Contracting",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "9/13/2024 8:19:10 AM"
  },
  {
    "userPrincipalName": "LCawthorne@eurosafeuk.com",
    "displayName": "Lucas Cawthorne",
    "surname": "Cawthorne",
    "mail": "LCawthorne@eurosafeuk.com",
    "givenName": "Lucas",
    "id": "0f932e05-e548-43f4-a8e1-11533fac9ce1",
    "userType": "Member",
    "jobTitle": "Sales & Training Administrator",
    "department": "Training",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (Ext 326)",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "6/23/2023 7:45:39 AM"
  },
  {
    "userPrincipalName": "acollings@eurosafeuk.com",
    "displayName": "Alan Collings",
    "surname": "Collings",
    "mail": "acollings@eurosafeuk.com",
    "givenName": "Alan",
    "id": "0faa828a-4525-4e7e-a71c-c4a67904cead",
    "userType": "Member",
    "jobTitle": "Site Operative",
    "department": "Repairs",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Compliance",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "6/11/2021 1:31:51 PM"
  },
  {
    "userPrincipalName": "EurosafeSolutionsITProject@eurosafesolutions.co.uk",
    "displayName": "Eurosafe Solutions IT Project",
    "surname": "",
    "mail": "EurosafeSolutionsITProject@eurosafesolutions.co.uk",
    "givenName": "",
    "id": "100cb5cf-51ab-4144-9783-fe9988355511",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "True",
    "usageLocation": "",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "2/3/2023 1:40:33 PM"
  },
  {
    "userPrincipalName": "facade@eurosafesolutions.co.uk",
    "displayName": "facade",
    "surname": "",
    "mail": "facade@eurosafeuk.com",
    "givenName": "",
    "id": "108346e9-53b3-43ee-82b7-f31e5327dc4b",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "False",
    "usageLocation": "",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "6/21/2018 12:48:08 PM"
  },
  {
    "userPrincipalName": "IMallett@eurosafeuk.com",
    "displayName": "Ian Mallett",
    "surname": "Mallett",
    "mail": "IMallett@eurosafeuk.com",
    "givenName": "Ian",
    "id": "10e54a3a-421c-4154-be33-399ccc3aa9b1",
    "userType": "Member",
    "jobTitle": "Finance Director",
    "department": "Finance",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (ext 202)",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "1/17/2017 12:45:01 PM"
  },
  {
    "userPrincipalName": "JJenkins@eurosafeuk.com",
    "displayName": "Jack Jenkins",
    "surname": "Jenkins",
    "mail": "JJenkins@eurosafeuk.com",
    "givenName": "Jack",
    "id": "1128f142-a8b2-419a-b573-1384b93848b4",
    "userType": "Member",
    "jobTitle": "Warehouse Assistant",
    "department": "Warehouse & Purchasing",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Contracting",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (Ext 340)",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Contracting",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "9/25/2023 9:45:52 AM"
  },
  {
    "userPrincipalName": "BWright@eurosafeuk.com",
    "displayName": "Bailey Wright",
    "surname": "Wright",
    "mail": "BWright@eurosafeuk.com",
    "givenName": "Bailey",
    "id": "11756015-c1b7-4dac-b237-b028c492763b",
    "userType": "Member",
    "jobTitle": "Site Operative",
    "department": "Compliance",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Compliance",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "4/10/2024 10:17:37 AM"
  },
  {
    "userPrincipalName": "PBolton@eurosafeuk.com",
    "displayName": "Phillipa Bolton",
    "surname": "Bolton",
    "mail": "PBolton@eurosafeuk.com",
    "givenName": "Phillipa",
    "id": "121ff45a-fd28-4d8b-8f18-2407a6388931",
    "userType": "Member",
    "jobTitle": "Remedial Division Admin Assistant",
    "department": "Test and Inspection Operations",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "United Kingdom",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (Ext 335)",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "False",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "9/14/2023 3:45:30 PM"
  },
  {
    "userPrincipalName": "G3ca01cbfda464b8b9caa66a8be5f91d9@eurosafesolutions.co.uk",
    "displayName": "Test & Inspection Records",
    "surname": "",
    "mail": "Test&InspectionRecord@eurosafeuk.com",
    "givenName": "",
    "id": "136671f7-7c2e-424d-9dc2-5bad7df17153",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "False",
    "usageLocation": "",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "4/17/2019 1:55:55 PM"
  },
  {
    "userPrincipalName": "AMarshall@eurosafeuk.com",
    "displayName": "Alex Marshall",
    "surname": "Marshall",
    "mail": "AMarshall@eurosafeuk.com",
    "givenName": "Alex",
    "id": "1377e23e-7f4d-46c3-bd9a-cbcbe0b96de0",
    "userType": "Member",
    "jobTitle": "Sales Advisor",
    "department": "Compliance",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Compliance",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (ext 407)",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Compliance",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "2/20/2024 4:27:28 PM"
  },
  {
    "userPrincipalName": "DanielSmith@eurosafeuk.com",
    "displayName": "Daniel Smith",
    "surname": "Smith",
    "mail": "DanielSmith@eurosafeuk.com",
    "givenName": "Daniel",
    "id": "13c91c3d-2a27-4cf6-b551-11293b42e330",
    "userType": "Member",
    "jobTitle": "Site Operative",
    "department": "Compliance",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Compliance",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "6/23/2023 8:16:26 AM"
  },
  {
    "userPrincipalName": "Kratz@eurosafeuk.com",
    "displayName": "Erik Kratz",
    "surname": "Kratz",
    "mail": "EKratz@eurosafeuk.com",
    "givenName": "Erik",
    "id": "14377788-7de8-49a5-ba6c-ed2a4e8d8b11",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "8/21/2019 2:53:07 PM"
  },
  {
    "userPrincipalName": "RHarris@eurosafeuk.com",
    "displayName": "Rachel Harris",
    "surname": "Harris",
    "mail": "RHarris@eurosafeuk.com",
    "givenName": "Rachel",
    "id": "14c047b7-405a-4818-b947-ba3c5136e780",
    "userType": "Member",
    "jobTitle": "Senior Credit Control",
    "department": "Finance",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "2/8/2023 10:58:47 AM"
  },
  {
    "userPrincipalName": "LDestecroix@eurosafesolutions.co.uk",
    "displayName": "Lee De-Ste-Croix",
    "surname": "De-Ste-Croix",
    "mail": "LDestecroix@eurosafesolutions.co.uk",
    "givenName": "Lee",
    "id": "16a3613f-e1ad-4bef-86eb-053296f1437c",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "False",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "9/25/2023 6:45:38 AM"
  },
  {
    "userPrincipalName": "MHeavens@eurosafesolutions.co.uk",
    "displayName": "Maximilian Heavens",
    "surname": "Heavens",
    "mail": "MHeavens@eurosafesolutions.co.uk",
    "givenName": "Maximilian",
    "id": "16d1c3ee-3924-49c6-9824-6c0218ee2281",
    "userType": "Member",
    "jobTitle": "Data Analyst",
    "department": "Test & Inspection - Sales",
    "accountEnabled": "False",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (Ext 217)",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "11/19/2021 10:42:56 AM"
  },
  {
    "userPrincipalName": "WorkDiaryUK@eurosafesolutions.co.uk",
    "displayName": "WorkDiaryUK",
    "surname": "",
    "mail": "WorkDiaryUK@eurosafeuk.com",
    "givenName": "",
    "id": "16da9c57-0118-4aef-929a-4eb7d75bf327",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "False",
    "usageLocation": "",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "6/29/2018 2:30:59 PM"
  },
  {
    "userPrincipalName": "Gellis@eurosafeuk.com",
    "displayName": "Gavin Ellis",
    "surname": "Ellis",
    "mail": "GEllis@eurosafeuk.com",
    "givenName": "Gavin",
    "id": "1727da58-db8e-425d-a0f0-5f9cb190d34d",
    "userType": "Member",
    "jobTitle": "Group CEO",
    "department": "",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (ext 201)",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "1/17/2017 12:44:56 PM"
  },
  {
    "userPrincipalName": "LRayworth@eurosafeuk.com",
    "displayName": "Lauren Rayworth",
    "surname": "Rayworth",
    "mail": "LRayworth@eurosafeuk.com",
    "givenName": "Lauren",
    "id": "175dc340-8ece-4551-8a32-2330027e1636",
    "userType": "Member",
    "jobTitle": "Retentions Account Manager",
    "department": "Compliance",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Compliance",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "01142507411 (EXT. 229)",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Compliance",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "5/23/2023 3:46:00 PM"
  },
  {
    "userPrincipalName": "aadlington@eurosafeuk.com",
    "displayName": "Andy Adlington",
    "surname": "Adlington",
    "mail": "AAdlington@eurosafeuk.com",
    "givenName": "Andy",
    "id": "1775c828-d452-4229-ba68-df360594f25a",
    "userType": "Member",
    "jobTitle": "Sales Advisor Team Leader",
    "department": "Compliance",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Compliance",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (Ext 211)",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Compliance",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "8/10/2022 10:29:43 AM"
  },
  {
    "userPrincipalName": "SAbela@eurosafeuk.com",
    "displayName": "Steve Abela",
    "surname": "Abela",
    "mail": "SAbela@eurosafeuk.com",
    "givenName": "Steve",
    "id": "179b039c-9ca0-49f2-956b-d4dd1c3cc26b",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "False",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "8/4/2023 8:03:14 AM"
  },
  {
    "userPrincipalName": "PBerry-Paxton@eurosafesolutions.co.uk",
    "displayName": "Peter Berry-Paxton",
    "surname": "Berry-Paxton",
    "mail": "PBerry-Paxton@eurosafesolutions.co.uk",
    "givenName": "Peter",
    "id": "1a2e5a9b-9fc2-4604-abb4-01b67b791c79",
    "userType": "Member",
    "jobTitle": "Site Operative",
    "department": "Test & Inspection",
    "accountEnabled": "False",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "5/22/2023 3:37:29 PM"
  },
  {
    "userPrincipalName": "NMerrall@eurosafeuk.com",
    "displayName": "Nadine Merrall",
    "surname": "Merrall",
    "mail": "NMerrall@eurosafeuk.com",
    "givenName": "Nadine",
    "id": "1aa665f3-7ed0-4eb3-a0d2-3bda51c28b06",
    "userType": "Member",
    "jobTitle": "Sales Advisor",
    "department": "Compliance",
    "accountEnabled": "False",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Compliance",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (ext 344)",
    "mobilePhone": "0114 250 7411 (ext 344)",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Compliance",
    "creationType": "",
    "directorySynced": "False",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "11/21/2023 9:01:53 AM"
  },
  {
    "userPrincipalName": "CWood@eurosafeuk.com",
    "displayName": "Chris Wood",
    "surname": "Wood",
    "mail": "CWood@eurosafeuk.com",
    "givenName": "Chris",
    "id": "1ac23d09-5dae-4b14-84f5-350fac5f72c7",
    "userType": "Member",
    "jobTitle": "Accounts Receivables Manager",
    "department": "Finance",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (EXT - 273)",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "6/6/2023 8:58:47 AM"
  },
  {
    "userPrincipalName": "tirlam@eurosafeuk.com",
    "displayName": "Tom Irlam",
    "surname": "Irlam",
    "mail": "TIrlam@eurosafeuk.com",
    "givenName": "Tom",
    "id": "1b82c7d6-dc05-45b0-9431-0235255929a1",
    "userType": "Member",
    "jobTitle": "Site Supervisor",
    "department": "Compliance",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Compliance",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Compliance",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "10/4/2022 8:50:20 AM"
  },
  {
    "userPrincipalName": "administrator@eurosafesolutions.co.uk",
    "displayName": "Administrator",
    "surname": "",
    "mail": "administrator@eurosafeuk.com",
    "givenName": "",
    "id": "1c12c0a5-6165-4072-b84e-9443aa6ba73c",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "False",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "9/5/2019 12:33:42 PM"
  },
  {
    "userPrincipalName": "KKelleher@eurosafeuk.com",
    "displayName": "Kye Kelleher",
    "surname": "Kelleher",
    "mail": "KKelleher@eurosafeuk.com",
    "givenName": "Kye",
    "id": "1e45c14f-b898-43e1-bbc1-5ae709832233",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "False",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "11/3/2023 12:38:02 PM"
  },
  {
    "userPrincipalName": "DanielWright@eurosafeuk.com",
    "displayName": "Daniel Wright",
    "surname": "Wright",
    "mail": "DanielWright@eurosafeuk.com",
    "givenName": "Daniel",
    "id": "209d7b97-7bd5-4cdb-9b68-18b8ca3e21ac",
    "userType": "Member",
    "jobTitle": "Contracts Co-Ordinator",
    "department": "Compliance",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Compliance",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Compliance",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "2/12/2024 2:24:43 PM"
  },
  {
    "userPrincipalName": "JLenton@eurosafeuk.com",
    "displayName": "Joe Lenton",
    "surname": "Lenton",
    "mail": "jlenton@eurosafeuk.com",
    "givenName": "Joe",
    "id": "22673b73-9acb-41a8-94ea-68a2b856bdf2",
    "userType": "Member",
    "jobTitle": "Site Operative",
    "department": "Compliance",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Compliance",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Compliance",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "3/19/2024 11:40:19 AM"
  },
  {
    "userPrincipalName": "DStewart@eurosafeuk.com",
    "displayName": "Dave Stewart",
    "surname": "Stewart",
    "mail": "DStewart@eurosafeuk.com",
    "givenName": "Dave",
    "id": "23600b0c-f251-4122-bc98-f7a2f370f38f",
    "userType": "Member",
    "jobTitle": "Project Manager",
    "department": "Installations",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Contracting",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411",
    "mobilePhone": "07494473804",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Contracting",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "10/16/2023 9:35:36 AM"
  },
  {
    "userPrincipalName": "JHorobin@eurosafeuk.com",
    "displayName": "Joshua Horobin",
    "surname": "Horobin",
    "mail": "JHorobin@eurosafeuk.com",
    "givenName": "Joshua",
    "id": "23da3745-e579-488e-a4b6-b3cd3cd58708",
    "userType": "Member",
    "jobTitle": "Senior Sales Estimator",
    "department": "Sales",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Contracting",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (Ext 306)",
    "mobilePhone": "07494473806",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Contracting",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "6/19/2018 6:51:50 AM"
  },
  {
    "userPrincipalName": "ATIteam1@eurosafeuk.com",
    "displayName": "T&I Team 1",
    "surname": "Team 1",
    "mail": "ATIteam1@eurosafeuk.com",
    "givenName": "T&I",
    "id": "23dbda03-10ad-48da-8b6e-b0ef66bb1fdc",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "4/18/2019 3:03:23 PM"
  },
  {
    "userPrincipalName": "FacadeUK@eurosafesolutions.co.uk",
    "displayName": "Facadeuk",
    "surname": "",
    "mail": "facadeuk@eurosafeuk.com",
    "givenName": "",
    "id": "26d4a054-81fd-4e70-9da2-de682fa8111c",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "False",
    "usageLocation": "",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "6/28/2018 2:31:53 PM"
  },
  {
    "userPrincipalName": "RFletcher@eurosafesolutions.co.uk",
    "displayName": "Ryan Fletcher",
    "surname": "Fletcher",
    "mail": "RFletcher@eurosafesolutions.co.uk",
    "givenName": "Ryan",
    "id": "273b2db2-692f-49ed-a9e1-3ff8c3a07785",
    "userType": "Member",
    "jobTitle": "Sales Advisor",
    "department": "Test & Inspection - Sales",
    "accountEnabled": "False",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "United Kingdom",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (Ext 218)",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "False",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "8/4/2020 8:33:58 AM"
  },
  {
    "userPrincipalName": "amellard@eurosafeuk.com",
    "displayName": "Alex Mellard",
    "surname": "Mellard",
    "mail": "amellard@eurosafeuk.com",
    "givenName": "Alex",
    "id": "285e2889-5e77-4595-b2e3-dd291557e2ca",
    "userType": "Member",
    "jobTitle": "Site Supervisor",
    "department": "Lightning Protection",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "Unit 51B, Orgreave Drive",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Compliance",
    "city": "Sheffield",
    "postalCode": "S13 9NR",
    "telephoneNumber": "0114 2507411",
    "mobilePhone": "07377679839",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "6/21/2018 1:04:15 PM"
  },
  {
    "userPrincipalName": "DHayward@eurosafesolutions.co.uk",
    "displayName": "Darren Hayward",
    "surname": "Hayward",
    "mail": "DHayward@eurosafesolutions.co.uk",
    "givenName": "Darren",
    "id": "29323d6f-f3a0-4ef5-b990-4ee5e6ca6af2",
    "userType": "Member",
    "jobTitle": "Site Operative",
    "department": "Test & Inspection",
    "accountEnabled": "False",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "5/22/2023 3:31:49 PM"
  },
  {
    "userPrincipalName": "TrainingRoom1@eurosafeuk.com",
    "displayName": "Training Room1",
    "surname": "Room1",
    "mail": "",
    "givenName": "Training",
    "id": "2994c581-bd2e-447e-a832-7360ac8908eb",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "3/22/2021 4:31:29 PM"
  },
  {
    "userPrincipalName": "JPearson@eurosafeuk.com",
    "displayName": "Jacob Pearson",
    "surname": "Pearson",
    "mail": "JPearson@eurosafeuk.com",
    "givenName": "Jacob",
    "id": "29ba3c83-85c7-4ad2-9a05-8e3f97c18a84",
    "userType": "Member",
    "jobTitle": "Apprentice Quantity Surveyor",
    "department": "Installations",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Contracting",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (Ext 424)",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Contracting",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "7/5/2024 11:34:34 AM"
  },
  {
    "userPrincipalName": "nedgar@eurosafeuk.com",
    "displayName": "Nikki Edgar",
    "surname": "Edgar",
    "mail": "nedgar@eurosafeuk.com",
    "givenName": "Nikki",
    "id": "2a175bf8-cff7-4b83-a641-43735b6f021e",
    "userType": "Member",
    "jobTitle": "Site Supervisor",
    "department": "Lightning Protection",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Compliance",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "6/10/2022 1:59:00 PM"
  },
  {
    "userPrincipalName": "InfoUK@eurosafesolutions.co.uk",
    "displayName": "InfoUK",
    "surname": "",
    "mail": "infouk@eurosafeuk.com",
    "givenName": "",
    "id": "2a8ce695-4f4c-46db-bb24-f236079d6bf7",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "False",
    "usageLocation": "",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "6/28/2018 1:54:17 PM"
  },
  {
    "userPrincipalName": "JRaynes@eurosafesolutions.co.uk",
    "displayName": "Jordan Raynes",
    "surname": "Raynes",
    "mail": "JRaynes@eurosafesolutions.co.uk",
    "givenName": "Jordan",
    "id": "2aa622d3-4f9a-4603-8205-e38e9985321a",
    "userType": "Member",
    "jobTitle": "T&I Contract Co-Ordinator",
    "department": "Test & Inspection - Operations",
    "accountEnabled": "False",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (Ext 233)",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "6/30/2022 2:34:34 PM"
  },
  {
    "userPrincipalName": "PNelson@eurosafeuk.com",
    "displayName": "Peter Nelson",
    "surname": "Nelson",
    "mail": "PNelson@eurosafeuk.com",
    "givenName": "Peter",
    "id": "2aeb9901-4881-442a-9270-0735dd1a3ee8",
    "userType": "Member",
    "jobTitle": "Senior Designer",
    "department": "Design",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Contracting",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "01142507411 (ext 292)",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Contracting",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "12/17/2019 11:25:10 AM"
  },
  {
    "userPrincipalName": "JHumphreys@eurosafeuk.com",
    "displayName": "Joe Humphreys",
    "surname": "Humphreys",
    "mail": "JHumphreys@eurosafeuk.com",
    "givenName": "Joe",
    "id": "2bdb91cd-f033-4225-9e84-94551a43cc87",
    "userType": "Member",
    "jobTitle": "Site Operative",
    "department": "Compliance",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Compliance",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Compliance",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "3/26/2024 1:43:25 PM"
  },
  {
    "userPrincipalName": "HNewton@eurosafeuk.com",
    "displayName": "Harry Newton",
    "surname": "Newton",
    "mail": "HNewton@eurosafeuk.com",
    "givenName": "Harry",
    "id": "2d6d4068-6512-4237-b7ee-ae82db920013",
    "userType": "Member",
    "jobTitle": "Retentions Team Leader",
    "department": "Compliance",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Compliance",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "01142507411 (EXT 226)",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Compliance",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "3/17/2023 8:42:56 AM"
  },
  {
    "userPrincipalName": "LArchibald@eurosafesolutions.co.uk",
    "displayName": "Luke Archibald",
    "surname": "Archibald",
    "mail": "LArchibald@eurosafesolutions.co.uk",
    "givenName": "Luke",
    "id": "2f2cc94e-df1c-4402-a2dd-0d000316b3b6",
    "userType": "Member",
    "jobTitle": "Site Operative",
    "department": "Contracts",
    "accountEnabled": "False",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "2/6/2023 10:59:16 AM"
  },
  {
    "userPrincipalName": "LStevenson@eurosafeuk.com",
    "displayName": "Luke Stevenson",
    "surname": "Stevenson",
    "mail": "LStevenson@eurosafeuk.com",
    "givenName": "Luke",
    "id": "2f4252da-79da-4a60-a61b-df96512b27e7",
    "userType": "Member",
    "jobTitle": "Project Manager",
    "department": "Installations",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Contracting",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411",
    "mobilePhone": "07944118177",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Contracting",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "1/5/2022 11:54:22 AM"
  },
  {
    "userPrincipalName": "ATITeam28@eurosafeuk.com",
    "displayName": "ATI Team28",
    "surname": "Team28",
    "mail": "ATITeam28@eurosafeuk.com",
    "givenName": "ATI",
    "id": "30d838e6-9f96-4964-baad-19e27ab1cbd5",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "5/16/2024 2:57:17 PM"
  },
  {
    "userPrincipalName": "jfairest@eurosafesolutions.co.uk",
    "displayName": "Jamie Fairest",
    "surname": "Fairest",
    "mail": "jfairest@eurosafesolutions.co.uk",
    "givenName": "Jamie",
    "id": "31024bf8-e403-41c1-902c-787afb8a544c",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "False",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "6/13/2022 7:45:26 AM"
  },
  {
    "userPrincipalName": "ATITeam20@eurosafeuk.com",
    "displayName": "ATITeam20@eurosafesolutions.co.uk",
    "surname": "Team 20",
    "mail": "ATITeam20@eurosafeuk.com",
    "givenName": "ATI",
    "id": "311d83e5-8957-4e3a-bf7c-a394a10164a8",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "07939973305",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "4/8/2022 8:37:38 AM"
  },
  {
    "userPrincipalName": "HelpdeskUK@eurosafesolutions.co.uk",
    "displayName": "HelpdeskUK",
    "surname": "",
    "mail": "helpdeskuk@eurosafeuk.com",
    "givenName": "",
    "id": "3136343e-8bc0-437f-b18d-66e971010f7e",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "False",
    "usageLocation": "",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "6/28/2018 1:56:49 PM"
  },
  {
    "userPrincipalName": "REaton@eurosafeuk.com",
    "displayName": "Ryan Eaton",
    "surname": "Eaton",
    "mail": "REaton@eurosafeuk.com",
    "givenName": "Ryan",
    "id": "314d9075-6ae6-4d1a-b158-8ff491519c9f",
    "userType": "Member",
    "jobTitle": "Site Operative",
    "department": "Contracting",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Contracting",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Contracting",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "9/9/2024 1:39:36 PM"
  },
  {
    "userPrincipalName": "Jobs@eurosafesolutions.co.uk",
    "displayName": "Jobs",
    "surname": "",
    "mail": "jobs@eurosafeuk.com",
    "givenName": "",
    "id": "323e3dc3-40ab-45d7-9b4b-bdfca279ab55",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "False",
    "usageLocation": "",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "6/28/2018 3:25:25 PM"
  },
  {
    "userPrincipalName": "CTolliday@eurosafesolutions.co.uk",
    "displayName": "Charlotte Tolliday",
    "surname": "Tolliday",
    "mail": "CTolliday@eurosafesolutions.co.uk",
    "givenName": "Charlotte",
    "id": "327b8874-0933-473f-a30a-0152b9498f5b",
    "userType": "Member",
    "jobTitle": "Senior Account Manager",
    "department": "Test & Inspection",
    "accountEnabled": "False",
    "usageLocation": "GB",
    "streetAddress": "Unit 51B, Orgreave Drive",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "Sheffield",
    "postalCode": "S13 9NR",
    "telephoneNumber": "0114 2507411 Ext. 239",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "2/23/2018 2:36:39 PM"
  },
  {
    "userPrincipalName": "pdolan@eurosafeuk.com",
    "displayName": "Pete Dolan",
    "surname": "Dolan",
    "mail": "PDolan@eurosafeuk.com",
    "givenName": "Pete",
    "id": "33a379c5-452b-4c5b-b88e-912732879e21",
    "userType": "Member",
    "jobTitle": "Warehouse Manager",
    "department": "Warehouse & Purchasing",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Contracting",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (Ext 264)",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Contracting",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "9/3/2018 10:41:48 AM"
  },
  {
    "userPrincipalName": "LoSullivan@eurosafeuk.com",
    "displayName": "Laura O'Sullivan",
    "surname": "O'Sullivan",
    "mail": "LOSullivan@eurosafeuk.com",
    "givenName": "Laura",
    "id": "33c80c4c-1397-41ab-bbd2-5676a25cb8c1",
    "userType": "Member",
    "jobTitle": "Retentions Manager",
    "department": "Compliance",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Compliance",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (Ext 216)",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Compliance",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "5/17/2021 12:45:48 PM"
  },
  {
    "userPrincipalName": "LNaylor@eurosafeuk.com",
    "displayName": "Luke Naylor",
    "surname": "Naylor",
    "mail": "LNaylor@eurosafeuk.com",
    "givenName": "Luke",
    "id": "356dd93d-3e85-4dd9-9a17-460a6a92fa18",
    "userType": "Member",
    "jobTitle": "Sales Advisor",
    "department": "Compliance",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Compliance",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (Ext 408)",
    "mobilePhone": "0114 250 7411 (Ext 408)",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Compliance",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "3/8/2024 11:05:31 AM"
  },
  {
    "userPrincipalName": "CSenior@eurosafeuk.com",
    "displayName": "Chay Senior",
    "surname": "Senior",
    "mail": "CSenior@eurosafeuk.com",
    "givenName": "Chay",
    "id": "35d7087a-73b9-4b02-91e4-eda75b890d92",
    "userType": "Member",
    "jobTitle": "Site Operative",
    "department": "Installations",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Contracting",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "4/22/2021 2:37:10 PM"
  },
  {
    "userPrincipalName": "SMiller@eurosafeuk.com",
    "displayName": "Simon Miller",
    "surname": "Miller",
    "mail": "SMiller@eurosafeuk.com",
    "givenName": "Simon",
    "id": "35ff3f14-b083-4414-9034-9609ab128afb",
    "userType": "Member",
    "jobTitle": "Training Manager",
    "department": "Training",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Training & PPE",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (Ext 323)",
    "mobilePhone": "0796 005 2433",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Training & PPE",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "1/15/2021 12:11:36 PM"
  },
  {
    "userPrincipalName": "ATITeam19@eurosafeuk.com",
    "displayName": "ATI Team19",
    "surname": "Team19",
    "mail": "ATITeam19@eurosafeuk.com",
    "givenName": "ATI",
    "id": "36195a3c-f104-43ea-a8c0-0f35840db7a3",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "07498145348",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "3/21/2022 9:42:14 AM"
  },
  {
    "userPrincipalName": "LTyler@eurosafeuk.com",
    "displayName": "Lauren Tyler",
    "surname": "Tyler",
    "mail": "LTyler@eurosafeuk.com",
    "givenName": "Lauren",
    "id": "3766c767-234b-4b15-a579-7789067d0ad7",
    "userType": "Member",
    "jobTitle": "Compliance Manager",
    "department": "Process Department",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (Ext 307)",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "11/29/2018 2:29:58 PM"
  },
  {
    "userPrincipalName": "accountspayable@eurosafeuk.com",
    "displayName": "AccountsPayable",
    "surname": "",
    "mail": "accountspayable@eurosafeuk.com",
    "givenName": "",
    "id": "382502f2-ccbb-4f99-9a43-8043f78681fa",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "True",
    "usageLocation": "",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "5/20/2024 10:40:10 AM"
  },
  {
    "userPrincipalName": "ACarter@eurosafeuk.com",
    "displayName": "Adam Carter",
    "surname": "Carter",
    "mail": "ACarter@eurosafeuk.com",
    "givenName": "Adam",
    "id": "3828acfd-2209-49c8-8f4a-5579584f3e25",
    "userType": "Member",
    "jobTitle": "Site Supervisor",
    "department": "Compliance",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Compliance",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "07891066408",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "6/4/2021 9:53:57 AM"
  },
  {
    "userPrincipalName": "erp@eurosafeuk.com",
    "displayName": "ERP Mailer",
    "surname": "Mailer",
    "mail": "erp@eurosafeuk.com",
    "givenName": "ERP",
    "id": "388b9324-fc74-4ab9-ac0d-4eacffcc7b7a",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "9/28/2021 11:58:38 AM"
  },
  {
    "userPrincipalName": "RHornsby@eurosafesolutions.co.uk",
    "displayName": "Robert Hornsby",
    "surname": "Hornsby",
    "mail": "RHornsby@eurosafesolutions.co.uk",
    "givenName": "Robert",
    "id": "38edb6a9-f92e-4a2b-a844-ff6b328b8ed7",
    "userType": "Member",
    "jobTitle": "Site Supervisor",
    "department": "",
    "accountEnabled": "False",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "7/5/2022 8:55:29 AM"
  },
  {
    "userPrincipalName": "LGleaden@eurosafeuk.com",
    "displayName": "Luke Gleaden",
    "surname": "Gleaden",
    "mail": "LGleaden@eurosafeuk.com",
    "givenName": "Luke",
    "id": "3a67b305-e7ad-4591-a887-ea97be800863",
    "userType": "Member",
    "jobTitle": "Pre-Contracts Designer",
    "department": "Sales",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Contracting",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (Ext 308)",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Contracting",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "4/30/2021 1:20:05 PM"
  },
  {
    "userPrincipalName": "Pshaw@eurosafeuk.com",
    "displayName": "Patricia Shaw",
    "surname": "Shaw",
    "mail": "PShaw@eurosafeuk.com",
    "givenName": "Patricia",
    "id": "3aaaf16e-035d-4399-952b-14e6e4e8455d",
    "userType": "Member",
    "jobTitle": "Accounts Assistant",
    "department": "Finance",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (Ext 270)",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "7/6/2017 1:19:10 PM"
  },
  {
    "userPrincipalName": "RCooke@eurosafeuk.com",
    "displayName": "Ryan Cooke",
    "surname": "Cooke",
    "mail": "RCooke@eurosafeuk.com",
    "givenName": "Ryan",
    "id": "3b27cb27-4955-4c92-a6b3-5ece1f18e320",
    "userType": "Member",
    "jobTitle": "Site Operative",
    "department": "Test & Inspection",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Test & Inspection",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (Ext 267)",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "7/16/2019 9:23:41 AM"
  },
  {
    "userPrincipalName": "VVlad@eurosafeuk.com",
    "displayName": "Vilian Vlad",
    "surname": "Vlad",
    "mail": "VVlad@eurosafeuk.com",
    "givenName": "Vilian",
    "id": "3c3c85fa-b8f2-43dd-826c-cf7addb4ea1e",
    "userType": "Member",
    "jobTitle": "Site Supervisor",
    "department": "Installations",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Contracting",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "07464742908",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "12/4/2020 11:36:54 AM"
  },
  {
    "userPrincipalName": "atiteam25@eurosafeuk.com",
    "displayName": "ATITEAM25",
    "surname": 25,
    "mail": "atiteam25@eurosafeuk.com",
    "givenName": "ATITEAM",
    "id": "3d273b75-956f-42be-93d7-52085c9cb462",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "11/16/2023 10:43:51 AM"
  },
  {
    "userPrincipalName": "TrainingRoom2@eurosafeuk.com",
    "displayName": "Training Room2",
    "surname": "Room2",
    "mail": "",
    "givenName": "Training",
    "id": "3ddb3c2b-914b-4dd9-a93a-dc6ba2d80d48",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "3/22/2021 4:29:12 PM"
  },
  {
    "userPrincipalName": "dclarkson@eurosafesolutions.co.uk",
    "displayName": "Daniel Clarkson",
    "surname": "Clarkson",
    "mail": "dclarkson@eurosafesolutions.co.uk",
    "givenName": "Daniel",
    "id": "3f33dc39-417e-4d19-95e8-2fc5fffeea7e",
    "userType": "Member",
    "jobTitle": "Account Manager",
    "department": "Test & Inspection - Sales",
    "accountEnabled": "False",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (Ext 214)",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "8/10/2022 10:30:58 AM"
  },
  {
    "userPrincipalName": "AnthonySimms@eurosafeuk.com",
    "displayName": "Anthony Simms",
    "surname": "Simms",
    "mail": "AnthonySimms@eurosafeuk.com",
    "givenName": "Anthony",
    "id": "3f3c45ad-8782-47ac-a6e6-92cab6be0fd5",
    "userType": "Member",
    "jobTitle": "Quality Manager",
    "department": "Contracts",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Contracting",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "07398518173",
    "mobilePhone": "07398518173",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Contracting",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "9/18/2024 7:51:25 AM"
  },
  {
    "userPrincipalName": "RopeAccess@eurosafeuk.com",
    "displayName": "Rope Access",
    "surname": "Access",
    "mail": "ropeaccess@eurosafeuk.com",
    "givenName": "Rope",
    "id": "3f85e85c-1e36-47db-8274-e52012566429",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "10/22/2019 12:16:31 PM"
  },
  {
    "userPrincipalName": "Jweston@eurosafeuk.com",
    "displayName": "Jordan Weston",
    "surname": "Weston",
    "mail": "JWeston@eurosafeuk.com",
    "givenName": "Jordan",
    "id": "40e63fe1-ca7f-44ca-bd5c-f5f3df0bface",
    "userType": "Member",
    "jobTitle": "Team Operations Manager",
    "department": "Compliance",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Compliance",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (Ext 234)",
    "mobilePhone": "0747 749 2513",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Compliance",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "6/3/2019 12:40:13 PM"
  },
  {
    "userPrincipalName": "ABailes@eurosafeuk.com",
    "displayName": "Alex Bailes",
    "surname": "Bailes",
    "mail": "ABailes@eurosafeuk.com",
    "givenName": "Alex",
    "id": "412b6602-a599-467a-a5b9-619d5198f338",
    "userType": "Member",
    "jobTitle": "Site Operative",
    "department": "Compliance",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Compliance",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "11/21/2023 11:01:13 AM"
  },
  {
    "userPrincipalName": "MZhelyazkova@eurosafeuk.com",
    "displayName": "Megan Zhelyazkova",
    "surname": "Zhelyazkova",
    "mail": "MZhelyazkova@eurosafeuk.com",
    "givenName": "Megan",
    "id": "4136c57d-9b27-4787-a95a-358e3d9896dc",
    "userType": "Member",
    "jobTitle": "Senior Designer",
    "department": "Design",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Contracting",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "01142507411 (ext 419)",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Contracting",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "5/21/2024 1:41:22 PM"
  },
  {
    "userPrincipalName": "TrainingDiaryUK@eurosafeuk.com",
    "displayName": "TrainingDiaryUK",
    "surname": "",
    "mail": "trainingdiaryuk@eurosafeuk.com",
    "givenName": "",
    "id": "440bb8fd-22c8-42a1-95a3-06fa2e8218f5",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "False",
    "usageLocation": "",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "6/29/2018 11:25:10 AM"
  },
  {
    "userPrincipalName": "ATIteam14@eurosafeuk.com",
    "displayName": "T&I Team 14",
    "surname": "Team 14",
    "mail": "ATIteam14@eurosafeuk.com",
    "givenName": "T&I",
    "id": "444bdf9c-1bda-43db-8e0c-9ce6b64bbbd2",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "4/18/2019 3:46:53 PM"
  },
  {
    "userPrincipalName": "ebartlett@eurosafeuk.com",
    "displayName": "Erika Bartlett",
    "surname": "Bartlett",
    "mail": "EBartlett@eurosafeuk.com",
    "givenName": "Erika",
    "id": "44ddea2a-9198-4d59-b7ef-ef693e5c4618",
    "userType": "Member",
    "jobTitle": "Purchasing & Finance Administrator",
    "department": "Warehouse & Purchasing",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (EXT 350)",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "12/15/2023 11:12:31 AM"
  },
  {
    "userPrincipalName": "HBarcroft@eurosafeuk.com",
    "displayName": "Hannah Barcroft",
    "surname": "Barcroft",
    "mail": "HBarcroft@eurosafeuk.com",
    "givenName": "Hannah",
    "id": "4530f567-d53f-46c2-b9ae-4fb1cd4187c1",
    "userType": "Member",
    "jobTitle": "Commercial Manager",
    "department": "Installations",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Contracting",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (Ext 282)",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Contracting",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "9/24/2018 6:59:36 AM"
  },
  {
    "userPrincipalName": "LAvery@eurosafeuk.com",
    "displayName": "Lee Avery",
    "surname": "Avery",
    "mail": "LAvery@eurosafeuk.com",
    "givenName": "Lee",
    "id": "46170ac3-d6dc-44e9-a709-613c82de7b00",
    "userType": "Member",
    "jobTitle": "Site Operative",
    "department": "Compliance",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Contracting",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Compliance",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "11/9/2021 1:30:17 PM"
  },
  {
    "userPrincipalName": "JKirkland@eurosafeuk.com",
    "displayName": "Joshua Kirkland",
    "surname": "Kirkland",
    "mail": "JKirkland@eurosafeuk.com",
    "givenName": "Joshua",
    "id": "46755b7c-3747-40d8-9e3b-a1af8f4288f3",
    "userType": "Member",
    "jobTitle": "Quantity Surveyor",
    "department": "Installations",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Contracting",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (Ext 283)",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Contracting",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "5/21/2021 1:43:35 PM"
  },
  {
    "userPrincipalName": "SWiddowson@eurosafeuk.com",
    "displayName": "Samantha Widdowson",
    "surname": "Widdowson",
    "mail": "SWiddowson@eurosafeuk.com",
    "givenName": "Samantha",
    "id": "46b14e5c-685e-4f06-93d9-1e6f9c22becf",
    "userType": "Member",
    "jobTitle": "Finance Assistant",
    "department": "Finance",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (Ext 268)",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "3/1/2019 8:37:50 AM"
  },
  {
    "userPrincipalName": "pmartin@eurosafeuk.com",
    "displayName": "Paul Martin",
    "surname": "Martin",
    "mail": "PMartin@eurosafeuk.com",
    "givenName": "Paul",
    "id": "483517ba-c683-42f9-9984-14e5d34ee1b8",
    "userType": "Member",
    "jobTitle": "Technical & Compliance Manager",
    "department": "Compliance",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Compliance",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (Ext 240)",
    "mobilePhone": "0781 855 4599",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Compliance",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "11/23/2020 8:45:23 AM"
  },
  {
    "userPrincipalName": "AQuinn@eurosafeuk.com",
    "displayName": "Alex Quinn",
    "surname": "Quinn",
    "mail": "AQuinn@eurosafeuk.com",
    "givenName": "Alex",
    "id": "49924668-53c6-40ab-8676-3ae3000029b4",
    "userType": "Member",
    "jobTitle": "Site Operative",
    "department": "Lightning Protection",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Compliance",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "8/29/2023 6:49:42 AM"
  },
  {
    "userPrincipalName": "Atiteam29@eurosafeuk.com",
    "displayName": "Atiteam 29",
    "surname": 29,
    "mail": "Atiteam29@eurosafeuk.com",
    "givenName": "Atiteam",
    "id": "49b042ca-6d30-473a-9ff2-8e74a8793007",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "5/31/2024 12:52:07 PM"
  },
  {
    "userPrincipalName": "AJennings@eurosafeuk.com",
    "displayName": "Andy Jennings",
    "surname": "Jennings",
    "mail": "AJennings@eurosafeuk.com",
    "givenName": "Andy",
    "id": "4a17160f-14ca-45f5-a851-1a79a28a5a83",
    "userType": "Member",
    "jobTitle": "Senior Trainer",
    "department": "Training",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Training & PPE",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (Ext 320)",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Training & PPE",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "1/17/2017 12:44:46 PM"
  },
  {
    "userPrincipalName": "ATITeam24@eurosafeuk.com",
    "displayName": "ATI Team 24",
    "surname": "Team",
    "mail": "ATITeam24@eurosafeuk.com",
    "givenName": "ATI",
    "id": "4ad12acb-811f-4871-b284-b3c94ecd1683",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "12/21/2023 3:31:05 PM"
  },
  {
    "userPrincipalName": "JMarsden@eurosafeuk.com",
    "displayName": "Jack Marsden",
    "surname": "Marsden",
    "mail": "JMarsden@eurosafeuk.com",
    "givenName": "Jack",
    "id": "4c8b1f9b-de1b-48b8-a04f-b6c9cb07eb9f",
    "userType": "Member",
    "jobTitle": "Site Operative",
    "department": "Compliance",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Compliance",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "12/22/2023 10:16:21 AM"
  },
  {
    "userPrincipalName": "EUpenieks@eurosafeuk.com",
    "displayName": "Eriks Upenieks",
    "surname": "Upenieks",
    "mail": "EUpenieks@eurosafeuk.com",
    "givenName": "Eriks",
    "id": "4d8d80de-48dd-47d8-a0dc-f86012464e55",
    "userType": "Member",
    "jobTitle": "Site Supervisor",
    "department": "Lightning Protection",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Compliance",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "7/15/2022 7:30:16 AM"
  },
  {
    "userPrincipalName": "abarton@eurosafeuk.com",
    "displayName": "Aaron Barton",
    "surname": "Barton",
    "mail": "abarton@eurosafeuk.com",
    "givenName": "Aaron",
    "id": "4dbf3791-ed24-4f86-be20-7baa84bd9ea7",
    "userType": "Member",
    "jobTitle": "Site Supervisor",
    "department": "Compliance",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Compliance",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "4/17/2024 11:14:12 AM"
  },
  {
    "userPrincipalName": "Timesheets-ATI@eurosafeuk.com",
    "displayName": "Timesheets-ATI",
    "surname": "",
    "mail": "Timesheets-ATI@eurosafeuk.com",
    "givenName": "",
    "id": "4df26a51-9360-451b-876d-7233338ba2ec",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "8/18/2020 10:49:08 AM"
  },
  {
    "userPrincipalName": "hotels@eurosafesolutions.co.uk",
    "displayName": "Hotels",
    "surname": "",
    "mail": "hotels@eurosafeuk.com",
    "givenName": "",
    "id": "4e4aa40d-cfca-496e-badf-cfc792c9f2d6",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "False",
    "usageLocation": "",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "9/5/2018 10:43:14 AM"
  },
  {
    "userPrincipalName": "AAtkin@eurosafeuk.com",
    "displayName": "Ashley Atkin",
    "surname": "Atkin",
    "mail": "AAtkin@eurosafeuk.com",
    "givenName": "Ashley",
    "id": "4eefde5b-367b-4f89-ad8a-cc20b19e60a8",
    "userType": "Member",
    "jobTitle": "Site Operative",
    "department": "Contracts",
    "accountEnabled": "False",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "11/22/2023 12:58:43 PM"
  },
  {
    "userPrincipalName": "Sync_ES-HYPERV-DC_851a23960591@eurosafeukltd.onmicrosoft.com",
    "displayName": "On-Premises Directory Synchronization Service Account",
    "surname": "",
    "mail": "",
    "givenName": "",
    "id": "4f47889d-d82c-481b-9dfc-001adaacb9a8",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "True",
    "usageLocation": "",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "2/14/2024 11:38:16 AM"
  },
  {
    "userPrincipalName": "ATITeam17@eurosafeuk.com",
    "displayName": "ATITeam17@eurosafesolutions.co.uk",
    "surname": "Team17",
    "mail": "ATITeam17@eurosafeuk.com",
    "givenName": "ATI",
    "id": "5194b36c-cbf3-4a23-9d03-bb436aa59777",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "7/2/2021 11:37:44 AM"
  },
  {
    "userPrincipalName": "JMason@eurosafeuk.com",
    "displayName": "Jakson Mason",
    "surname": "Mason",
    "mail": "JMason@eurosafeuk.com",
    "givenName": "Jakson",
    "id": "53deafea-8bfc-41b3-842f-0944f149472b",
    "userType": "Member",
    "jobTitle": "Site Operative",
    "department": "Compliance",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Compliance",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "5/22/2023 3:38:45 PM"
  },
  {
    "userPrincipalName": "hellis@eurosafeuk.com",
    "displayName": "Helen Ellis",
    "surname": "Ellis",
    "mail": "hellis@eurosafeuk.com",
    "givenName": "Helen",
    "id": "55e2c966-84a2-46bc-afe4-e9afcf4d4a9c",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "07929131859",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "5/26/2020 2:36:13 PM"
  },
  {
    "userPrincipalName": "RDolan@eurosafeuk.com",
    "displayName": "Richard Dolan",
    "surname": "Dolan",
    "mail": "RDolan@eurosafeuk.com",
    "givenName": "Richard",
    "id": "564fc96b-5648-437b-8fb6-26dc30164485",
    "userType": "Member",
    "jobTitle": "Operations Director, Façade Access",
    "department": "Installations",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Contracting",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (Ext 285)",
    "mobilePhone": "0796 826 2402",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Contracting",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "1/13/2017 10:59:28 AM"
  },
  {
    "userPrincipalName": "TISalesVM@eurosafeuk.com",
    "displayName": "TISales Voicemail",
    "surname": "",
    "mail": "TISalesVM@eurosafeuk.com",
    "givenName": "",
    "id": "56dbbba6-c660-442c-b8ca-379c304ade9e",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "True",
    "usageLocation": "",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "2/28/2024 10:08:32 AM"
  },
  {
    "userPrincipalName": "Purchasing@eurosafesolutions.co.uk",
    "displayName": "Purchasing",
    "surname": "",
    "mail": "purchasing@eurosafeuk.com",
    "givenName": "",
    "id": "571c56d0-03cf-4007-b189-9234aab86aab",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "True",
    "usageLocation": "",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "11/10/2021 8:01:37 AM"
  },
  {
    "userPrincipalName": "WDavies@eurosafeuk.com",
    "displayName": "Wynn Davies",
    "surname": "Davies",
    "mail": "WDavies@eurosafeuk.com",
    "givenName": "Wynn",
    "id": "594ef858-bd53-44ad-a7ec-b365080faa40",
    "userType": "Member",
    "jobTitle": "Business Development Manager",
    "department": "Training & PPE",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Training & PPE",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Training & PPE",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "10/14/2024 7:52:12 AM"
  },
  {
    "userPrincipalName": "ATIteam4@eurosafeuk.com",
    "displayName": "T&I Team 4",
    "surname": "Team 4",
    "mail": "ATIteam4@eurosafeuk.com",
    "givenName": "T&I",
    "id": "59ccc016-4d7b-49c2-93ff-25fcef70c5b2",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "4/18/2019 3:09:10 PM"
  },
  {
    "userPrincipalName": "EHorsfield@eurosafeuk.com",
    "displayName": "Emma Horsfield",
    "surname": "Horsfield",
    "mail": "EHorsfield@eurosafeuk.com",
    "givenName": "Emma",
    "id": "5a363b66-2e9b-4292-a66c-9b4c2bf51465",
    "userType": "Member",
    "jobTitle": "Contracts Administrator",
    "department": "Installations",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Contracting",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "01142507411 (ext 415)",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Contracting",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "4/23/2024 1:57:25 PM"
  },
  {
    "userPrincipalName": "ElliotAdmin@eurosafeukltd.onmicrosoft.com",
    "displayName": "Elliot Admin",
    "surname": "Admin",
    "mail": "",
    "givenName": "Elliot",
    "id": "5addd557-3e63-4f1b-a7c9-f35d1e044cca",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "True",
    "usageLocation": "",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "11/21/2023 5:02:21 PM"
  },
  {
    "userPrincipalName": "CLuck@eurosafeuk.com",
    "displayName": "Callie Luck",
    "surname": "Luck",
    "mail": "cluck@eurosafeuk.com",
    "givenName": "Callie",
    "id": "5b6600c9-e466-4f3f-8538-76da45fe82a8",
    "userType": "Member",
    "jobTitle": "Internal Operations Manager",
    "department": "Process Department",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (Ext 251)",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "1/13/2017 9:29:18 AM"
  },
  {
    "userPrincipalName": "JWilliams@eurosafeuk.com",
    "displayName": "Jerome Williams",
    "surname": "Williams",
    "mail": "JWilliams@eurosafeuk.com",
    "givenName": "Jerome",
    "id": "5d0a09d3-566f-4028-a912-d147bf831be1",
    "userType": "Member",
    "jobTitle": "Authorising Engineer",
    "department": "Compliance",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Compliance",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "07961906114",
    "mobilePhone": "07961906114",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Compliance",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "11/22/2023 10:02:47 AM"
  },
  {
    "userPrincipalName": "Twright@eurosafeuk.com",
    "displayName": "Tom Wright",
    "surname": "Wright",
    "mail": "TWright@eurosafeuk.com",
    "givenName": "Tom",
    "id": "5d37e102-f7e5-480e-893d-bdd303ffa6c5",
    "userType": "Member",
    "jobTitle": "Business Development Manager",
    "department": "Compliance",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Compliance",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (Ext 219)",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Compliance",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "1/17/2017 12:45:30 PM"
  },
  {
    "userPrincipalName": "Payslips@eurosafeuk.com",
    "displayName": "Payslips",
    "surname": "",
    "mail": "Payslips@eurosafeuk.com",
    "givenName": "Payslips",
    "id": "5dbcb8c8-5d95-4410-9813-41255f042559",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "6/24/2024 11:38:53 AM"
  },
  {
    "userPrincipalName": "GHatton@eurosafeuk.com",
    "displayName": "Gary Hatton",
    "surname": "Hatton",
    "mail": "GHatton@eurosafeuk.com",
    "givenName": "Gary",
    "id": "5dc32368-d246-439b-bec6-809414e1980b",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "11/3/2023 12:35:20 PM"
  },
  {
    "userPrincipalName": "EWT1@eurosafesolutions.co.uk",
    "displayName": "EWT1",
    "surname": "",
    "mail": "ewt1@eurosafeuk.com",
    "givenName": "EWT1",
    "id": "5f085347-f8ac-4665-ba2b-28c323c54c8e",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "False",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "7/7/2017 11:10:44 AM"
  },
  {
    "userPrincipalName": "JAmofah@eurosafeuk.com",
    "displayName": "Josh Amofah",
    "surname": "Amofah",
    "mail": "JAmofah@eurosafeuk.com",
    "givenName": "Josh",
    "id": "5fe85dcf-06ac-4bb6-896b-803ef25585f1",
    "userType": "Member",
    "jobTitle": "Sales Advisor",
    "department": "Test & Inspection Sales",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (228)",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "3/8/2023 2:31:57 PM"
  },
  {
    "userPrincipalName": "RKay@eurosafesolutions.co.uk",
    "displayName": "Ross Kay",
    "surname": "Kay",
    "mail": "RKay@eurosafesolutions.co.uk",
    "givenName": "Ross",
    "id": "604bd9d2-8096-41a3-a2d4-6ccbbebbfb63",
    "userType": "Member",
    "jobTitle": "Trainee Health and Safety Manager",
    "department": "Installations",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (Ext 287)",
    "mobilePhone": "0738 036 3340",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "3/4/2024 3:03:34 PM"
  },
  {
    "userPrincipalName": "ATITeam16@eurosafeuk.com",
    "displayName": "ATITeam16@eurosafesolutions.co.uk",
    "surname": "Team 16",
    "mail": "ATITeam16@eurosafeuk.com",
    "givenName": "ATI",
    "id": "60b219ea-cc68-4203-a981-312994717c12",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "07496983805",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "7/2/2021 11:34:48 AM"
  },
  {
    "userPrincipalName": "lomalleymabey@eurosafeuk.com",
    "displayName": "Lee O'Malley-Mabey",
    "surname": "O'Malley-Mabey",
    "mail": "lomalleymabey@eurosafeuk.com",
    "givenName": "Lee",
    "id": "61b7c051-ac7d-44fd-8c58-bf7a4cbba2f5",
    "userType": "Member",
    "jobTitle": "Site Operative",
    "department": "Compliance",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Compliance",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "4/12/2024 9:34:23 AM"
  },
  {
    "userPrincipalName": "Marketing@eurosafeuk.com",
    "displayName": "Marketing",
    "surname": "",
    "mail": "Marketing@eurosafeuk.com",
    "givenName": "",
    "id": "63ea1aac-805d-4cc2-bc81-fa54a5cf3df7",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "1/9/2023 10:18:28 AM"
  },
  {
    "userPrincipalName": "ATIteam13@eurosafeuk.com",
    "displayName": "T&I Team13",
    "surname": "Team13",
    "mail": "ATIteam13@eurosafeuk.com",
    "givenName": "T&I",
    "id": "6444d09c-74ff-4867-b79f-9beeffeed683",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "4/18/2019 3:46:12 PM"
  },
  {
    "userPrincipalName": "THumphreys@eurosafeuk.com",
    "displayName": "Tom Humphreys",
    "surname": "Humphreys",
    "mail": "THumphreys@eurosafeuk.com",
    "givenName": "Tom",
    "id": "647dae53-ed5a-4daa-bf90-50a782096781",
    "userType": "Member",
    "jobTitle": "Site Operative",
    "department": "Compliance",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Compliance",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "6/12/2023 8:24:33 AM"
  },
  {
    "userPrincipalName": "elearning@eurosafeuk.com",
    "displayName": "E Learning",
    "surname": "Learning",
    "mail": "elearning@eurosafeukltd.onmicrosoft.com",
    "givenName": "E",
    "id": "64d126fe-39e8-48d4-86cf-a9cb9ce92f6d",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "1/17/2023 8:16:46 AM"
  },
  {
    "userPrincipalName": "CWhite@eurosafesolutions.co.uk",
    "displayName": "Chelsie White",
    "surname": "White",
    "mail": "CWhite@eurosafesolutions.co.uk",
    "givenName": "Chelsie",
    "id": "655ab2a3-f6e1-4395-b137-19f9c96c7d49",
    "userType": "Member",
    "jobTitle": "Accounts Manager",
    "department": "Test & Inspections - Sales",
    "accountEnabled": "False",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (Ext 213)",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "12/14/2020 3:04:07 PM"
  },
  {
    "userPrincipalName": "JClark@eurosafeuk.com",
    "displayName": "James Clark",
    "surname": "Clark",
    "mail": "JClark@eurosafeuk.com",
    "givenName": "James",
    "id": "65a89102-4691-4982-ba0c-b6cbe3ea0bfe",
    "userType": "Member",
    "jobTitle": "Site Operative",
    "department": "Repairs",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Repairs",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Compliance",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "8/20/2024 7:58:29 AM"
  },
  {
    "userPrincipalName": "MGodbehere@eurosafeuk.com",
    "displayName": "Megan Godbehere",
    "surname": "Godbehere",
    "mail": "MGodbehere@eurosafeuk.com",
    "givenName": "Megan",
    "id": "6608f270-31d3-4327-8e03-5982187b9b87",
    "userType": "Member",
    "jobTitle": "Compliance Administrator",
    "department": "Compliance",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Compliance",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (Ext 422)",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Compliance",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "6/11/2024 12:52:36 PM"
  },
  {
    "userPrincipalName": "aearle@eurosafeuk.com",
    "displayName": "Andrew Earle",
    "surname": "Earle",
    "mail": "AEarle@eurosafeuk.com",
    "givenName": "Andrew",
    "id": "66184302-9dd4-44e1-9f5f-6333adc8d033",
    "userType": "Member",
    "jobTitle": "Quality & Development Manager",
    "department": "Compliance",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Compliance",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (Ext 230)",
    "mobilePhone": "0784 148 7583",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Compliance",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "6/21/2018 1:03:00 PM"
  },
  {
    "userPrincipalName": "Sales@eurosafeuk.com",
    "displayName": "Sales",
    "surname": "",
    "mail": "Sales@eurosafeuk.com",
    "givenName": "Sales",
    "id": "671f9715-8a5e-4716-9d94-b71e370d1ccc",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "6/21/2018 10:47:34 AM"
  },
  {
    "userPrincipalName": "KJones@eurosafeuk.com",
    "displayName": "Kathryn Jones",
    "surname": "Jones",
    "mail": "KJones@eurosafeuk.com",
    "givenName": "Kathryn",
    "id": "67985524-8e4f-452a-8139-f40e19e8cbbb",
    "userType": "Member",
    "jobTitle": "Renewals Account Manager",
    "department": "Compliance",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Compliance",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "01142507411 (EXT 420)",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Compliance",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "5/31/2024 9:17:00 AM"
  },
  {
    "userPrincipalName": "Jknight@eurosafeuk.com",
    "displayName": "Joshua Knight",
    "surname": "Knight",
    "mail": "Jknight@eurosafeuk.com",
    "givenName": "Joshua",
    "id": "6aa92760-f287-4bf0-8406-673cbbb8a76c",
    "userType": "Member",
    "jobTitle": "Compliance Coordination Manager",
    "department": "Compliance",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Compliance",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (Ext 236)",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Compliance",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "1/17/2017 12:45:11 PM"
  },
  {
    "userPrincipalName": "TBassinder@eurosafesolutions.co.uk",
    "displayName": "Tom Bassinder",
    "surname": "Bassinder",
    "mail": "TBassinder@eurosafesolutions.co.uk",
    "givenName": "Tom",
    "id": "6b0e4733-3ffe-434d-b349-23613e554a80",
    "userType": "Member",
    "jobTitle": "Site Operative",
    "department": "Test & Inspection",
    "accountEnabled": "False",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "1/3/2023 2:38:28 PM"
  },
  {
    "userPrincipalName": "JOldroyd@eurosafeuk.com",
    "displayName": "Jonathan Oldroyd",
    "surname": "Oldroyd",
    "mail": "JOldroyd@eurosafeuk.com",
    "givenName": "Jonathan",
    "id": "6c55edc1-2e9f-4af4-8d4f-d5e55590ddda",
    "userType": "Member",
    "jobTitle": "Site Supervisor",
    "department": "Repairs",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Compliance",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "6/4/2021 9:43:32 AM"
  },
  {
    "userPrincipalName": "ALarsen@eurosafeuk.com",
    "displayName": "Alex Larsen",
    "surname": "Larsen",
    "mail": "ALarsen@eurosafeuk.com",
    "givenName": "Alex",
    "id": "6f3593a5-3b88-4e93-8c93-2a6ad6667631",
    "userType": "Member",
    "jobTitle": "Rope Access Technician",
    "department": "Rope Access",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Contracting",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Contracting",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "9/18/2023 7:49:01 AM"
  },
  {
    "userPrincipalName": "MJenkinson@eurosafeuk.com",
    "displayName": "Mark Jenkinson",
    "surname": "Jenkinson",
    "mail": "MJenkinson@eurosafeuk.com",
    "givenName": "Mark",
    "id": "6fe4237a-2969-4b74-9c81-9a23dc5b5617",
    "userType": "Member",
    "jobTitle": "Team Operations Manager",
    "department": "Compliance",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Compliance",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (Ext 239)",
    "mobilePhone": "0793 997 3304",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Compliance",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "6/4/2021 10:30:33 AM"
  },
  {
    "userPrincipalName": "HTopp@eurosafeuk.com",
    "displayName": "Harry Topp",
    "surname": "Topp",
    "mail": "HTopp@eurosafeuk.com",
    "givenName": "Harry",
    "id": "7105d2a0-99b0-4c8d-b79a-a4bb496c9f9b",
    "userType": "Member",
    "jobTitle": "Remedial Estimator",
    "department": "Repairs",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Compliance",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (Ext 425)",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Compliance",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "7/24/2024 12:40:13 PM"
  },
  {
    "userPrincipalName": "atiteam22@eurosafeuk.com",
    "displayName": "atiteam22",
    "surname": "",
    "mail": "atiteam22@eurosafeuk.com",
    "givenName": "",
    "id": "7133150f-2eb9-4ae9-9ad3-60ca2e03efeb",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "3/15/2023 1:39:38 PM"
  },
  {
    "userPrincipalName": "HPercival@eurosafesolutions.co.uk",
    "displayName": "Harvey Percival",
    "surname": "Percival",
    "mail": "HPercival@eurosafesolutions.co.uk",
    "givenName": "Harvey",
    "id": "722bc98c-4a7c-4205-b29b-6f49d43c78b5",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "False",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "6/13/2023 7:21:25 AM"
  },
  {
    "userPrincipalName": "SRichards@eurosafeuk.com",
    "displayName": "Steve Richards",
    "surname": "Richards",
    "mail": "SRichards@eurosafeuk.com",
    "givenName": "Steve",
    "id": "72397ddd-854b-4eae-be09-aa6b3ec7535e",
    "userType": "Member",
    "jobTitle": "Site Supervisor",
    "department": "Installations",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Contracting",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "11/21/2023 9:25:05 AM"
  },
  {
    "userPrincipalName": "EWarner@eurosafeuk.com",
    "displayName": "Elliot Warner",
    "surname": "Warner",
    "mail": "EWarner@eurosafeuk.com",
    "givenName": "Elliot",
    "id": "73207459-79d0-48dd-b7de-7a2c1ee46c44",
    "userType": "Member",
    "jobTitle": "Site Supervisor",
    "department": "Compliance",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Compliance",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Compliance",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "3/17/2022 2:14:51 PM"
  },
  {
    "userPrincipalName": "evaughan@eurosafeuk.com",
    "displayName": "Ethan Vaughan",
    "surname": "Vaughan",
    "mail": "EVaughan@eurosafeuk.com",
    "givenName": "Ethan",
    "id": "74618f8c-1a36-4085-abc0-d2d159882b85",
    "userType": "Member",
    "jobTitle": "Sales Advisor",
    "department": "Compliance",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Compliance",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (EXT. 224)",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Compliance",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "11/11/2022 9:04:37 AM"
  },
  {
    "userPrincipalName": "mci4service@eurosafeuk.com",
    "displayName": "MCi 4. Service",
    "surname": "Service",
    "mail": "mci4service@eurosafeuk.com",
    "givenName": "MCi",
    "id": "75a3172d-7c19-4ed5-a409-3e8009d647a9",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "7/5/2017 9:27:22 AM"
  },
  {
    "userPrincipalName": "ltoseland@eurosafeuk.com",
    "displayName": "Lewis Toseland",
    "surname": "Toseland",
    "mail": "LToseland@eurosafeuk.com",
    "givenName": "Lewis",
    "id": "761b869d-2f90-4be7-91a6-3aaee6df11cd",
    "userType": "Member",
    "jobTitle": "Software Engineer",
    "department": "Process Department",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (Ext 253)",
    "mobilePhone": "07719450486",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "6/30/2022 1:02:10 PM"
  },
  {
    "userPrincipalName": "MHunt@eurosafeuk.com",
    "displayName": "Martin Hunt",
    "surname": "Hunt",
    "mail": "MHunt@eurosafeuk.com",
    "givenName": "Martin",
    "id": "77dd641a-cd4e-40ed-930d-a6c42981ddd1",
    "userType": "Member",
    "jobTitle": "Site Operative",
    "department": "Eurosafe Contracting",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "8/31/2023 9:09:34 AM"
  },
  {
    "userPrincipalName": "MCITest@eurosafeuk.com",
    "displayName": "MCI Test",
    "surname": "Test",
    "mail": "mcitest@eurosafeuk.com",
    "givenName": "MCI",
    "id": "7866d82f-9c1c-4ee2-aa61-8085a9a214e4",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "5/3/2023 10:16:19 AM"
  },
  {
    "userPrincipalName": "RetestSales@eurosafeuk.com",
    "displayName": "Retest Sales",
    "surname": "Sales",
    "mail": "RetestSales@eurosafeuk.com",
    "givenName": "Retest",
    "id": "79aef509-c24f-4c0f-850e-a4e05e6a78bd",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "True",
    "usageLocation": "",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "9/1/2022 8:35:17 AM"
  },
  {
    "userPrincipalName": "LMillin@eurosafeuk.com",
    "displayName": "Lee Millin",
    "surname": "Millin",
    "mail": "LMillin@eurosafeuk.com",
    "givenName": "Lee",
    "id": "7a37e21f-7934-450a-b1bc-14e26909d2f4",
    "userType": "Member",
    "jobTitle": "Site Supervisor",
    "department": "Installations",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Contracting",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "07572288323",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "12/4/2020 11:34:43 AM"
  },
  {
    "userPrincipalName": "ARussell@eurosafeuk.com",
    "displayName": "Alex Russell",
    "surname": "Russell",
    "mail": "ARussell@eurosafeuk.com",
    "givenName": "Alex",
    "id": "7a442db1-2c1b-40d3-84a8-6d4c69829622",
    "userType": "Member",
    "jobTitle": "Compliance Administrator",
    "department": "Compliance",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Compliance",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (Ext 421)",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Compliance",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "6/10/2024 8:51:47 AM"
  },
  {
    "userPrincipalName": "DCapper@eurosafeuk.com",
    "displayName": "Dan Capper",
    "surname": "Capper",
    "mail": "DCapper@eurosafeuk.com",
    "givenName": "Dan",
    "id": "7a8babe7-c670-4503-8e38-ff2e09239568",
    "userType": "Member",
    "jobTitle": "Business Development Director",
    "department": "Sales",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Contracting",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (Ext 301)",
    "mobilePhone": "0797 313 8712",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Contracting",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "1/17/2017 12:44:50 PM"
  },
  {
    "userPrincipalName": "BSilverwood@eurosafeuk.com",
    "displayName": "Bailey Silverwood",
    "surname": "Silverwood",
    "mail": "BSilverwood@eurosafeuk.com",
    "givenName": "Bailey",
    "id": "7b13efa1-3176-43fb-977c-984691787dc0",
    "userType": "Member",
    "jobTitle": "Site Supervisor",
    "department": "Repairs",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Compliance",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "6/4/2021 10:26:36 AM"
  },
  {
    "userPrincipalName": "ALawson@eurosafeuk.com",
    "displayName": "Adam Lawson",
    "surname": "Lawson",
    "mail": "ALawson@eurosafeuk.com",
    "givenName": "Adam",
    "id": "7b68d515-0e01-456a-9809-7bc1393294c7",
    "userType": "Member",
    "jobTitle": "Sales Advisor",
    "department": "Compliance",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Compliance",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (ext 406)",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Compliance",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "2/16/2024 10:09:41 AM"
  },
  {
    "userPrincipalName": "ATIteam6@eurosafeuk.com",
    "displayName": "T&I Team 6",
    "surname": "Team 6",
    "mail": "ATIteam6@eurosafeuk.com",
    "givenName": "T&I",
    "id": "7b9303c4-3bed-4192-acad-c0460b502030",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "4/17/2019 2:06:58 PM"
  },
  {
    "userPrincipalName": "ADaffin@eurosafeuk.com",
    "displayName": "Aaron Daffin",
    "surname": "Daffin",
    "mail": "ADaffin@eurosafeuk.com",
    "givenName": "Aaron",
    "id": "7b9852fe-aa0e-4c77-8225-7b38f75893cb",
    "userType": "Member",
    "jobTitle": "Site Supervisor",
    "department": "Compliance",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Compliance",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "07960052285",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "6/4/2021 10:15:06 AM"
  },
  {
    "userPrincipalName": "renewals@eurosafesolutions.co.uk",
    "displayName": "Renewals",
    "surname": "",
    "mail": "renewals@eurosafeuk.com",
    "givenName": "",
    "id": "7c32b7f5-8083-4a5f-a274-422126419380",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "False",
    "usageLocation": "",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "9/25/2020 2:45:59 PM"
  },
  {
    "userPrincipalName": "JRichards@eurosafeuk.com",
    "displayName": "John Richards",
    "surname": "Richards",
    "mail": "JRichards@eurosafeuk.com",
    "givenName": "John",
    "id": "7c45d46b-163d-4b77-8aa9-ef79fcb58676",
    "userType": "Member",
    "jobTitle": "Designer",
    "department": "Design",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Contracting",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (Ext 291)",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Contracting",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "8/22/2018 3:17:27 PM"
  },
  {
    "userPrincipalName": "ATIteam7@eurosafeuk.com",
    "displayName": "T&I Team 7",
    "surname": "Team 7",
    "mail": "ATIteam7@eurosafeuk.com",
    "givenName": "T&I",
    "id": "7d7c1a20-aea8-41a8-b40e-b6804a822f30",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "4/17/2019 2:07:47 PM"
  },
  {
    "userPrincipalName": "SJohns@eurosafeuk.com",
    "displayName": "Steven Johns",
    "surname": "Johns",
    "mail": "SJohns@eurosafeuk.com",
    "givenName": "Steven",
    "id": "7db582f5-4045-44bd-b185-c2fd6d4e6b6c",
    "userType": "Member",
    "jobTitle": "Business Development Manager",
    "department": "Supply & Training",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Training & PPE",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (Ext 324)",
    "mobilePhone": "0793 171 4629",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Training & PPE",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "10/4/2019 1:36:30 PM"
  },
  {
    "userPrincipalName": "elankovskis@eurosafeuk.com",
    "displayName": "Eduards Lankovskis MCIOB",
    "surname": "Lankovskis",
    "mail": "ELankovskis@eurosafeuk.com",
    "givenName": "Eduards",
    "id": "7deb1e0f-59e7-487b-a205-be4b33133da7",
    "userType": "Member",
    "jobTitle": "Project Manager",
    "department": "Contracts",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Contracting",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411",
    "mobilePhone": "0783 765 8422",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Contracting",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "6/21/2018 1:09:36 PM"
  },
  {
    "userPrincipalName": "IGregg@eurosafeuk.com",
    "displayName": "Ian Gregg",
    "surname": "Gregg",
    "mail": "IGregg@eurosafeuk.com",
    "givenName": "Ian",
    "id": "7f0b43a4-eb95-4f3a-8274-1fc820cac6bb",
    "userType": "Member",
    "jobTitle": "Site Operative",
    "department": "Installations",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Contracting",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "4/5/2023 7:24:50 AM"
  },
  {
    "userPrincipalName": "UMadumere@eurosafeuk.com",
    "displayName": "Uchenna Madumere",
    "surname": "Madumere",
    "mail": "UMadumere@eurosafeuk.com",
    "givenName": "Uchenna",
    "id": "7f272144-52e9-4493-afe8-87147a7041bb",
    "userType": "Member",
    "jobTitle": "Sales Estimator",
    "department": "Sales",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Contracting",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "07415368812",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Contracting",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "1/11/2023 2:20:49 PM"
  },
  {
    "userPrincipalName": "TMetcalfe@eurosafeuk.com",
    "displayName": "Tom Metcalfe",
    "surname": "Metcalfe",
    "mail": "TMetcalfe@eurosafeuk.com",
    "givenName": "Tom",
    "id": "7f4a04f5-2d7c-4236-bb06-b6a9bf55ceec",
    "userType": "Member",
    "jobTitle": "Site Supervisor",
    "department": "Compliance",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Compliance",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Compliance",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "11/10/2021 8:59:55 AM"
  },
  {
    "userPrincipalName": "rtipler@eurosafeuk.com",
    "displayName": "Richard Tipler",
    "surname": "Tipler",
    "mail": "RTipler@eurosafeuk.com",
    "givenName": "Richard",
    "id": "7f6bdae5-1f76-46bd-a8b9-a34b4ffd7f27",
    "userType": "Member",
    "jobTitle": "Projects Manager",
    "department": "Installations",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Contracting",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (Ext 286)",
    "mobilePhone": "0781 575 8422",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Contracting",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "6/21/2018 1:13:51 PM"
  },
  {
    "userPrincipalName": "DCrossley@eurosafeuk.com",
    "displayName": "Damion Crossley",
    "surname": "Crossley",
    "mail": "DCrossley@eurosafeuk.com",
    "givenName": "Damion",
    "id": "7fd0de17-1a24-4b1b-8f6d-55ea99721199",
    "userType": "Member",
    "jobTitle": "Rope Access Technician",
    "department": "Rope Access",
    "accountEnabled": "False",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Contracting",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "5/30/2024 12:14:06 PM"
  },
  {
    "userPrincipalName": "gbradbury@eurosafesolutions.co.uk",
    "displayName": "Greig Bradbury",
    "surname": "Bradbury",
    "mail": "gbradbury@eurosafesolutions.co.uk",
    "givenName": "Greig",
    "id": "805c806e-cab7-45d4-a671-a350c30dbc03",
    "userType": "Member",
    "jobTitle": "Test & Inspection Operative",
    "department": "Test & Inspection",
    "accountEnabled": "False",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "11/11/2022 8:44:51 AM"
  },
  {
    "userPrincipalName": "GRodrigues@eurosafeuk.com",
    "displayName": "Geoffrey Rodrigues",
    "surname": "Rodrigues",
    "mail": "GRodrigues@eurosafeuk.com",
    "givenName": "Geoffrey",
    "id": "80674548-699a-4517-b0a9-1fdcce325be3",
    "userType": "Member",
    "jobTitle": "Business Development Manager",
    "department": "Training",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Training & PPE",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (Ext 431)",
    "mobilePhone": 7398518754,
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Training & PPE",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "9/24/2024 2:24:13 PM"
  },
  {
    "userPrincipalName": "ATIteam15@eurosafeuk.com",
    "displayName": "T&I Team 15",
    "surname": "Team 15",
    "mail": "ATIteam15@eurosafeuk.com",
    "givenName": "T&I",
    "id": "80a97229-ea98-40c1-9a14-e190f0ceea1b",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "4/18/2019 3:47:38 PM"
  },
  {
    "userPrincipalName": "OCurtis@eurosafeuk.com",
    "displayName": "Owen Curtis",
    "surname": "Curtis",
    "mail": "OCurtis@eurosafeuk.com",
    "givenName": "Owen",
    "id": "80fb7dd7-2675-4635-b645-220438cdccfd",
    "userType": "Member",
    "jobTitle": "Site Operative",
    "department": "Retest",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "5/16/2024 8:01:08 AM"
  },
  {
    "userPrincipalName": "LBex@eurosafesolutions.co.uk",
    "displayName": "Lewis Bex",
    "surname": "Bex",
    "mail": "LBex@eurosafesolutions.co.uk",
    "givenName": "Lewis",
    "id": "8175d06a-c842-4d0b-b5ef-e336ffea78ec",
    "userType": "Member",
    "jobTitle": "Contracts Co-Ordinator",
    "department": "Test & Inspection Operations",
    "accountEnabled": "False",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "United Kingdom",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (Ext 334)",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "False",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "9/12/2023 8:27:11 AM"
  },
  {
    "userPrincipalName": "BAvery@eurosafeuk.com",
    "displayName": "Ben Avery",
    "surname": "Avery",
    "mail": "BAvery@eurosafeuk.com",
    "givenName": "Ben",
    "id": "81dca8f4-fd03-4eb7-9a35-42f6312f9d08",
    "userType": "Member",
    "jobTitle": "Site Operative",
    "department": "Compliance",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Compliance",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Compliance",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "12/20/2023 12:45:39 PM"
  },
  {
    "userPrincipalName": "dhenry@eurosafesolutions.co.uk",
    "displayName": "Doyle Henry",
    "surname": "Henry",
    "mail": "dhenry@eurosafesolutions.co.uk",
    "givenName": "Doyle",
    "id": "82953004-e484-457a-974e-221d32d6c6ee",
    "userType": "Member",
    "jobTitle": "Site Operative",
    "department": "Contracts",
    "accountEnabled": "False",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "11/25/2022 10:54:49 AM"
  },
  {
    "userPrincipalName": "SalesUK@eurosafesolutions.co.uk",
    "displayName": "SalesUK",
    "surname": "",
    "mail": "salesuk@eurosafeuk.com",
    "givenName": "",
    "id": "82f72c0e-6375-4367-9156-71a2b4f6b969",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "False",
    "usageLocation": "",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "6/28/2018 1:53:59 PM"
  },
  {
    "userPrincipalName": "DKedwell@eurosafeuk.com",
    "displayName": "David Kedwell",
    "surname": "Kedwell",
    "mail": "DKedwell@eurosafeuk.com",
    "givenName": "David",
    "id": "835fd583-0971-4ccd-a2e8-841416b331d3",
    "userType": "Member",
    "jobTitle": "Site Supervisor",
    "department": "Installations",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Contracting",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Contracting",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "1/11/2022 3:33:43 PM"
  },
  {
    "userPrincipalName": "ATITeam27@eurosafeuk.com",
    "displayName": "ATITeam27",
    "surname": "Team27",
    "mail": "ATITeam27@eurosafeuk.com",
    "givenName": "ATI",
    "id": "84357030-677c-4158-8c8b-48df6c3e5e31",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "4/9/2024 9:04:47 AM"
  },
  {
    "userPrincipalName": "finance@eurosafeuk.com",
    "displayName": "Finance",
    "surname": "",
    "mail": "finance@eurosafeuk.com",
    "givenName": "",
    "id": "84fc1b6b-1f4c-4bae-807e-35be8588c1b7",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "False",
    "usageLocation": "",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "6/21/2018 10:42:56 AM"
  },
  {
    "userPrincipalName": "KOxenford@eurosafeuk.com",
    "displayName": "Ken Oxenford",
    "surname": "Oxenford",
    "mail": "KOxenford@eurosafeuk.com",
    "givenName": "Ken",
    "id": "8617967d-aeb6-415c-9c4d-0a8171234845",
    "userType": "Member",
    "jobTitle": "Project Manager",
    "department": "Contracts",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Contracting",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (Ext 427)",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Contracting",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "9/16/2024 7:50:25 AM"
  },
  {
    "userPrincipalName": "ATIteam3@eurosafeuk.com",
    "displayName": "T&I Team 3",
    "surname": "Team 3",
    "mail": "ATIteam3@eurosafeuk.com",
    "givenName": "T&I",
    "id": "87306826-3e39-4e86-a721-060598b20014",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "4/18/2019 3:07:43 PM"
  },
  {
    "userPrincipalName": "SCartwright@eurosafeuk.com",
    "displayName": "Steve Cartwright",
    "surname": "Cartwright",
    "mail": "SCartwright@eurosafeuk.com",
    "givenName": "Steve",
    "id": "88437956-a026-4bba-9db1-5965069f2e57",
    "userType": "Member",
    "jobTitle": "Site Supervisor",
    "department": "Repairs",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Compliance",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "3/17/2022 2:20:11 PM"
  },
  {
    "userPrincipalName": "Photos@eurosafesolutions.co.uk",
    "displayName": "Photos",
    "surname": "",
    "mail": "photos@eurosafeuk.com",
    "givenName": "",
    "id": "88bcd15a-9e5c-4770-a74e-41510c4e8788",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "False",
    "usageLocation": "",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "1/24/2020 7:46:48 AM"
  },
  {
    "userPrincipalName": "JBloomfield@eurosafeuk.com",
    "displayName": "Jamie Bloomfield",
    "surname": "Bloomfield",
    "mail": "JBloomfield@eurosafeuk.com",
    "givenName": "Jamie",
    "id": "89de6f39-4f48-4598-9281-dd739380ff5c",
    "userType": "Member",
    "jobTitle": "Pre-Contracts Designer",
    "department": "Sales",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Contracting",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Contracting",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "6/23/2023 2:59:22 PM"
  },
  {
    "userPrincipalName": "OHoy@eurosafeuk.com",
    "displayName": "Owen Hoy",
    "surname": "Hoy",
    "mail": "OHoy@eurosafeuk.com",
    "givenName": "Owen",
    "id": "8a3d91e2-44cd-4d13-9538-12f5ebaff50d",
    "userType": "Member",
    "jobTitle": "Designer",
    "department": "Design",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Contracting",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (Ext 331)",
    "mobilePhone": "07810296044",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Contracting",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "8/25/2023 8:25:07 AM"
  },
  {
    "userPrincipalName": "Aeearle@eurosafeukltd.onmicrosoft.com",
    "displayName": "Andy Earle",
    "surname": "Earle",
    "mail": "",
    "givenName": "Andy",
    "id": "8c7ea6a6-95a6-46ef-be07-0538e6eef66d",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "False",
    "usageLocation": "",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "8/24/2022 1:56:06 PM"
  },
  {
    "userPrincipalName": "cmccabe2@eurosafesolutions.co.uk",
    "displayName": "Callie Admin",
    "surname": "Admin",
    "mail": "",
    "givenName": "Callie",
    "id": "8c98d92e-4adb-44e5-ad1f-c7494b928ccd",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "True",
    "usageLocation": "",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "11/7/2022 10:58:01 AM"
  },
  {
    "userPrincipalName": "JNuttall@eurosafeuk.com",
    "displayName": "Josh Nuttall",
    "surname": "Nuttall",
    "mail": "JNuttall@eurosafeuk.com",
    "givenName": "Josh",
    "id": "8ca80839-dade-4580-9c84-744859f0429e",
    "userType": "Member",
    "jobTitle": "Site Supervisor",
    "department": "Compliance",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Compliance",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "6/4/2021 10:23:09 AM"
  },
  {
    "userPrincipalName": "ptest@eurosafeukltd.onmicrosoft.com",
    "displayName": "Print Test",
    "surname": "Test",
    "mail": "",
    "givenName": "Print",
    "id": "8cbb82e7-f69f-45c2-a8ac-f070b63c635c",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "True",
    "usageLocation": "",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "8/24/2022 1:56:06 PM"
  },
  {
    "userPrincipalName": "IElizabeth@eurosafeuk.com",
    "displayName": "Indi Elizabeth",
    "surname": "Elizabeth",
    "mail": "IElizabeth@eurosafeuk.com",
    "givenName": "Indi",
    "id": "8d4524a7-b4ee-4bb7-859d-72a70ed29b71",
    "userType": "Member",
    "jobTitle": "Purchasing & Environmental Co-Ordinator",
    "department": "Warehousing & Purchasing",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (Ext 260)",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "11/10/2021 11:47:14 AM"
  },
  {
    "userPrincipalName": "Lightning@eurosafeuk.com",
    "displayName": "Lightning",
    "surname": "",
    "mail": "Lightning@eurosafeuk.com",
    "givenName": "Lightning",
    "id": "8ec238e5-9329-4721-9f51-8f4267956594",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "2/22/2023 3:52:38 PM"
  },
  {
    "userPrincipalName": "facade-access@eurosafeuk.com",
    "displayName": "Facade Access",
    "surname": "Access",
    "mail": "facade-access@eurosafeuk.com",
    "givenName": "Facade",
    "id": "8edca0b6-4862-4743-b2fe-6c45a4cbb26c",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "8/7/2024 7:14:47 AM"
  },
  {
    "userPrincipalName": "KHarlowe@eurosafeuk.com",
    "displayName": "Keith Harlowe",
    "surname": "Harlowe",
    "mail": "KHarlowe@eurosafeuk.com",
    "givenName": "Keith",
    "id": "8f4d9263-bc30-4f4b-8a14-533200c01cfa",
    "userType": "Member",
    "jobTitle": "T&I Supervisor",
    "department": "Test & Inspection",
    "accountEnabled": "False",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "11/11/2022 8:59:41 AM"
  },
  {
    "userPrincipalName": "sharepointadmin@eurosafeuk.com",
    "displayName": "Sharepoint Admin",
    "surname": "Admin",
    "mail": "sharepointadmin@eurosafeuk.com",
    "givenName": "Sharepoint",
    "id": "906b4a38-1f49-4a2a-99a0-b3db8d364e4e",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "11/23/2020 8:58:08 PM"
  },
  {
    "userPrincipalName": "RKynoch@eurosafeuk.com",
    "displayName": "Robert Kynoch",
    "surname": "Kynoch",
    "mail": "RKynoch@eurosafeuk.com",
    "givenName": "Robert",
    "id": "920cf27a-1e2e-41d4-98c4-ae5886e0c386",
    "userType": "Member",
    "jobTitle": "Trainer",
    "department": "Training",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Training & PPE",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "07951 974585",
    "mobilePhone": "07951 974585",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Training & PPE",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "1/22/2024 9:19:32 AM"
  },
  {
    "userPrincipalName": "Quality@eurosafesolutions.co.uk",
    "displayName": "Quality",
    "surname": "",
    "mail": "quality@eurosafeuk.com",
    "givenName": "",
    "id": "94337236-c03c-4f89-b3fa-f19a5a2e376b",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "False",
    "usageLocation": "",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "3/13/2020 2:54:31 PM"
  },
  {
    "userPrincipalName": "W.Bogaerts@eurosafeukltd.onmicrosoft.com",
    "displayName": "Willem Bogaerts",
    "surname": "Bogaerts",
    "mail": "",
    "givenName": "Willem",
    "id": "953c7142-8069-47a1-824c-404d94ff27c2",
    "userType": "Member",
    "jobTitle": "",
    "department": "Installations (816)",
    "accountEnabled": "True",
    "usageLocation": "",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "6/11/2024 8:52:23 AM"
  },
  {
    "userPrincipalName": "DWright@eurosafesolutions.co.uk",
    "displayName": "Daniel Wright",
    "surname": "Wright",
    "mail": "DWright@eurosafesolutions.co.uk",
    "givenName": "Daniel",
    "id": "95c01ab6-36a5-44ed-9c79-a69d6f85e11c",
    "userType": "Member",
    "jobTitle": "T&I Contracts Co-Ordinator",
    "department": "Test & Inspection",
    "accountEnabled": "False",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "5/14/2019 2:38:08 PM"
  },
  {
    "userPrincipalName": "GChipchase@eurosafesolutions.co.uk",
    "displayName": "George Chipchase",
    "surname": "Chipchase",
    "mail": "GChipchase@eurosafesolutions.co.uk",
    "givenName": "George",
    "id": "97054ca7-e0eb-43b8-8c37-8c7fb30f4706",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "False",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "9/25/2023 7:27:25 AM"
  },
  {
    "userPrincipalName": "AFrench@eurosafeuk.com",
    "displayName": "Arron French",
    "surname": "French",
    "mail": "AFrench@eurosafeuk.com",
    "givenName": "Arron",
    "id": "9806cb02-63d8-4907-a8f2-95c35f5073dc",
    "userType": "Member",
    "jobTitle": "Site Supervisor",
    "department": "Compliance",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Compliance",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "5/21/2024 8:19:33 AM"
  },
  {
    "userPrincipalName": "ATITeam21@eurosafeuk.com",
    "displayName": "ATI Team 21",
    "surname": "Team 21",
    "mail": "ATITeam21@eurosafeuk.com",
    "givenName": "ATI",
    "id": "98981607-4919-4294-a151-3f3d48821073",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "8/31/2022 9:22:44 AM"
  },
  {
    "userPrincipalName": "CControl@eurosafeuk.com",
    "displayName": "Credit Control",
    "surname": "Control",
    "mail": "CControl@eurosafeuk.com",
    "givenName": "Credit",
    "id": "98c8be3f-5c59-4459-8f5c-4d89dc01ea96",
    "userType": "Member",
    "jobTitle": "Credit Control",
    "department": "Finance",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (Ext 269)",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "5/5/2022 10:04:34 AM"
  },
  {
    "userPrincipalName": "AJoyner@eurosafesolutions.co.uk",
    "displayName": "Arran Joyner",
    "surname": "Joyner",
    "mail": "AJoyner@eurosafesolutions.co.uk",
    "givenName": "Arran",
    "id": "98da5fa7-7e48-4f0f-91ae-587421dc8b2c",
    "userType": "Member",
    "jobTitle": "Site Operative",
    "department": "Test & Inspection",
    "accountEnabled": "False",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "4/21/2022 11:03:27 AM"
  },
  {
    "userPrincipalName": "ATIteam10@eurosafeuk.com",
    "displayName": "T&I Team 10",
    "surname": "Team 10",
    "mail": "ATIteam10@eurosafeuk.com",
    "givenName": "T&I",
    "id": "9a0e5385-ec3d-48dd-a28b-f57ec5f36b49",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "4/18/2019 3:23:47 PM"
  },
  {
    "userPrincipalName": "MainMeetingRoom@eurosafesolutions.co.uk",
    "displayName": "Main Meeting Room",
    "surname": "",
    "mail": "MainMeetingRoom@eurosafesolutions.co.uk",
    "givenName": "",
    "id": "9a1dd5ce-5e01-4eaf-9827-bbc76e2cc98b",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "True",
    "usageLocation": "",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "9/21/2020 7:24:46 AM"
  },
  {
    "userPrincipalName": "BMallett@eurosafeuk.com",
    "displayName": "Ben Mallett",
    "surname": "Mallett",
    "mail": "BMallett@eurosafeuk.com",
    "givenName": "Ben",
    "id": "9a5a2200-baab-4afd-9ccc-3507cdae153d",
    "userType": "Member",
    "jobTitle": "Process & Development Co-Ordinator",
    "department": "Process Department",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (Ext 250)",
    "mobilePhone": "07983946602",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "11/6/2019 10:44:33 AM"
  },
  {
    "userPrincipalName": "KWebb@eurosafeuk.com",
    "displayName": "Kyle Webb",
    "surname": "Webb",
    "mail": "KWebb@eurosafeuk.com",
    "givenName": "Kyle",
    "id": "9aa5e37f-7b32-496b-8d6f-52bdea8945cd",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "4/5/2023 7:48:45 AM"
  },
  {
    "userPrincipalName": "lwoodall@eurosafeuk.com",
    "displayName": "Leah Woodall",
    "surname": "Woodall",
    "mail": "LWoodall@eurosafeuk.com",
    "givenName": "Leah",
    "id": "9add6d18-2791-4fb4-bfdd-82d2b7f285e6",
    "userType": "Member",
    "jobTitle": "Credit Controller",
    "department": "Finance",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "01142507411 (ext 416)",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "5/2/2024 2:01:11 PM"
  },
  {
    "userPrincipalName": "Djackson@eurosafeuk.com",
    "displayName": "Dan Jackson",
    "surname": "Jackson",
    "mail": "DJackson@eurosafeuk.com",
    "givenName": "Dan",
    "id": "9b8f55d7-bb16-4386-9735-5273ef2fe8c8",
    "userType": "Member",
    "jobTitle": "Pre-Contracts Designer",
    "department": "Sales",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Contracting",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (Ext 302)",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Contracting",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "1/17/2017 12:44:51 PM"
  },
  {
    "userPrincipalName": "LMitchell@eurosafeuk.com",
    "displayName": "Luke Mitchell",
    "surname": "Mitchell",
    "mail": "LMitchell@eurosafeuk.com",
    "givenName": "Luke",
    "id": "9c821d98-4a0a-48aa-9ca2-e4eab012e711",
    "userType": "Member",
    "jobTitle": "Site Supervisor",
    "department": "Compliance",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Compliance",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "07535077601",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "6/4/2021 10:19:20 AM"
  },
  {
    "userPrincipalName": "INorris@eurosafeuk.com",
    "displayName": "Ilona Norris",
    "surname": "Norris",
    "mail": "INorris@eurosafeuk.com",
    "givenName": "Ilona",
    "id": "9d352bb7-00b5-4163-b86f-7373e6535cf0",
    "userType": "Member",
    "jobTitle": "Marketing Manager",
    "department": "Process Department",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (Ext 252)",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "7/15/2022 8:40:10 AM"
  },
  {
    "userPrincipalName": "AWiddowson@eurosafeuk.com",
    "displayName": "Alyssa Widdowson",
    "surname": "Widdowson",
    "mail": "AWiddowson@eurosafeuk.com",
    "givenName": "Alyssa",
    "id": "9d7d23a8-e938-43a5-a11a-3f797b5ac002",
    "userType": "Member",
    "jobTitle": "Retentions Account Manager",
    "department": "Test & Inspection - Sales",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (Ext 210)",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "6/30/2022 9:51:32 AM"
  },
  {
    "userPrincipalName": "LewisAdmin@eurosafeukltd.onmicrosoft.com",
    "displayName": "Lewis Admin",
    "surname": "Admin",
    "mail": "",
    "givenName": "Lewis",
    "id": "9f618529-57ca-417b-939e-197d4400a3da",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "True",
    "usageLocation": "",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "8/24/2022 1:56:09 PM"
  },
  {
    "userPrincipalName": "RGilbert@eurosafeuk.com",
    "displayName": "Ryan Gilbert",
    "surname": "Gilbert",
    "mail": "RGilbert@eurosafeuk.com",
    "givenName": "Ryan",
    "id": "a025dc7e-ad65-4848-9d17-7929c5bf690b",
    "userType": "Member",
    "jobTitle": "Site Supervisor",
    "department": "Compliance",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Compliance",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "10/30/2023 11:47:27 AM"
  },
  {
    "userPrincipalName": "kvangeene@eurosafeuk.com",
    "displayName": "Kieren Van Geene",
    "surname": "Van Geene",
    "mail": "kvangeene@eurosafeuk.com",
    "givenName": "Kieren",
    "id": "a19e1ad6-bf51-4c1a-883f-575b1e0887f3",
    "userType": "Member",
    "jobTitle": "Health & Safety Manager",
    "department": "Installations",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Contracting",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (Ext 411)",
    "mobilePhone": "07508266977",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Contracting",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "3/27/2024 10:14:19 AM"
  },
  {
    "userPrincipalName": "HKilbypollard@eurosafeuk.com",
    "displayName": "Harry Kilby-Pollard",
    "surname": "Kilby-Pollard",
    "mail": "HKilbypollard@eurosafeuk.com",
    "givenName": "Harry",
    "id": "a210a823-f222-4400-a826-99b74b229668",
    "userType": "Member",
    "jobTitle": "Renewals Account Manager",
    "department": "",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "01142507411 (ext 414)",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "4/17/2024 10:23:29 AM"
  },
  {
    "userPrincipalName": "KReynolds@eurosafesolutions.co.uk",
    "displayName": "Kieron Reynolds",
    "surname": "Reynolds",
    "mail": "KReynolds@eurosafesolutions.co.uk",
    "givenName": "Kieron",
    "id": "a24aa3ea-db46-4df5-840b-3fbe87965b56",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "4/17/2019 2:00:47 PM"
  },
  {
    "userPrincipalName": "BFotheringham@eurosafeuk.com",
    "displayName": "Barry Fotheringham",
    "surname": "Fotheringham",
    "mail": "BFotheringham@eurosafeuk.com",
    "givenName": "Barry",
    "id": "a2cb1c16-bfb7-41d8-8896-d4755b363cba",
    "userType": "Member",
    "jobTitle": "Site Supervisor",
    "department": "Lightning Protection",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Compliance",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "6/4/2021 9:52:07 AM"
  },
  {
    "userPrincipalName": "TomHumphreys@eurosafeuk.com",
    "displayName": "Tom Humphreys",
    "surname": "Humphreys",
    "mail": "TomHumphreys@eurosafeuk.com",
    "givenName": "Tom",
    "id": "a362d85f-a893-4ab7-9e04-3d9a50731064",
    "userType": "Member",
    "jobTitle": "Site Operative",
    "department": "Compliance",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Compliance",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "7/25/2023 8:13:13 AM"
  },
  {
    "userPrincipalName": "ATIteam2@eurosafeuk.com",
    "displayName": "T&I Team 2",
    "surname": "Team 2",
    "mail": "ATIteam2@eurosafeuk.com",
    "givenName": "T&I",
    "id": "a3d5b2e3-e909-496f-b3fe-01c3bf12b281",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "4/18/2019 3:06:11 PM"
  },
  {
    "userPrincipalName": "AMilborrow@eurosafeuk.com",
    "displayName": "Aaron Milborrow",
    "surname": "Milborrow",
    "mail": "AMilborrow@eurosafeuk.com",
    "givenName": "Aaron",
    "id": "a50bcbf8-dc21-47f5-8221-8c55ea1367fd",
    "userType": "Member",
    "jobTitle": "Site Supervisor",
    "department": "Compliance",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Compliance",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "5/17/2023 1:55:08 PM"
  },
  {
    "userPrincipalName": "mfinnie@eurosafeuk.com",
    "displayName": "Mike Finnie",
    "surname": "Finnie",
    "mail": "mfinnie@eurosafeuk.com",
    "givenName": "Mike",
    "id": "a7db8eec-c636-4fea-89f9-132e5854c8b5",
    "userType": "Member",
    "jobTitle": "Site Supervisor",
    "department": "Compliance",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "Unit 51B, Orgreave Drive",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Compliance",
    "city": "Sheffield",
    "postalCode": "S13 9NR",
    "telephoneNumber": "0114 2507411",
    "mobilePhone": "07508266980",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "6/21/2018 1:11:11 PM"
  },
  {
    "userPrincipalName": "CReid@eurosafeuk.com",
    "displayName": "Carole Reid",
    "surname": "Reid",
    "mail": "CReid@eurosafeuk.com",
    "givenName": "Carole",
    "id": "a8f5be17-14f7-4c25-b08c-18bd91674121",
    "userType": "Member",
    "jobTitle": "Training Sales Advisor",
    "department": "Training",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Training & PPE",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (Ext 426)",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Training & PPE",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "8/6/2024 10:16:57 AM"
  },
  {
    "userPrincipalName": "liampowell@eurosafeuk.com",
    "displayName": "Liam Powell",
    "surname": "Powell",
    "mail": "LiamPowell@eurosafeuk.com",
    "givenName": "Liam",
    "id": "aa07fc31-a4a1-42be-8aff-bbde5b831723",
    "userType": "Member",
    "jobTitle": "Site Manager",
    "department": "Installations",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Contracting",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411",
    "mobilePhone": "07875 315 807",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Contracting",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "6/21/2018 1:10:33 PM"
  },
  {
    "userPrincipalName": "ATIteam5@eurosafeuk.com",
    "displayName": "T&I Team 5",
    "surname": "Team 5",
    "mail": "ATIteam5@eurosafeuk.com",
    "givenName": "T&I",
    "id": "aa341364-740e-4804-8813-0073e9f0587e",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "4/18/2019 3:10:41 PM"
  },
  {
    "userPrincipalName": "SBerry@eurosafeuk.com",
    "displayName": "Simone Berry",
    "surname": "Berry",
    "mail": "SBerry@eurosafeuk.com",
    "givenName": "Simone",
    "id": "aa3fb76e-b5a2-48ec-b8e4-b096ccd5e1c2",
    "userType": "Member",
    "jobTitle": "Sales Administrator",
    "department": "Sales",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Contracting",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (Ext 311)",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Contracting",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "7/13/2018 11:12:08 AM"
  },
  {
    "userPrincipalName": "MGregg@eurosafeuk.com",
    "displayName": "Mark Gregg",
    "surname": "Gregg",
    "mail": "MGregg@eurosafeuk.com",
    "givenName": "Mark",
    "id": "ab5d8876-ff62-4631-b582-307441997cf8",
    "userType": "Member",
    "jobTitle": "Site Supervisor",
    "department": "Installations",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Contracting",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "07852648984",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "11/29/2019 9:47:31 AM"
  },
  {
    "userPrincipalName": "GBaker@eurosafesolutions.co.uk",
    "displayName": "Graham Baker",
    "surname": "Baker",
    "mail": "GBaker@eurosafesolutions.co.uk",
    "givenName": "Graham",
    "id": "abdb8230-8324-4333-83c5-8a8905a911ef",
    "userType": "Member",
    "jobTitle": "Designer",
    "department": "Design",
    "accountEnabled": "False",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "01142507411 (EXT 293)",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "9/2/2022 8:47:49 AM"
  },
  {
    "userPrincipalName": "NTaylor@eurosafeuk.com",
    "displayName": "Nick Taylor",
    "surname": "Taylor",
    "mail": "NTaylor@eurosafeuk.com",
    "givenName": "Nick",
    "id": "ac50e03e-c554-4961-b963-60ee9a5c50b4",
    "userType": "Member",
    "jobTitle": "Site Supervisor",
    "department": "Repairs",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Compliance",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "3/8/2019 9:27:03 AM"
  },
  {
    "userPrincipalName": "MTame@eurosafeuk.com",
    "displayName": "Marc Tame",
    "surname": "Tame",
    "mail": "MTame@eurosafeuk.com",
    "givenName": "Marc",
    "id": "ac6d6c70-0515-4a23-bd29-062b6f99c1da",
    "userType": "Member",
    "jobTitle": "Site Operative",
    "department": "Compliance",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Compliance",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Compliance",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "3/1/2024 8:31:59 AM"
  },
  {
    "userPrincipalName": "admin@eurosafeukltd.onmicrosoft.com",
    "displayName": "admin",
    "surname": "",
    "mail": "",
    "givenName": "admin",
    "id": "ac8fba0c-c6ec-4cb6-b259-70a752ae1db5",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "4/25/2017 9:05:51 AM"
  },
  {
    "userPrincipalName": "PRoper@eurosafeuk.com",
    "displayName": "Paul Roper",
    "surname": "Roper",
    "mail": "PRoper@eurosafeuk.com",
    "givenName": "Paul",
    "id": "aca82be8-8fb8-4ab4-9039-f180bab6c108",
    "userType": "Member",
    "jobTitle": "Asset and Fleet Manager",
    "department": "Warehouse & Fleet",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (Ext 263)",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "2/18/2019 12:12:29 PM"
  },
  {
    "userPrincipalName": "RMerrick@eurosafeuk.com",
    "displayName": "Richard Merrick",
    "surname": "Merrick",
    "mail": "RMerrick@eurosafeuk.com",
    "givenName": "Richard",
    "id": "acac4dd1-bc09-4409-8cbc-5e2088f3fef5",
    "userType": "Member",
    "jobTitle": "Junior Designer",
    "department": "Design",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Contracting",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (EXT 338)",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Contracting",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "9/18/2023 7:52:41 AM"
  },
  {
    "userPrincipalName": "Lgrayson@eurosafeuk.com",
    "displayName": "Lauren Grayson",
    "surname": "Grayson",
    "mail": "LGrayson@eurosafeuk.com",
    "givenName": "Lauren",
    "id": "acd7a957-2291-4308-bcc7-16aae3688fbc",
    "userType": "Member",
    "jobTitle": "Remedial Operations Manager",
    "department": "Repairs",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Compliance",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (Ext 237)",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Compliance",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "7/6/2017 1:19:09 PM"
  },
  {
    "userPrincipalName": "dsherwood@eurosafeuk.com",
    "displayName": "Dan Sherwood",
    "surname": "Sherwood",
    "mail": "DSherwood@eurosafeuk.com",
    "givenName": "Dan",
    "id": "ad6bc53c-4717-4280-bf25-6ca105ab6d21",
    "userType": "Member",
    "jobTitle": "Rope Access Contracts Manager",
    "department": "Rope Access",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Contracting",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (Ext 303)",
    "mobilePhone": "0753 507 7602",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Contracting",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "6/21/2018 1:05:32 PM"
  },
  {
    "userPrincipalName": "ATITeam23@eurosafeuk.com",
    "displayName": "ATI Team 23",
    "surname": 23,
    "mail": "ATITeam23@eurosafeuk.com",
    "givenName": "ATI",
    "id": "adafe3cf-3a85-4216-b04c-9390ab854e7f",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "6/2/2023 3:21:08 PM"
  },
  {
    "userPrincipalName": "DPhillips@eurosafeuk.com",
    "displayName": "Daniel Phillips",
    "surname": "Phillips",
    "mail": "DPhillips@eurosafeuk.com",
    "givenName": "Daniel",
    "id": "adf220d8-aa8b-4e50-9d52-065a52431946",
    "userType": "Member",
    "jobTitle": "Site Supervisor",
    "department": "Compliance",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Compliance",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "6/4/2021 10:44:15 AM"
  },
  {
    "userPrincipalName": "scanner@eurosafeukltd.onmicrosoft.com",
    "displayName": "Eurosafe Scanner",
    "surname": "Scanner",
    "mail": "",
    "givenName": "Eurosafe",
    "id": "aef1a3b5-420a-439e-93b6-11153675868c",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "True",
    "usageLocation": "",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "8/24/2022 1:56:06 PM"
  },
  {
    "userPrincipalName": "IBrown@eurosafeuk.com",
    "displayName": "Iain Brown",
    "surname": "Brown",
    "mail": "IBrown@eurosafeuk.com",
    "givenName": "Iain",
    "id": "aef677be-f5b3-4c3a-9050-05bb8709b48c",
    "userType": "Member",
    "jobTitle": "Data Analyst",
    "department": "Process Department",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (EXT 336)",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "9/18/2023 9:14:26 AM"
  },
  {
    "userPrincipalName": "apearce@eurosafeuk.com",
    "displayName": "Antony Pearce",
    "surname": "Pearce",
    "mail": "APearce@eurosafeuk.com",
    "givenName": "Antony",
    "id": "af261261-4cf8-44af-8668-ec97b5e5cb89",
    "userType": "Member",
    "jobTitle": "Site Manager",
    "department": "Installations",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Contracting",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411",
    "mobilePhone": "07599528081",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Contracting",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "4/30/2021 12:06:48 PM"
  },
  {
    "userPrincipalName": "ISampson@eurosafeuk.com",
    "displayName": "Izaak Sampson",
    "surname": "Sampson",
    "mail": "ISampson@eurosafeuk.com",
    "givenName": "Izaak",
    "id": "b17e75e1-ca60-4272-91aa-f2f6c2f320f1",
    "userType": "Member",
    "jobTitle": "Marketing Assistant",
    "department": "",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (Ext 413)",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "6/12/2023 8:50:53 AM"
  },
  {
    "userPrincipalName": "JParkin@eurosafesolutions.co.uk",
    "displayName": "Joe Parkin",
    "surname": "Parkin",
    "mail": "JParkin@eurosafesolutions.co.uk",
    "givenName": "Joe",
    "id": "b2033538-a33d-48a4-939c-5dd2b0f736e5",
    "userType": "Member",
    "jobTitle": "Site Operative",
    "department": "Test & Inspection",
    "accountEnabled": "False",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "4/21/2022 11:05:02 AM"
  },
  {
    "userPrincipalName": "CRenzi@eurosafeuk.com",
    "displayName": "Charlie Renzi",
    "surname": "Renzi",
    "mail": "CRenzi@eurosafeuk.com",
    "givenName": "Charlie",
    "id": "b21c7c1f-8079-4e45-82f7-853d1edeed55",
    "userType": "Member",
    "jobTitle": "Site Operative",
    "department": "Compliance",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Compliance",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Compliance",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "11/17/2021 11:14:31 AM"
  },
  {
    "userPrincipalName": "Dsmith@eurosafeuk.com",
    "displayName": "David Smith",
    "surname": "Smith",
    "mail": "DSmith@eurosafeuk.com",
    "givenName": "David",
    "id": "b28de41a-9870-4f23-8611-599a9ba51238",
    "userType": "Member",
    "jobTitle": "Design Manager",
    "department": "Design",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Contracting",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (Ext 290)",
    "mobilePhone": "0757 228 8301",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Contracting",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "1/17/2017 12:44:53 PM"
  },
  {
    "userPrincipalName": "fMcAllisterGraham@eurosafeuk.com",
    "displayName": "Felix McAllister-Graham",
    "surname": "McAllister-Graham",
    "mail": "fMcAllisterGraham@eurosafeuk.com",
    "givenName": "Felix",
    "id": "b52a5e45-4ab7-4674-b7b4-1fa083887520",
    "userType": "Member",
    "jobTitle": "Site Operative",
    "department": "Installations",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Contracting",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "11/11/2022 9:16:03 AM"
  },
  {
    "userPrincipalName": "blane@eurosafeuk.com",
    "displayName": "Bailey Lane",
    "surname": "Lane",
    "mail": "blane@eurosafeuk.com",
    "givenName": "Bailey",
    "id": "b58cec8e-5dbe-45da-920b-1de8a7a3ef69",
    "userType": "Member",
    "jobTitle": "Site Supervisor",
    "department": "Lightning Protection",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Compliance",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "8/17/2021 8:37:46 AM"
  },
  {
    "userPrincipalName": "CBerke@eurosafeuk.com",
    "displayName": "Callum Berke",
    "surname": "Berke",
    "mail": "CBerke@eurosafeuk.com",
    "givenName": "Callum",
    "id": "b842372a-7d28-4e69-ad77-4ccc021ca0bc",
    "userType": "Member",
    "jobTitle": "Site Operative",
    "department": "Test & Inspection",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "3/17/2022 8:00:01 AM"
  },
  {
    "userPrincipalName": "PHope@eurosafeuk.com",
    "displayName": "Paul Hope",
    "surname": "Hope",
    "mail": "PHope@eurosafeuk.com",
    "givenName": "Paul",
    "id": "b87f87e4-bebb-4eff-904d-4830cb67cb08",
    "userType": "Member",
    "jobTitle": "Site Supervisor",
    "department": "Installations",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Contracting",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Contracting",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "1/11/2022 3:20:51 PM"
  },
  {
    "userPrincipalName": "DFickling@eurosafeuk.com",
    "displayName": "Darren Fickling",
    "surname": "Fickling",
    "mail": "DFickling@eurosafeuk.com",
    "givenName": "Darren",
    "id": "b9bd741d-8151-494a-b9cd-3ea7b72b0040",
    "userType": "Member",
    "jobTitle": "Business Development Manager",
    "department": "Supply & Training",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Training & PPE",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (Ext 321)",
    "mobilePhone": "0737 647 4331",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Training & PPE",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "10/15/2020 10:15:35 AM"
  },
  {
    "userPrincipalName": "mmccarthy@eurosafeuk.com",
    "displayName": "Mick McCarthy",
    "surname": "McCarthy",
    "mail": "mmccarthy@eurosafeuk.com",
    "givenName": "Mick",
    "id": "b9c9224f-a8b1-46e0-b5a8-7f3142f7ecfd",
    "userType": "Member",
    "jobTitle": "Site Supervisor",
    "department": "Repairs",
    "accountEnabled": "False",
    "usageLocation": "GB",
    "streetAddress": "Unit 51B, Orgreave Drive",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "Sheffield",
    "postalCode": "S13 9NR",
    "telephoneNumber": "0114 2507411",
    "mobilePhone": "07817023514",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "6/21/2018 1:12:57 PM"
  },
  {
    "userPrincipalName": "TeamVPN@eurosafeuk.com",
    "displayName": "Team VPN",
    "surname": "VPN",
    "mail": "",
    "givenName": "Team",
    "id": "ba07a948-afed-46c8-b600-44f339e13e5a",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "True",
    "usageLocation": "",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "1/3/2024 12:25:29 PM"
  },
  {
    "userPrincipalName": "DJustice@eurosafeuk.com",
    "displayName": "Danielle Justice",
    "surname": "Justice",
    "mail": "DJustice@eurosafeuk.com",
    "givenName": "Danielle",
    "id": "ba39452e-8352-4aaa-9aaa-1297195889e2",
    "userType": "Member",
    "jobTitle": "Renewals Account Manager",
    "department": "Compliance",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Compliance",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (Ext 423)",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Compliance",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "6/19/2024 10:57:08 AM"
  },
  {
    "userPrincipalName": "KGoodwin@eurosafeuk.com",
    "displayName": "Kersha Goodwin",
    "surname": "Goodwin",
    "mail": "KGoodwin@eurosafeuk.com",
    "givenName": "Kersha",
    "id": "bbf738ce-5446-4f91-b896-8c8136ecd765",
    "userType": "Member",
    "jobTitle": "Training Co-Ordinator",
    "department": "Training",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Training & PPE",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (ext 326)",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Training & PPE",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "3/18/2024 10:09:53 AM"
  },
  {
    "userPrincipalName": "IShore@eurosafeuk.com",
    "displayName": "Ian Shore",
    "surname": "Shore",
    "mail": "IShore@eurosafeuk.com",
    "givenName": "Ian",
    "id": "bcbd4f93-7d7e-4219-8d25-3dcb6173ab52",
    "userType": "Member",
    "jobTitle": "Rope Access Manager",
    "department": "Rope Access",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Contracting",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (Ext 304)",
    "mobilePhone": "0797 027 2950",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Contracting",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "1/17/2017 12:45:02 PM"
  },
  {
    "userPrincipalName": "mciadmin@eurosafeuk.com",
    "displayName": "mciadmin",
    "surname": "",
    "mail": "mciadmin@eurosafeuk.com",
    "givenName": "",
    "id": "bd87be2b-17f6-42b4-80a1-dfe7cfc18066",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "support@mci4service.co.uk",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "1/25/2018 9:26:19 AM"
  },
  {
    "userPrincipalName": "DBaker@eurosafeuk.com",
    "displayName": "Darren Baker",
    "surname": "Baker",
    "mail": "DBaker@eurosafeuk.com",
    "givenName": "Darren",
    "id": "be734a70-0f21-4aed-95dc-9858aa3deead",
    "userType": "Member",
    "jobTitle": "Site Supervisor",
    "department": "Repairs",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Compliance",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "3/17/2022 2:04:14 PM"
  },
  {
    "userPrincipalName": "ESiedlecki@eurosafeuk.com",
    "displayName": "Erika Siedlecki",
    "surname": "Siedlecki",
    "mail": "ESiedlecki@eurosafeuk.com",
    "givenName": "Erika",
    "id": "c075efeb-460d-45ac-a1ca-7199107f7a11",
    "userType": "Member",
    "jobTitle": "Retentions Account Manager",
    "department": "T&I Sales",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (EXT 225)",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "11/1/2021 7:58:51 AM"
  },
  {
    "userPrincipalName": "IRS@eurosafesolutions.co.uk",
    "displayName": "IRS",
    "surname": "",
    "mail": "IRS@eurosafeuk.com",
    "givenName": "",
    "id": "c0876af3-a6b6-4b5d-a2f4-230155a1b0d7",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "True",
    "usageLocation": "",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "7/1/2022 8:40:00 AM"
  },
  {
    "userPrincipalName": "webmaster@eurosafesolutions.co.uk",
    "displayName": "webmaster",
    "surname": "",
    "mail": "webmaster@eurosafeuk.com",
    "givenName": "",
    "id": "c0b54f09-0579-46fa-b1cc-0c6d39868c45",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "True",
    "usageLocation": "",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "9/28/2021 8:41:57 AM"
  },
  {
    "userPrincipalName": "ABassinder@eurosafeuk.com",
    "displayName": "Andy Bassinder",
    "surname": "Bassinder",
    "mail": "ABassinder@eurosafeuk.com",
    "givenName": "Andy",
    "id": "c112be7e-7294-49a0-943c-35e40767f5d1",
    "userType": "Member",
    "jobTitle": "Site Supervisor",
    "department": "Compliance",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Compliance",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "07377968240",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "6/4/2021 10:40:11 AM"
  },
  {
    "userPrincipalName": "SChristodoulou@eurosafesolutions.co.uk",
    "displayName": "Steffen Christodoulou",
    "surname": "Christodoulou",
    "mail": "SChristodoulou@eurosafesolutions.co.uk",
    "givenName": "Steffen",
    "id": "c17684d0-db97-4bfa-9dc4-020fc566d23d",
    "userType": "Member",
    "jobTitle": "Site Operative",
    "department": "Test & Inspection",
    "accountEnabled": "False",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "5/22/2023 3:36:04 PM"
  },
  {
    "userPrincipalName": "JNewton@eurosafeuk.com",
    "displayName": "James Newton",
    "surname": "Newton",
    "mail": "JNewton@eurosafeuk.com",
    "givenName": "James",
    "id": "c1ab3780-63ce-4cf5-9fb2-99a2f41e85e2",
    "userType": "Member",
    "jobTitle": "Team Operations Manager",
    "department": "Compliance",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Compliance",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "07494 473805",
    "mobilePhone": "07494 473805",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Compliance",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "6/4/2021 10:28:41 AM"
  },
  {
    "userPrincipalName": "MMallett@eurosafeuk.com",
    "displayName": "Matthew Mallett",
    "surname": "Mallett",
    "mail": "MMallett@eurosafeuk.com",
    "givenName": "Matthew",
    "id": "c2163a0d-17c3-48f1-b96a-0fccf0148070",
    "userType": "Member",
    "jobTitle": "Finance Apprentice",
    "department": "Finance",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "7/24/2023 10:54:09 AM"
  },
  {
    "userPrincipalName": "ATITeam18@eurosafeuk.com",
    "displayName": "ATITeam18@eurosafesolutions.co.uk",
    "surname": "Team18",
    "mail": "ATITeam18@eurosafeuk.com",
    "givenName": "ATI",
    "id": "c26b1989-6bf1-46bb-a1d3-fb10aa28b7ec",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "7/2/2021 11:40:32 AM"
  },
  {
    "userPrincipalName": "JThorpe@eurosafeuk.com",
    "displayName": "Joe Thorpe",
    "surname": "Thorpe",
    "mail": "JThorpe@eurosafeuk.com",
    "givenName": "Joe",
    "id": "c42bb04f-e366-4b6a-adf5-dee7048a86cd",
    "userType": "Member",
    "jobTitle": "Contracts Co-ordinator",
    "department": "Compliance",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Compliance",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Compliance",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "7/25/2023 8:24:40 AM"
  },
  {
    "userPrincipalName": "chill@eurosafeuk.com",
    "displayName": "Charlotte Hill",
    "surname": "Hill",
    "mail": "CHill@eurosafeuk.com",
    "givenName": "Charlotte",
    "id": "c4366ce2-798a-4852-8d6e-b18c00208b0a",
    "userType": "Member",
    "jobTitle": "Sales Advisor Team Leader",
    "department": "Compliance",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Compliance",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (Ext 212)",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Compliance",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "6/17/2022 11:20:55 AM"
  },
  {
    "userPrincipalName": "DFishley@eurosafeuk.com",
    "displayName": "Dennis Fishley",
    "surname": "Fishley",
    "mail": "DFishley@eurosafeuk.com",
    "givenName": "Dennis",
    "id": "c476a427-7558-41a6-89ad-ab885f99bb91",
    "userType": "Member",
    "jobTitle": "Site Operative",
    "department": "Installations",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Contracting",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "4/22/2021 2:38:16 PM"
  },
  {
    "userPrincipalName": "Training@eurosafeuk.com",
    "displayName": "Training",
    "surname": "",
    "mail": "training@eurosafeuk.com",
    "givenName": "",
    "id": "c53d8bbf-cedb-4c5c-b373-f00368e27b71",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "5/7/2021 1:30:10 PM"
  },
  {
    "userPrincipalName": "jsiedlecki@eurosafesolutions.co.uk",
    "displayName": "Jan Siedlecki",
    "surname": "Siedlecki",
    "mail": "jsiedlecki@eurosafesolutions.co.uk",
    "givenName": "Jan",
    "id": "c5bde61f-fa58-49a8-917f-2ea0e6c88700",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "1/11/2023 2:38:55 PM"
  },
  {
    "userPrincipalName": "chopson@eurosafeuk.com",
    "displayName": "Caitlin Hopson",
    "surname": "Hopson",
    "mail": "CHopson@eurosafeuk.com",
    "givenName": "Caitlin",
    "id": "c62af285-5eae-403e-b2ce-dd54419ce46a",
    "userType": "Member",
    "jobTitle": "Sales Advisor",
    "department": "Compliance",
    "accountEnabled": "False",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Compliance",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (EXT 223)",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Compliance",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "10/31/2022 12:23:03 PM"
  },
  {
    "userPrincipalName": "wreid@eurosafeuk.com",
    "displayName": "Wayne Reid",
    "surname": "Reid",
    "mail": "WReid@eurosafeuk.com",
    "givenName": "Wayne",
    "id": "c92fc4b8-f984-4250-ac0b-039247decf33",
    "userType": "Member",
    "jobTitle": "Sales Director",
    "department": "Compliance",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Compliance",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (220)",
    "mobilePhone": "0790 353 6104",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Compliance",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "1/17/2017 12:45:36 PM"
  },
  {
    "userPrincipalName": "msmith@eurosafeuk.com",
    "displayName": "Mitchel Smith",
    "surname": "Smith",
    "mail": "msmith@eurosafeuk.com",
    "givenName": "Mitchel",
    "id": "cb366464-7b42-4b0c-98bb-8439c4008b2b",
    "userType": "Member",
    "jobTitle": "Site Supervisor",
    "department": "Installations",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Contracting",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "10/31/2022 10:36:18 AM"
  },
  {
    "userPrincipalName": "JDillon@eurosafeuk.com",
    "displayName": "Jordan Dillon",
    "surname": "Dillon",
    "mail": "JDillon@eurosafeuk.com",
    "givenName": "Jordan",
    "id": "cc102e38-31db-417a-995e-366dcf223303",
    "userType": "Member",
    "jobTitle": "Remedials Division Manager",
    "department": "Repairs",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Compliance",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (Ext 235)",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Compliance",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "1/17/2017 12:45:08 PM"
  },
  {
    "userPrincipalName": "MScott@eurosafesolutions.co.uk",
    "displayName": "Marie Scott",
    "surname": "Scott",
    "mail": "MScott@eurosafesolutions.co.uk",
    "givenName": "Marie",
    "id": "cc7a2876-bca8-446f-80c6-bdb5e56e2adc",
    "userType": "Member",
    "jobTitle": "Credit Controller",
    "department": "Finance",
    "accountEnabled": "False",
    "usageLocation": "GB",
    "streetAddress": "Unit 51B, Orgreave Drive",
    "state": "",
    "country": "United Kingdom",
    "officeLocation": "",
    "city": "Sheffield",
    "postalCode": "S13 9NR",
    "telephoneNumber": "0114 2507411 Ext.201",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "7/6/2017 1:23:54 PM"
  },
  {
    "userPrincipalName": "retest@eurosafeuk.com",
    "displayName": "Test & Inspection",
    "surname": "",
    "mail": "retest@eurosafeuk.com",
    "givenName": "",
    "id": "cd14b1da-1b7f-4813-98e2-b1de0a655e72",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "10/12/2018 10:07:54 AM"
  },
  {
    "userPrincipalName": "KOldfield@eurosafeuk.com",
    "displayName": "Karen Oldfield",
    "surname": "Oldfield",
    "mail": "KOldfield@eurosafeuk.com",
    "givenName": "Karen",
    "id": "cd7652bf-f27c-491d-aa1a-cfd7aae28a70",
    "userType": "Member",
    "jobTitle": "Document Controller",
    "department": "Installations",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Contracting",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (Ext 284)",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Contracting",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "5/22/2017 1:04:51 PM"
  },
  {
    "userPrincipalName": "FinanceUK@eurosafeuk.com",
    "displayName": "Finance",
    "surname": "",
    "mail": "FinanceUK@eurosafeuk.com",
    "givenName": "",
    "id": "ce3a751c-815b-4743-80c9-7898dba2e893",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "financeuk@eurosafesolutions.com",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "6/28/2018 1:53:35 PM"
  },
  {
    "userPrincipalName": "LShore@eurosafeuk.com",
    "displayName": "Laura Shore",
    "surname": "Shore",
    "mail": "LShore@eurosafeuk.com",
    "givenName": "Laura",
    "id": "cf477b66-4a51-4838-94da-6ca74ff7fc72",
    "userType": "Member",
    "jobTitle": "Assistant Fleet Manager",
    "department": "Warehouse & Fleet",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (Ext 261)",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "1/17/2017 12:45:15 PM"
  },
  {
    "userPrincipalName": "AGreening@eurosafeuk.com",
    "displayName": "Adam Greening",
    "surname": "Greening",
    "mail": "AGreening@eurosafeuk.com",
    "givenName": "Adam",
    "id": "d07220f5-4160-4c29-a0f7-bed983dbd920",
    "userType": "Member",
    "jobTitle": "Business Development Manager",
    "department": "Sales",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Contracting",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (Ext 300)",
    "mobilePhone": "0737 796 8049",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Contracting",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "5/21/2021 10:06:13 AM"
  },
  {
    "userPrincipalName": "Jgreen@eurosafeuk.com",
    "displayName": "John Green",
    "surname": "Green",
    "mail": "Jgreen@eurosafeuk.com",
    "givenName": "John",
    "id": "d092ab42-db77-4a12-90cd-7710a2c16525",
    "userType": "Member",
    "jobTitle": "Site Operative",
    "department": "",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "2/29/2024 4:13:03 PM"
  },
  {
    "userPrincipalName": "srawlings@eurosafeuk.com",
    "displayName": "Sarah Rawlings",
    "surname": "Rawlings",
    "mail": "SRawlings@eurosafeuk.com",
    "givenName": "Sarah",
    "id": "d35a3180-dc27-41ca-97fa-da0ab917a6a4",
    "userType": "Member",
    "jobTitle": "Credit Control",
    "department": "Finance",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (Ext 269)",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "6/17/2022 11:21:55 AM"
  },
  {
    "userPrincipalName": "ATIteam11@eurosafeuk.com",
    "displayName": "T&I Team 11",
    "surname": "Team 11",
    "mail": "ATIteam11@eurosafeuk.com",
    "givenName": "T&I",
    "id": "d3787693-b40d-4fb4-9696-7d7cf6d4f648",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "4/18/2019 3:43:46 PM"
  },
  {
    "userPrincipalName": "NWood@eurosafeuk.com",
    "displayName": "Nathan Wood",
    "surname": "Wood",
    "mail": "NWood@eurosafeuk.com",
    "givenName": "Nathan",
    "id": "d3a2a9c7-fa14-4be9-aac8-fd7249f7b98f",
    "userType": "Member",
    "jobTitle": "Managing Director",
    "department": "",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Contracting",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (Ext 309)",
    "mobilePhone": "0779 157 0116",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Contracting",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "1/17/2017 12:45:21 PM"
  },
  {
    "userPrincipalName": "ASimmsold@eurosafeuk.com",
    "displayName": "Anthony Simms",
    "surname": "Simms",
    "mail": "ASimmsold@eurosafeuk.com",
    "givenName": "Anthony",
    "id": "d48821b7-f011-467d-afa3-39b991cbaf3f",
    "userType": "Member",
    "jobTitle": "Quality Manager",
    "department": "Contracts",
    "accountEnabled": "False",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Contracts",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (Ext 428)",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Contracting",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "5/9/2019 9:48:56 AM"
  },
  {
    "userPrincipalName": "RChadwick@eurosafeuk.com",
    "displayName": "Reece Chadwick",
    "surname": "Chadwick",
    "mail": "RChadwick@eurosafeuk.com",
    "givenName": "Reece",
    "id": "d48d3ae9-5125-499e-a4e8-388c90ec4f3d",
    "userType": "Member",
    "jobTitle": "Site Supervisor",
    "department": "Contracts",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Contracting",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Contracting",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "9/24/2024 9:54:08 AM"
  },
  {
    "userPrincipalName": "ArenaCourtMeetingRoom@eurosafeuk.com",
    "displayName": "Arena Court Meeting Room",
    "surname": "",
    "mail": "ArenaCourtMeetingRoom@eurosafeuk.com",
    "givenName": "",
    "id": "d529d4fc-ce2a-43fd-b114-a2a1eda823e2",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "True",
    "usageLocation": "",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "8/30/2024 8:32:22 AM"
  },
  {
    "userPrincipalName": "bmoffatt@eurosafeuk.com",
    "displayName": "Barnaby Moffatt",
    "surname": "Moffatt",
    "mail": "BMoffatt@eurosafeuk.com",
    "givenName": "Barnaby",
    "id": "d52d5cdc-0a1e-4ae6-9122-0e21988e6beb",
    "userType": "Member",
    "jobTitle": "Assistant Project Manager",
    "department": "Installations",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Contracting",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (Ext 281)",
    "mobilePhone": "07950415574",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Contracting",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "8/10/2022 10:23:13 AM"
  },
  {
    "userPrincipalName": "LPowell@eurosafeuk.com",
    "displayName": "Louise Powell",
    "surname": "Powell",
    "mail": "LPowell@eurosafeuk.com",
    "givenName": "Louise",
    "id": "d62b0623-42a0-4e8a-9311-cc6e9b753fa1",
    "userType": "Member",
    "jobTitle": "Senior Sales Advisor",
    "department": "Supply & Training",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Training & PPE",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (Ext 322)",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Training & PPE",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "1/17/2017 12:45:18 PM"
  },
  {
    "userPrincipalName": "cpowell@eurosafeuk.com",
    "displayName": "Callum Powell",
    "surname": "Powell",
    "mail": "CPowell@eurosafeuk.com",
    "givenName": "Callum",
    "id": "d8627c32-821c-482d-9bb1-86df08b4fc59",
    "userType": "Member",
    "jobTitle": "Compliance Designer",
    "department": "Compliance",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Compliance",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Compliance",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "10/31/2022 11:23:02 AM"
  },
  {
    "userPrincipalName": "abm@eurosafeuk.com",
    "displayName": "Apple Business Manager",
    "surname": "",
    "mail": "abm@eurosafeuk.com",
    "givenName": "",
    "id": "d86a984c-4a01-4dee-8500-f525964a7898",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "True",
    "usageLocation": "",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "9/29/2022 9:08:52 AM"
  },
  {
    "userPrincipalName": "Chriswright@eurosafeuk.com",
    "displayName": "Chris Wright",
    "surname": "Wright",
    "mail": "ChrisWright@eurosafeuk.com",
    "givenName": "Chris",
    "id": "d8ff3bf8-34d9-4742-b3dc-2bbfc526195e",
    "userType": "Member",
    "jobTitle": "Technical Operations Manager",
    "department": "Lightning Protection",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Compliance",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Compliance",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "6/4/2021 9:49:40 AM"
  },
  {
    "userPrincipalName": "SHughes@eurosafeuk.com",
    "displayName": "Sion Hughes",
    "surname": "Hughes",
    "mail": "SHughes@eurosafeuk.com",
    "givenName": "Sion",
    "id": "d94b96c4-6de9-4317-aaef-10cdf6eac22e",
    "userType": "Member",
    "jobTitle": "Site Supervisor",
    "department": "Compliance",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Compliance",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "6/4/2021 10:02:42 AM"
  },
  {
    "userPrincipalName": "LGrey@eurosafeuk.com",
    "displayName": "Leanne Grey",
    "surname": "Grey",
    "mail": "LGrey@eurosafeuk.com",
    "givenName": "Leanne",
    "id": "da2f4575-a235-4b31-a1c5-e5916cf73bcc",
    "userType": "Member",
    "jobTitle": "Supply Chain Manager",
    "department": "Warehouse & Purchasing",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (Ext 262)",
    "mobilePhone": "07910412237",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "8/24/2017 8:11:30 AM"
  },
  {
    "userPrincipalName": "LBattersby@eurosafeuk.com",
    "displayName": "Lewis Battersby",
    "surname": "Battersby",
    "mail": "LBattersby@eurosafeuk.com",
    "givenName": "Lewis",
    "id": "da42fd9f-b5d9-4642-aee2-b943ac151e35",
    "userType": "Member",
    "jobTitle": "Purchasing Assistant",
    "department": "procurement",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "United Kingdom",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 2507411 Ext. 260",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "4/11/2019 3:09:46 PM"
  },
  {
    "userPrincipalName": "ATIteam9@eurosafeuk.com",
    "displayName": "T&I Team 9",
    "surname": "Team 9",
    "mail": "ATIteam9@eurosafeuk.com",
    "givenName": "T&I",
    "id": "da468fe9-c53e-4a5a-80b8-c7f030b831a6",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "4/18/2019 3:21:11 PM"
  },
  {
    "userPrincipalName": "JThornton@eurosafeuk.com",
    "displayName": "Josh Thornton",
    "surname": "Thornton",
    "mail": "JThornton@eurosafeuk.com",
    "givenName": "Josh",
    "id": "da50df90-9afd-42b6-af91-098a8dbb1e33",
    "userType": "Member",
    "jobTitle": "Site Operative",
    "department": "Contracts",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (Ext 305)",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "8/30/2019 8:33:26 AM"
  },
  {
    "userPrincipalName": "JSanderson@eurosafeuk.com",
    "displayName": "Jack Sanderson",
    "surname": "Sanderson",
    "mail": "JSanderson@eurosafeuk.com",
    "givenName": "Jack",
    "id": "da84bc51-eb55-4ad9-9780-7c05f941edf7",
    "userType": "Member",
    "jobTitle": "Site Operative",
    "department": "Installations",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Contractings",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "12/4/2020 11:22:31 AM"
  },
  {
    "userPrincipalName": "ATITeam26@eurosafeuk.com",
    "displayName": "ATITeam26",
    "surname": 26,
    "mail": "ATITeam26@eurosafeuk.com",
    "givenName": "ATITeam",
    "id": "da8ae629-524e-4d4c-91cb-7195193be9e8",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "2/9/2024 12:15:28 PM"
  },
  {
    "userPrincipalName": "CBates@eurosafeuk.com",
    "displayName": "Colin Bates",
    "surname": "Bates",
    "mail": "CBates@eurosafeuk.com",
    "givenName": "Colin",
    "id": "dab40ab9-1367-4485-827f-7cb907abbad5",
    "userType": "Member",
    "jobTitle": "Site Supervisor",
    "department": "Installations",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Contracting",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "2/6/2023 10:13:57 AM"
  },
  {
    "userPrincipalName": "JBoyle@eurosafeuk.com",
    "displayName": "John Boyle",
    "surname": "Boyle",
    "mail": "JBoyle@eurosafeuk.com",
    "givenName": "John",
    "id": "db6972ac-477c-4988-b242-f8bc6da16ecc",
    "userType": "Member",
    "jobTitle": "Managing Director",
    "department": "Compliance",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Compliance",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (ext 203)",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Compliance",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "1/17/2017 12:45:06 PM"
  },
  {
    "userPrincipalName": "LPS2@eurosafeuk.com",
    "displayName": "LPS2",
    "surname": "",
    "mail": "LPS2@eurosafeuk.com",
    "givenName": "LPS2",
    "id": "dc32fafb-9ae4-4950-afe9-5d2714666443",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "6/11/2018 1:59:58 PM"
  },
  {
    "userPrincipalName": "CNicol@eurosafeuk.com",
    "displayName": "Connor Nicol",
    "surname": "Nicol",
    "mail": "CNicol@eurosafeuk.com",
    "givenName": "Connor",
    "id": "dcbcac5a-9a7d-4b03-b641-d8c1460c2042",
    "userType": "Member",
    "jobTitle": "Site Operative",
    "department": "Installations",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Contracting",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "1/17/2024 9:09:38 AM"
  },
  {
    "userPrincipalName": "TYork@eurosafeuk.com",
    "displayName": "Tyler York",
    "surname": "York",
    "mail": "TYork@eurosafeuk.com",
    "givenName": "Tyler",
    "id": "dcfc2978-23a1-475f-b86f-57956c8dcf4c",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "False",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "1/9/2024 9:48:01 AM"
  },
  {
    "userPrincipalName": "EBishop@eurosafesolutions.co.uk",
    "displayName": "Ericka Bishop",
    "surname": "Bishop",
    "mail": "EBishop@eurosafesolutions.co.uk",
    "givenName": "Ericka",
    "id": "dda9f27b-0f13-4b16-a365-f8ee1658dd17",
    "userType": "Member",
    "jobTitle": "Sales Advisor Team Leader",
    "department": "Test & Inspection - Sales",
    "accountEnabled": "False",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (Ext 215)",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "4/19/2021 8:22:49 AM"
  },
  {
    "userPrincipalName": "Tlilley@eurosafeuk.com",
    "displayName": "Tom Lilley",
    "surname": "Lilley",
    "mail": "Tlilley@eurosafeuk.com",
    "givenName": "Tom",
    "id": "de41c488-0d40-4c1b-9d89-ad41a0c51dc4",
    "userType": "Member",
    "jobTitle": "Contracts Co-Ordinator",
    "department": "Compliance",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Compliance",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Compliance",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "7/25/2023 8:13:49 AM"
  },
  {
    "userPrincipalName": "Sync_ESDC01_c06fca98de16@eurosafeukltd.onmicrosoft.com",
    "displayName": "On-Premises Directory Synchronization Service Account",
    "surname": "",
    "mail": "",
    "givenName": "",
    "id": "df5c4016-4078-4fde-a0e8-addfa9505cc6",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "True",
    "usageLocation": "",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "8/24/2022 1:51:28 PM"
  },
  {
    "userPrincipalName": "BCollin@eurosafeuk.com",
    "displayName": "Ben Collin",
    "surname": "Collin",
    "mail": "BCollin@eurosafeuk.com",
    "givenName": "Ben",
    "id": "dfa3c2a3-7367-4cbb-aee1-6a7e03817abf",
    "userType": "Member",
    "jobTitle": "Site Supervisor",
    "department": "Compliance",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Compliance",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "4/21/2023 1:38:47 PM"
  },
  {
    "userPrincipalName": "helpdesk@eurosafesolutions.co.uk",
    "displayName": "Helpdesk",
    "surname": "",
    "mail": "helpdesk@eurosafeuk.com",
    "givenName": "",
    "id": "dfd5e283-0d67-4192-984e-2b11ed383b2b",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "False",
    "usageLocation": "",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "6/21/2018 10:43:50 AM"
  },
  {
    "userPrincipalName": "MSayles@eurosafeuk.com",
    "displayName": "Melanie Sayles",
    "surname": "Sayles",
    "mail": "MSayles@eurosafeuk.com",
    "givenName": "Melanie",
    "id": "e036c06a-3772-41d5-af71-68615a8965b4",
    "userType": "Member",
    "jobTitle": "Data Administrator",
    "department": "Process Department",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (Ext 410)",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "3/22/2024 11:11:35 AM"
  },
  {
    "userPrincipalName": "REllis@eurosafeuk.com",
    "displayName": "Robin Ellis",
    "surname": "Ellis",
    "mail": "REllis@eurosafeuk.com",
    "givenName": "Robin",
    "id": "e038e554-a935-4d39-902c-685833125c3a",
    "userType": "Member",
    "jobTitle": "Warehouse Assistant",
    "department": "Warehouse & Purchasing",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Compliance",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (Ext 266)",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Compliance",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "11/10/2021 8:55:54 AM"
  },
  {
    "userPrincipalName": "RCrowe@eurosafeuk.com",
    "displayName": "Roy Crowe",
    "surname": "Crowe",
    "mail": "RCrowe@eurosafeuk.com",
    "givenName": "Roy",
    "id": "e054695b-e48a-48e0-b69b-3ad0e6275ae3",
    "userType": "Member",
    "jobTitle": "Site Operative",
    "department": "Compliance",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Compliance",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Compliance",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "3/21/2024 9:41:12 AM"
  },
  {
    "userPrincipalName": "JMetcalfe@eurosafeuk.com",
    "displayName": "Jessica Metcalfe",
    "surname": "Metcalfe",
    "mail": "JMetcalfe@eurosafeuk.com",
    "givenName": "Jessica",
    "id": "e06fed34-3c8c-42c3-80d0-8e0a04990997",
    "userType": "Member",
    "jobTitle": "LPS Contracts Co-Ordinator",
    "department": "Lightning Protection",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Compliance",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (Ext 232)",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Compliance",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "7/2/2021 8:11:10 AM"
  },
  {
    "userPrincipalName": "TechnicalFiles@eurosafeuk.com",
    "displayName": "Technical Files",
    "surname": "",
    "mail": "TechnicalFiles@eurosafeuk.com",
    "givenName": "",
    "id": "e073017f-1b3d-4567-8efc-cd2497b47dff",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "2/1/2024 12:47:47 PM"
  },
  {
    "userPrincipalName": "info@eurosafesolutions.co.uk",
    "displayName": "Info",
    "surname": "",
    "mail": "info@eurosafeuk.com",
    "givenName": "",
    "id": "e1b40c01-1c57-4984-af56-aa858164468f",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "True",
    "usageLocation": "",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "1/31/2024 4:38:42 PM"
  },
  {
    "userPrincipalName": "OMuizarajs@eurosafeuk.com",
    "displayName": "Olivers Muizarajs",
    "surname": "Muizarajs",
    "mail": "OMuizarajs@eurosafeuk.com",
    "givenName": "Olivers",
    "id": "e2265f82-f6ca-4096-b125-2282ab2c465e",
    "userType": "Member",
    "jobTitle": "Site Operative",
    "department": "Installations",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Contracting",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "11/20/2023 10:18:36 AM"
  },
  {
    "userPrincipalName": "Traininguk@eurosafeuk.com",
    "displayName": "Traininguk",
    "surname": "",
    "mail": "Traininguk@eurosafeuk.com",
    "givenName": "",
    "id": "e2c328ad-e179-440b-8692-6548d81a7605",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "False",
    "usageLocation": "",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "6/28/2018 3:32:48 PM"
  },
  {
    "userPrincipalName": "JPhillips@eurosafeuk.com",
    "displayName": "James Phillips",
    "surname": "Phillips",
    "mail": "JPhillips@eurosafeuk.com",
    "givenName": "James",
    "id": "e389bbd1-81e9-44c1-be9c-dddce8e1e118",
    "userType": "Member",
    "jobTitle": "Site Supervisor",
    "department": "Installations",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Contracting",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "11/11/2022 9:55:16 AM"
  },
  {
    "userPrincipalName": "TParkes@eurosafeuk.com",
    "displayName": "Tyler Parkes",
    "surname": "Parkes",
    "mail": "TParkes@eurosafeuk.com",
    "givenName": "Tyler",
    "id": "e64af95f-7526-451e-aec3-67fa73ccb7e9",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "3/24/2023 9:21:44 AM"
  },
  {
    "userPrincipalName": "ITAdmin@eurosafesolutions.co.uk",
    "displayName": "IT Admin",
    "surname": "",
    "mail": "ITAdmin@eurosafeuk.com",
    "givenName": "",
    "id": "e7d568a2-ad7b-4cc0-ad8a-90cbc28ae96e",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "True",
    "usageLocation": "",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "11/3/2021 10:13:47 AM"
  },
  {
    "userPrincipalName": "FCowen@eurosafeuk.com",
    "displayName": "Frederick Cowen",
    "surname": "Cowen",
    "mail": "FCowen@eurosafeuk.com",
    "givenName": "Frederick",
    "id": "e9c17e1f-913b-4639-929b-b203c7e27dde",
    "userType": "Member",
    "jobTitle": "Site Operative",
    "department": "Lightning Protection",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Compliance",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Compliance",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "3/9/2023 1:40:51 PM"
  },
  {
    "userPrincipalName": "LJackson@eurosafeuk.com",
    "displayName": "Liam Jackson",
    "surname": "Jackson",
    "mail": "LJackson@eurosafeuk.com",
    "givenName": "Liam",
    "id": "eb789d2d-39f9-465a-a6c5-f6665a401db2",
    "userType": "Member",
    "jobTitle": "Site Operative",
    "department": "Installations",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Compliance",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "4/10/2024 9:38:56 AM"
  },
  {
    "userPrincipalName": "AJames@eurosafeuk.com",
    "displayName": "Anika-Leigh James",
    "surname": "James",
    "mail": "AJames@eurosafeuk.com",
    "givenName": "Anika-Leigh",
    "id": "ebce3979-1855-45f1-8ff3-546cf0960cdc",
    "userType": "Member",
    "jobTitle": "Sales & Administration Apprentice",
    "department": "Training",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (ext 417)",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "5/3/2024 8:01:32 AM"
  },
  {
    "userPrincipalName": "MSandford@eurosafeuk.com",
    "displayName": "Michael Sandford",
    "surname": "Sandford",
    "mail": "MSandford@eurosafeuk.com",
    "givenName": "Michael",
    "id": "ec22f417-1699-42ef-8ebc-eb2925bb68ed",
    "userType": "Member",
    "jobTitle": "Site Operative",
    "department": "Compliance",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Compliance",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "5/30/2024 1:33:39 PM"
  },
  {
    "userPrincipalName": "MPritchard@eurosafeuk.com",
    "displayName": "Matthew Pritchard",
    "surname": "Pritchard",
    "mail": "MPritchard@eurosafeuk.com",
    "givenName": "Matthew",
    "id": "ec577e2e-98d7-438f-8700-dd3386082dd1",
    "userType": "Member",
    "jobTitle": "Site Supervisor",
    "department": "Retest",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Compliance",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Compliance",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "10/9/2024 9:19:38 AM"
  },
  {
    "userPrincipalName": "athacker@eurosafeuk.com",
    "displayName": "Adrian Thacker",
    "surname": "Thacker",
    "mail": "athacker@eurosafeuk.com",
    "givenName": "Adrian",
    "id": "ecf0dfca-1166-45c1-87b8-cac9a0439b58",
    "userType": "Member",
    "jobTitle": "Site Supervisor",
    "department": "Installations",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "Unit 51B, Orgreave Drive",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Contracting",
    "city": "Sheffield",
    "postalCode": "S13 9NR",
    "telephoneNumber": "0114 2507411",
    "mobilePhone": "07377679840",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "6/21/2018 1:04:53 PM"
  },
  {
    "userPrincipalName": "BRipley@eurosafeuk.com",
    "displayName": "Bailey Ripley",
    "surname": "Ripley",
    "mail": "BRipley@eurosafeuk.com",
    "givenName": "Bailey",
    "id": "ed3d5fe1-1b99-4ee9-8e9c-43bc3ca6928e",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "False",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "6/5/2023 6:59:01 AM"
  },
  {
    "userPrincipalName": "ATIteam12@eurosafeuk.com",
    "displayName": "T&I Team 12",
    "surname": "Team 12",
    "mail": "ATIteam12@eurosafeuk.com",
    "givenName": "T&I",
    "id": "ee66d775-226f-489d-b58e-a20844fa8ce6",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "4/18/2019 3:45:31 PM"
  },
  {
    "userPrincipalName": "HR@eurosafeuk.com",
    "displayName": "HR",
    "surname": "",
    "mail": "hr@eurosafeuk.com",
    "givenName": "",
    "id": "ef31eb73-c096-4fc4-bc7d-0b5c9eb4a547",
    "userType": "Member",
    "jobTitle": "",
    "department": "HR Department",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "3/19/2019 11:14:26 AM"
  },
  {
    "userPrincipalName": "GLatham@eurosafesolutions.co.uk",
    "displayName": "Germaine Latham",
    "surname": "Latham",
    "mail": "GLatham@eurosafeukltd.onmicrosoft.com",
    "givenName": "Germaine",
    "id": "ef519cf3-cfc5-4ab8-8b88-02fcd6aca72f",
    "userType": "Member",
    "jobTitle": "Sales Advisor",
    "department": "Test & Inspection",
    "accountEnabled": "False",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (EXT 342)",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "11/10/2023 9:22:15 AM"
  },
  {
    "userPrincipalName": "PPM@eurosafeuk.com",
    "displayName": "PPM Helpdesk",
    "surname": "Helpdesk",
    "mail": "PPM@eurosafeuk.com",
    "givenName": "PPM",
    "id": "f0381934-0604-4236-98d0-be622616d8b1",
    "userType": "Member",
    "jobTitle": "",
    "department": "Retest Sales",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Compliance",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Compliance",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "8/21/2024 10:59:05 AM"
  },
  {
    "userPrincipalName": "EWalker@eurosafeuk.com",
    "displayName": "Emily Walker",
    "surname": "Walker",
    "mail": "EWalker@eurosafeuk.com",
    "givenName": "Emily",
    "id": "f0766aee-aacf-4b2f-9674-b968ef30c907",
    "userType": "Member",
    "jobTitle": "Rope Access Administrator",
    "department": "Rope Access",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Contracting",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (Ext 430)",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Contracting",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "9/24/2024 1:54:12 PM"
  },
  {
    "userPrincipalName": "Whitechapel@eurosafeuk.com",
    "displayName": "Whitechapel",
    "surname": "",
    "mail": "whitechapel@eurosafeuk.com",
    "givenName": "",
    "id": "f14949cc-b463-419a-9144-af3ce0f303b8",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "6/28/2018 2:51:47 PM"
  },
  {
    "userPrincipalName": "EBonser@eurosafeuk.com",
    "displayName": "Elliot Bonser",
    "surname": "Bonser",
    "mail": "EBonser@eurosafeuk.com",
    "givenName": "Elliot",
    "id": "f28ab930-a6da-4de2-a9f1-6c74377445b7",
    "userType": "Member",
    "jobTitle": "IT Apprentice",
    "department": "Process Department",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (ext 255)",
    "mobilePhone": "07722171739",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "11/15/2023 9:27:12 AM"
  },
  {
    "userPrincipalName": "BSmith@eurosafeuk.com",
    "displayName": "Bethany Smith",
    "surname": "Smith",
    "mail": "BSmith@eurosafeuk.com",
    "givenName": "Bethany",
    "id": "f319d14b-8053-4b1b-938a-af608b5948b5",
    "userType": "Member",
    "jobTitle": "Installation Technical Administrator",
    "department": "Compliance",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Compliance",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Compliance",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "3/24/2023 3:17:07 PM"
  },
  {
    "userPrincipalName": "RBrightmore@eurosafeuk.com",
    "displayName": "Richard Brightmore",
    "surname": "Brightmore",
    "mail": "RBrightmore@eurosafeuk.com",
    "givenName": "Richard",
    "id": "f5314eb9-2834-48ef-9b6f-f653bbd725f4",
    "userType": "Member",
    "jobTitle": "Service Technician",
    "department": "Warehouse & Purchasing",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (Ext 265)",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "11/2/2021 1:32:16 PM"
  },
  {
    "userPrincipalName": "RA1@eurosafeuk.com",
    "displayName": "Rope Access",
    "surname": "Access",
    "mail": "RA1@eurosafeuk.com",
    "givenName": "Rope",
    "id": "f5b4e071-c65e-420d-b540-1e3fede2e546",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "5/26/2021 1:46:12 PM"
  },
  {
    "userPrincipalName": "swakeling@eurosafeuk.com",
    "displayName": "Sam Wakeling",
    "surname": "Wakeling",
    "mail": "SWakeling@eurosafeuk.com",
    "givenName": "Sam",
    "id": "f81fd792-67d6-4204-909b-f220efff3f69",
    "userType": "Member",
    "jobTitle": "Estimating Manager",
    "department": "Sales",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Contracting",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (Ext 310)",
    "mobilePhone": "0793 947 7509",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Contracting",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "1/13/2017 9:25:53 AM"
  },
  {
    "userPrincipalName": "NKoskinas@eurosafesolutions.co.uk",
    "displayName": "Nikos Koskinas",
    "surname": "Koskinas",
    "mail": "Nkoskinas@eurosafesolutions.co.uk",
    "givenName": "Nikos",
    "id": "f9512ad8-7ff1-4fd4-9d1d-cfe03904f04f",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "False",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "False",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "10/28/2021 10:05:43 AM"
  },
  {
    "userPrincipalName": "TLee@eurosafeuk.com",
    "displayName": "Thomas Lee",
    "surname": "Lee",
    "mail": "TLee@eurosafeuk.com",
    "givenName": "Thomas",
    "id": "f9528f44-cd9a-4096-bc0c-4b85fc56e36d",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "False",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "7/25/2023 8:12:05 AM"
  },
  {
    "userPrincipalName": "LSparrow@eurosafeuk.com",
    "displayName": "Leigh Sparrow",
    "surname": "Sparrow",
    "mail": "LSparrow@eurosafeuk.com",
    "givenName": "Leigh",
    "id": "f9b5c75b-ab91-46ae-afd2-798f5091620e",
    "userType": "Member",
    "jobTitle": "Site Operative",
    "department": "Compliance",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Compliance",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "5/17/2024 7:30:24 AM"
  },
  {
    "userPrincipalName": "LPearson@eurosafeuk.com",
    "displayName": "Lily Pearson",
    "surname": "Pearson",
    "mail": "LPearson@eurosafeuk.com",
    "givenName": "Lily",
    "id": "f9f0544b-c657-4cfb-bb63-02d228457760",
    "userType": "Member",
    "jobTitle": "Sales Advisor",
    "department": "Retest Sales",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Compliance",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (Ext 429)",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Compliance",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "9/24/2024 11:54:11 AM"
  },
  {
    "userPrincipalName": "esadmin@eurosafeukltd.onmicrosoft.com",
    "displayName": "esadmin",
    "surname": "",
    "mail": "",
    "givenName": "esadmin",
    "id": "fb815be9-3efb-484b-bd75-19640e3cd4af",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "Unit C Centenary Works Little London Road",
    "state": "Yorkshire",
    "country": "GB",
    "officeLocation": "",
    "city": "SHEFFIELD",
    "postalCode": "S122QA",
    "telephoneNumber": "07971175403",
    "mobilePhone": "'+44 7971175403",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "1/12/2017 3:06:59 PM"
  },
  {
    "userPrincipalName": "BParnham@eurosafeuk.com",
    "displayName": "Bradley Parnham",
    "surname": "Parnham",
    "mail": "BParnham@eurosafeuk.com",
    "givenName": "Bradley",
    "id": "fbc3447d-0aec-4821-a0ae-c54e49097b30",
    "userType": "Member",
    "jobTitle": "Site Operative",
    "department": "Lightning Protection",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Compliance",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "4/3/2023 8:08:53 AM"
  },
  {
    "userPrincipalName": "SAthwal@eurosafeuk.com",
    "displayName": "Satvir Athwal",
    "surname": "Athwal",
    "mail": "SAthwal@eurosafeuk.com",
    "givenName": "Satvir",
    "id": "fcacb5bf-49c3-4b71-9134-a290b2f49efc",
    "userType": "Member",
    "jobTitle": "Site Operative",
    "department": "Installations",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Contracting",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "12/5/2023 8:13:18 AM"
  },
  {
    "userPrincipalName": "JEllison@eurosafeuk.com",
    "displayName": "John Ellison",
    "surname": "Ellison",
    "mail": "JEllison@eurosafeuk.com",
    "givenName": "John",
    "id": "fd802cae-18ad-4726-a550-8f7719194557",
    "userType": "Member",
    "jobTitle": "Senior Site Supervisor",
    "department": "Compliance",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Compliance",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "07376474336",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "6/4/2021 10:17:32 AM"
  },
  {
    "userPrincipalName": "LStacey@eurosafeuk.com",
    "displayName": "Liam Stacey",
    "surname": "Stacey",
    "mail": "LStacey@eurosafeuk.com",
    "givenName": "Liam",
    "id": "fd9b98e2-c6d8-466f-a90e-857734555ce9",
    "userType": "Member",
    "jobTitle": "Compliance Lead",
    "department": "Compliance",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Compliance",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (Ext 238)",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Compliance",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "4/30/2019 8:33:30 AM"
  },
  {
    "userPrincipalName": "CaitlinHopson@eurosafeuk.com",
    "displayName": "Caitlin Hopson",
    "surname": "Hopson",
    "mail": "CaitlinHopson@eurosafeuk.com",
    "givenName": "Caitlin",
    "id": "fdee1526-abef-41d2-b326-ba5c1394c6a9",
    "userType": "Member",
    "jobTitle": "Sales Advisor",
    "department": "Compliance",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Compliance",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (EXT 223)",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Compliance",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "7/18/2023 7:48:48 AM"
  },
  {
    "userPrincipalName": "CStanton@eurosafeuk.com",
    "displayName": "Cameron Stanton",
    "surname": "Stanton",
    "mail": "CStanton@eurosafeuk.com",
    "givenName": "Cameron",
    "id": "fe371e11-49d9-4deb-8a54-7cc9a733d0f3",
    "userType": "Member",
    "jobTitle": "Remedial Contracts & Sales Assistant",
    "department": "Repairs",
    "accountEnabled": "True",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "Eurosafe Compliance",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "0114 250 7411 (Ext 231)",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "Eurosafe Compliance",
    "creationType": "",
    "directorySynced": "True",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "11/10/2017 3:05:43 PM"
  },
  {
    "userPrincipalName": "DRS@eurosafesolutions.co.uk",
    "displayName": "DRS",
    "surname": "",
    "mail": "drs@eurosafeuk.com",
    "givenName": "",
    "id": "fe76a170-8276-466a-9f07-3ab3aacc522c",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "False",
    "usageLocation": "",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "6/28/2018 3:24:23 PM"
  },
  {
    "userPrincipalName": "repairs@eurosafesolutions.co.uk",
    "displayName": "Repairs",
    "surname": "",
    "mail": "repairs@eurosafeuk.com",
    "givenName": "",
    "id": "ff58f94e-de4f-46c9-9ba3-d0f267b6f14a",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "False",
    "usageLocation": "",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "1/21/2019 1:25:43 PM"
  },
  {
    "userPrincipalName": "JEdwards@eurosafesolutions.co.uk",
    "displayName": "Jack Edwards",
    "surname": "Edwards",
    "mail": "JEdwards@eurosafesolutions.co.uk",
    "givenName": "Jack",
    "id": "ffbad3c4-67a3-4f64-916b-aecac9f78a0d",
    "userType": "Member",
    "jobTitle": "",
    "department": "",
    "accountEnabled": "False",
    "usageLocation": "GB",
    "streetAddress": "",
    "state": "",
    "country": "",
    "officeLocation": "",
    "city": "",
    "postalCode": "",
    "telephoneNumber": "",
    "mobilePhone": "",
    "alternateEmailAddress": "",
    "ageGroup": "",
    "consentProvidedForMinor": "",
    "legalAgeGroupClassification": "",
    "companyName": "",
    "creationType": "",
    "directorySynced": "",
    "invitationState": "",
    "identityIssuer": "eurosafeukltd.onmicrosoft.com",
    "createdDateTime": "8/31/2023 8:18:42 AM"
  }
]